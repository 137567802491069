import React from 'react'

type Props = {
    onChange: (value: boolean) => void,
    value: boolean
}

export default function Switch({ onChange, value }: Props) {
    return (
        <label className="switch relative inline-block w-[44px] h-[20px] overflow-hidden text-[11px] cursor-pointer">
            <input
                type="checkbox"
                checked={value}
                onChange={(e) => {
                    onChange(e.target.checked)
                }}
                className="hidden peer" />
            <span className="slider absolute inset-0 bg-[#000000] transition-all duration-400 rounded-[30px] peer-focus:ring-2 peer-focus:ring-[#17C46759] peer-checked:bg-r-orange-2 peer-checked:bg-transparent"></span>
            <span className="slider-dot absolute left-[2px] top-1/2 transition-all duration-400 -translate-y-1/2 w-4 h-4 bg-[#323346] peer-checked:bg-g-100 rounded-full  peer-checked:translate-x-[24px]"></span>
            <span className="text on absolute top-1/2 left-[6px] transform -translate-y-1/2 translate-x-[-28px]  transition-all duration-400 peer-checked:translate-x-0 text-white">Yes</span>
            <span className="text off absolute top-1/2 right-[5.5px] text-[#D7D6DF] transform -translate-y-1/2  transition-all duration-400 peer-checked:translate-x-[20px]">No</span>
        </label>
    )
}