import React from 'react'
import Box from 'shared/components/Box'
import { LayoutIcon, LayoutLeftIcon, LayoutRightIcon } from 'shared/components/Icons/Menu'

import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react'
import { Radio, RadioGroup, cn } from '@nextui-org/react'
import { useAppDispatch, useAppSelector } from 'shared/hooks/reduxHook'
import { TLayout, updateLayout } from 'shared/state/redux/layoutSlice'
type Props = {}

export default function SetupLayout({ }: Props) {
    const layout = useAppSelector((state) => state.layout.layout);
    const dispatch = useAppDispatch()
    const onChangeLayout = (layout: 'left' | 'right') => {
        dispatch(updateLayout(layout))
    }

    return (
        <Menu>
            {/* @ts-ignore */}
            <MenuButton>
                <div>
                    <Box boxClassName="p-[6px] h-full flex items-center gap-[6px] flex-shrink-0 cursor-pointer group/item">
                        <div>
                            <LayoutIcon />
                        </div>
                        <span className="hidden text-[13px] text-gray-700 group-hover/item:text-gray-600 xl:inline">Layout</span>
                    </Box>
                </div>
            </MenuButton>
            <MenuList zIndex={1000000} className=' rounded-[6px] !p-0' bg="#0D0D14">
                <MenuItem as={Box} className='!p-4' >
                    <RadioGroup
                        value={layout}
                        onValueChange={(value) => onChangeLayout(value as TLayout)}
                    >
                        <CustomRadio value="left">
                            <div className='flex-1 flex items-center  gap-10 h-full text-13'>
                                Onchain on the Left
                                <LayoutLeftIcon className='ml-auto' />
                            </div>
                        </CustomRadio>
                        <CustomRadio value="right">
                            <div className='w-full flex items-center  gap-10 h-full text-13'>
                                Onchain on the Right
                                <LayoutRightIcon className='ml-auto' />
                            </div>
                        </CustomRadio>
                    </RadioGroup>

                </MenuItem>
            </MenuList>
        </Menu >
    )
}

export const CustomRadio = (props: any) => {
    const { children, ...otherProps } = props;

    return (
        <Radio
            {...otherProps}
            classNames={{
                base: cn(
                    "inline-flex m-0 bg-transparent items-center ",
                    "max-w-full w-full p-0 cursor-pointer rounded-lg gap-2 border-2 border-transparent text-13",
                    "data-[selected=true]:bg-transparent"
                ),
                labelWrapper: 'flex-1 text-13',
                control: 'bg-g-100 w-[8.53px] h-[8.53px]',
                wrapper: 'h-[12.7px] w-[12.7px] group-data-[selected=true]:border-[#7E818C] border-[1px]'
            }}
        >
            {children}
        </Radio>
    );
};
