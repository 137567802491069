import { fireRed } from 'assets/icons';
import React from 'react';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  icon?: string | React.ReactNode;
  baseClassName?: string;
  titleClassName?: string;
  iconClassName?: string;
  isIconComponent?: boolean;
}

const FireTitle: React.FC<Props> = ({ title, icon, baseClassName, titleClassName, iconClassName, isIconComponent }) => {
  return (
    <div className={twMerge('flex items-center gap-[9px]', baseClassName)}>
      {isIconComponent && typeof icon !== 'string' ? (
        icon
      ) : (
        <img src={typeof icon === 'string' ? icon : fireRed} className={iconClassName} />
      )}

      <h4 className={twMerge('bg-g-100 bg-clip-text text-[15px] font-bold text-transparent', titleClassName)}>
        {title}
      </h4>
    </div>
  );
};

export default FireTitle;
