import { Box, Flex } from '@chakra-ui/react';
import { memo, PropsWithChildren } from 'react';
import ErrorBoundary from 'shared/components/ErrorBoundary';

import ModalStacks from 'shared/components/ModalStacks';
import SidebarMenu from 'shared/components/SidebarMenu';
// import Trending from 'shared/components/Trending';
// import useHypeLabAds from 'shared/hooks/useHypeLabAds';
import PageHeader from './PageHeader';
import Footer from 'shared/components/Footer';
import HeaderBoard from 'modules/home/components/BoardHeader';
import Trending from 'modules/home/components/Trending';
import { useLocation } from 'react-router-dom';

const hiddenPaths = [
  '/dev-area',
  '/ads',
  '/social-info',
  '/banner-ads',
  '/boost-ads',
  '/top-trending',
  '/top-presale',
  '/top-research',
];

// const hiddenFooterPaths = ['']

function MainLayout({ children }: PropsWithChildren<{}>) {
  const { pathname } = useLocation();
  console.log('pathname:::', pathname);
  // useHypeLabAds();
  return (
    <>
      {/* <PageHeader /> */}
      {/* <Trending /> */}
      <div className="block md:flex bg-[#131722]">
        <SidebarMenu />
        <Box
          as="main"
          flex={1}
          overflowX="hidden"
          w="100%"
          maxH="100dvh"
           overflowY="auto"
        >
          {!hiddenPaths.includes(pathname) && (
            <div className="p-2 md:p-4 !pb-0 space-y-3">
              <HeaderBoard />
              <Trending />
            </div>
          )}

          <ErrorBoundary>{children}</ErrorBoundary>
          <Footer />
        </Box>
      </div>

      <ModalStacks />
    </>
  );
}

export default memo(MainLayout);
