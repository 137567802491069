import client from 'configs/axios';
import { ChainIds } from 'configs/chain';
import ApiResponse from 'types/ApiResponse';
import { IQueryParamsPresale } from 'types/Pair';
import { SignInfo, TPackage } from 'types/Trending';
export type PresaleParams = {
  signInfo: SignInfo;
  address: string;
  chainId: string;
  presaleInfo: {
    link: string;
    type: string;
    liqInitPct: number;
    liqLockDuration: number;
    contributors?: number;
    softCap: number;
    hardCap: number;
    maxBuy: number;
    raised?: number;
    startAt: number | null;
    endAt: number | null;
  };
  startAt: number;
  endAt: number;
  position: number;
  socialLinks: Record<string, string | string[]>;
  lockedSupply: string[];
  badges?:
  | {
    url?: string | null;
    type?: string | null;
  }[]
  | null;
  previousProjects: (
    | {
      chainId?: string | number;
      presale?: number;
      raised?: number;
      athRoi?: number;
      athVol?: number;
      athCap?: number;
      website?: string;
      twitter?: string;
      telegram?: string;
      devUsername?: string;
      link?: string;
    }
    | undefined
    | null
  )[];
  description?: string | null;
  telegramContact: string;
  baseTokenLogo: string;
  // timestamp: string;
  txHash: string;
};

export type SocialLinks = {
  website: string;
  twitter: string;
  telegram: string;
  discord: string;
  github: string;
  facebook: string;
  otherLinks: string[];
};

export async function updatePresale(params: PresaleParams) {
  return await client.post<
    ApiResponse<{
      data: any;
    }>
  >('/pairs/presale', params);
}

export async function getPresalePackages() {
  const res = await client.get<ApiResponse<TPackage[]>>('/pairs/presale-packages');
  return res;
}

export async function getPresaleRequests(params: { chainId: string; position: string }) {
  const res = await client.get<ApiResponse<any>>('/pairs/presale-requests', { params });
  return res.data.data;
}

export async function getPresales(params: {
  chainId?: string;
  factory?: string;
  pageIndex?: number;
  pageSize?: number;
}) {
  const res = await client.get<ApiResponse<any[]>>('/pairs/top-presales', { params });
  return res.data;
}

export async function getPresalesHome(params: IQueryParamsPresale) {
  const res = await client.get<ApiResponse<any[]>>('/pairs/presales', { params });
  return res.data;
}