import { ChainOutlineIcon, PhotoOutlineIcon, PlayOutlineIcon } from 'shared/components/Icons/Proof';
import { PropsWithChildren, useState } from 'react';
import { Link } from 'react-router-dom';

interface Props {
  link?: string;
  video?: string;
  image?: string;
}
const Proof = ({ image, link, video }: Props) => {
  const [proofHover, setProofHover] = useState('');
  return (
    <div className="flex items-center gap-1 justify-center">
      <LinkProof url={link}>
        <div onMouseEnter={() => setProofHover('chain')} onMouseLeave={() => setProofHover('')}>
          <ChainOutlineIcon color={link ? '#0bc662' : '#27593a'} />
        </div>
      </LinkProof>
      <LinkProof url={image}>
        <div onMouseEnter={() => setProofHover('photo')} onMouseLeave={() => setProofHover('')}>
          <PhotoOutlineIcon color={image ? '#0bc662' : '#27593a'} />
        </div>
      </LinkProof>
      <LinkProof url={video}>
        <div onMouseEnter={() => setProofHover('play')} onMouseLeave={() => setProofHover('')}>
          <PlayOutlineIcon color={video ? '#0bc662' : '#27593a'} />
        </div>
      </LinkProof>
    </div>
  );
};

export default Proof;

const LinkProof = ({ children, url }: PropsWithChildren<{ url?: string }>) => {
  if (url) {
    return (
      <Link to={url} target="_blank">
        {children}
      </Link>
    );
  }
  // return children
  return <div className="w-[20px] aspect-square bg-[#131722] rounded-md" />;
};
