import { Box, useMediaQuery } from '@chakra-ui/react';
import { Tab, Tabs } from '@nextui-org/react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { infoCircleBlackIcon } from 'assets/icons';
import constants from 'configs/constants';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SortIcon } from 'shared/components/Icons/Filter';
import { twMerge } from 'tailwind-merge';
import Pair, { HomeScreen, IQueryParamsInfoZone, IQueryParamsPresale, IQueryParamsTrending } from 'types/Pair';
import useTranslation from 'shared/hooks/useTranslation';
import { ClockIcon, DateIcon } from 'shared/components/Icons/Trade';

export type ViewMode = 'utc' | 'local';
interface Props {
  selectedKey: ViewMode;
  onChange: (value: ViewMode) => void;
}
const SwitchUtcMode = ({ selectedKey, onChange }: Props) => {
  const { t } = useTranslation('trade');

  return (
    <Tabs
      classNames={{
        tabList: 'rounded-[6px] bg-[#0D0D14] w-full w-full border border-[#323346] p-0 gap-0',
        cursor: 'bg-[#323346] m-0.5 rounded-[6px]',
        tab: 'py-0 h-5 px-1.5',
      }}
      // onChange={onChangeUTC}
      onSelectionChange={(value) => {
        onChange(value as ViewMode);
      }}
      selectedKey={selectedKey}
      defaultSelectedKey={'local'}
    >
      <Tab
        key="local"
        title={
          <div className="flex items-center justify-center gap-1">
            <span className="text-10 font-bold text-[#323346] flex items-center gap-1 group-data-[selected=true]:text-[#D7D6DF]">
              {t('local')}
            </span>
          </div>
        }
      ></Tab>
      <Tab
        key="utc"
        title={
          <div className="flex items-center justify-center gap-1">
            <span className="text-10 font-bold text-[#323346] flex items-center gap-1 group-data-[selected=true]:text-[#D7D6DF]">
              {t('utc')}
            </span>
          </div>
        }
      ></Tab>
    </Tabs>
  );
};

export default SwitchUtcMode;
