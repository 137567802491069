import { PopoverBody } from "@chakra-ui/react";
import { NotKnowIcon } from "assets/icons";
import { ACTIVITY_DETAIL_OPTIONS, ACTIVITY_OPTIONS, DEX_OPTIONS, TypeSale } from "configs/constants";
import { twMerge } from "tailwind-merge";

const MultiSelect = ({ id, tempValue = [], setTempValue }: any) => {

  const optionsList: any = {
    factory: DEX_OPTIONS,
    activity_type: ACTIVITY_OPTIONS,
    activity_description: ACTIVITY_DETAIL_OPTIONS,
    presaleInfo_type: TypeSale,
  }

  return (
    <PopoverBody className="!p-3 !pb-[0px]">
      <div className="space-y-1.5 max-h-[150px] overflow-auto">
        {optionsList[id]?.map((item: any) => (
          <button
            key={item.value}
            className={twMerge(
              'flex items-center justify-between w-full px-1.5 rounded hover:bg-[#131722]',
              (tempValue.includes(item.value) || tempValue === item.value) && 'bg-g-50'
            )}
            onClick={() =>
              id === 'factory'
                ? setTempValue(item.value)
                : tempValue.includes(item.value)
                  ? setTempValue(tempValue.filter((i: any) => i !== item.value && !!i))
                  : setTempValue([...tempValue, item.value].filter((i: any) => !!i))
            }
          >
            <div className="flex items-center gap-1.5">
              <img src={item.icon ?? NotKnowIcon} alt="" className="h-4 w-4 rounded-full" />
              <p className="text-[13px] leading-5 font-medium">
                {item.label}
              </p>
            </div>
            {
              tempValue.includes(item.value)
                ? <div className="h-[13.5px] w-[13.5px] rounded-full border-[2.5px] border-[#262626]" />
                : <></>
            }
          </button>
        ))}
      </div>
    </PopoverBody>
  )
}

export default MultiSelect;