import { Box } from '@chakra-ui/react';
import { AccessorKeyColumnDef, Cell, Row } from '@tanstack/react-table';
import numeralType from 'configs/numeral';
import path from 'configs/path';
import { themeConstants } from 'configs/theme';
import { Fragment, useMemo } from 'react';
import DataTableCell from 'shared/components/DataTableCell';
import FormatNumberDecimal from 'shared/components/FormatNumberDecimal';
import FormatPercent from 'shared/components/FormatPercent';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import useDexes from 'shared/hooks/useDexes';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import Dex from 'types/Dex';
import Pair, { HomeScreen } from 'types/Pair';
import { getChainNameFromChainId } from 'utils/chain';
import { formatTimePair, formatTimeTokenSocialNetwork, timeAgoDetailed } from 'utils/date';
import {
  abbreviateNumber,
  formatNumeral,
  formatTxDate,
  formatUTCDate,
  numberFormat,
  numberFormatWithAbbreviated,
} from 'utils/format';
import { buildHref } from 'utils/path';
import PairItemName from './PairItemName';
import SocialStatus from 'shared/components/SocialStatus';
import DevOf from 'shared/components/DevOf';
import ScanStatus from 'shared/components/ScanStatus';
import Proof from 'shared/components/Proof';
import { Tooltip } from '@nextui-org/react';
import Action from 'shared/components/Action';
import { twMerge } from 'tailwind-merge';
import { Badge } from 'types/Trending';
import SecurityBadge from 'shared/components/SecurityBadge';
import { format } from 'date-fns';
import { StringUtils } from 'utils/string';
import { ArrowIcon } from 'shared/components/Icons/Arrow';
import constants, { TypeSale } from 'configs/constants';
import { getLaunchPadType } from 'utils/getLaunchPadType';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';

type RowTableProps<Data extends Pair> = {
  row: Row<Data>;
  currentPage: number;
  limit: number;
  gridTemplateColumns?: string;
  homeScreen?: HomeScreen;
  setting: {
    isUTC?: boolean;
    isDisplayTimeAgo?: boolean;
  };
};

function PairItem<Data extends Pair>({ row, currentPage, limit, homeScreen, setting }: RowTableProps<Data>) {
  const dispatch = useAppDispatch();
  const dexes = useDexes();
  const dex = useMemo(() => {
    return dexes?.find((dex: Dex) => dex.factory === row.original.factory);
  }, [dexes, row]);
  // const bgHover = useColorModeValue(themeConstants.rowHoverLight, themeConstants.rowHoverDark);
  // const bg = useColorModeValue(themeConstants.cardBgLight, themeConstants.cardBgDark);
  // const onClickPair = useCallback(() => {
  //   if (row?.original?.address && row.original?.baseToken) {
  //     dispatch(addAddress({ address: String(row.original.address), baseToken: String(row.original?.baseToken) }));
  //   }
  // }, [dispatch, row.original.address, row.original?.baseToken]);

  const handleRedirect = (chainId: string, pair: string, baseToken: string) => {
    return () => {
      if (pair && baseToken) {
        dispatch(addAddress({ address: pair, baseToken: baseToken }));
      }

      const redirect_path = buildHref(
        path.TRADE,
        {
          address: baseToken,
          chain: getChainNameFromChainId(Number(chainId)) as string,
        },
        { pair },
      );
      window.location.href = redirect_path;
      // redirect(redirect_path);
    };
  };

  const renderCell = (cell: Cell<Data, unknown>) => {
    const columnDef = cell.column.columnDef as AccessorKeyColumnDef<Data, unknown>;
    const meta = columnDef.meta as any;
    let value: any;
    try {
      value = cell.getContext().getValue();
    } catch (error) {
      console.log('[get_cell_value] error: ', error);
    }

    const accessorKey = columnDef.accessorKey;
    // get header name
    const header = cell.column.columnDef.header as string;

    if (accessorKey === 'baseTokenSymbol') {
      return (
        <PairItemName
          pair={row.original.address}
          quoteTokenLogo={cell.row.original.quoteTokenLogo}
          baseTokenLogo={cell.row.original.baseTokenLogo}
          rank={row.index + 1 + (currentPage - 1) * limit}
          baseTokenSymbol={cell.row.original.baseTokenSymbol}
          quoteTokenSymbol={cell.row.original.quoteTokenSymbol}
          tokenName={cell.row.original.baseTokenName}
          dexLogo={dex?.avatar}
          baseToken={cell.row.original.baseToken}
          quoteToken={cell.row.original.quoteToken}
          chainId={cell.row.original.chainId}
          isTrending={!!cell.row.original.isTrending}
          isTopPresale={!!cell.row.original.isTopPresale}
          isAdsBoost={!!cell.row.original.isAdsBoost}
        />
      );
    }
    if (accessorKey === 'priceUsd') {
      return (
        <DataTableCell key={header} overflow="hidden" float={meta?.isNumeric ? 'right' : 'left'}>
          <FormatNumberDecimal justifyContent="flex-end" value={value as number} prefix={'$'} />
        </DataTableCell>
      );
    }
    if (accessorKey === 'volume24h' || accessorKey === 'liquidity' || accessorKey === 'fdv') {
      return (
        <DataTableCell key={header} float={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          {formatNumeral(value as number, numeralType.VOLUME_NUMBER, '$')}
        </DataTableCell>
      );
    }

    if (
      accessorKey === 'priceChange5m' ||
      accessorKey === 'priceChange1h' ||
      accessorKey === 'priceChange6h' ||
      accessorKey === 'priceChange24h' ||
      accessorKey === 'priceChange'
    ) {
      return (
        <DataTableCell key={header} float={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          <div className="flex items-center gap-3">
            {Number(value) > 0 ? (
              <ArrowIcon color={themeConstants.green} />
            ) : (
              <div className="rotate-180">
                <ArrowIcon color={themeConstants.red} />
              </div>
            )}
            <FormatPercent
              color={Number(value) > 0 ? themeConstants.green : themeConstants.red}
              percent={Math.abs(Number(value))}
            />
          </div>
        </DataTableCell>
      );
    }

    if (accessorKey === 'tradingStartTime' || accessorKey === 'pairCreatedTime') {
      const date = value
        ? setting.isUTC
          ? formatUTCDate(new Date(value * 1000).toUTCString())
          : format(new Date(value * 1000), 'dd-MM-yyyy HH:mm')
        : '';

      return (
        <DataTableCell key={header} overflow="hidden">
          <p className="text-left text-13 font-medium text-[#D7D6DF]">
            {homeScreen === HomeScreen.SOCIAL_INFOS
              ? date
              : homeScreen === HomeScreen.SOCIAL_NETWORK_UPDATES
                ? formatTimeTokenSocialNetwork(value)
                : formatTimePair(value as number)}
          </p>
        </DataTableCell>
      );
    }

    if (['createdAt'].includes(accessorKey as string)) {
      return (
        <DataTableCell key={header} textAlign={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          <span className="text-right text-13 font-medium text-[#D7D6DF]">
            {formatTimeTokenSocialNetwork(value as number)}
          </span>
        </DataTableCell>
      );
    }
    // if (['activity.startAt'].includes(accessorKey as string)) {
    //   return (
    //     <DataTableCell key={header} textAlign={'right'} overflow="hidden" display={'flex'} justifyContent={'end'}>
    //       <div className="text-right text-13 font-medium text-[#323346] whitespace-nowrap">
    //         {value && timeAgoDetailed(new Date(value))}
    //       </div>
    //     </DataTableCell>
    //   );
    // }
    if (
      ['presaleInfo.startAt', 'presaleInfo.endAt', 'activity.startAt', 'activity.updatedAt'].includes(
        accessorKey as string,
      )
    ) {
      return (
        <DataTableCell key={header} textAlign={meta?.isNumeric ? 'right' : 'left'} overflow="hidden">
          {setting.isDisplayTimeAgo ? (
            <div
              className={twMerge(
                'text-right text-13 font-medium  whitespace-nowrap',
                accessorKey === 'activity.startAt' ? '' : 'text-[#323346]',
              )}
            >
              {value && timeAgoDetailed(new Date(value))}
            </div>
          ) : (
            <div
              className={twMerge(
                'text-right text-13 font-medium  whitespace-nowrap',
                accessorKey === 'activity.startAt' ? '' : 'text-[#323346]',
              )}
            >
              <div>
                {!value
                  ? ''
                  : setting.isUTC
                  ? formatUTCDate(new Date(value).toUTCString())
                  : format(new Date(value), 'dd-MM-yyyy HH:mm')}
              </div>
            </div>
          )}
        </DataTableCell>
      );
    }

    if (accessorKey === 'presaleInfo.link') {
      return (
        <DataTableCell key={header} overflow="hidden" float={'right'} w={'100%'}>
          {/* <p className="text-right text-13 font-medium text-[#D7D6DF]">
            {TypeSale.find(i => i.value === value)?.label}
          </p> */}
          <Link to={value} target="_blank" className="flex gap-x-2 justify-end">
            <div>{constants?.LAUNCH_PAD_INFO?.[getLaunchPadType(value)]?.name}</div>
            <img
              src={constants?.LAUNCH_PAD_INFO?.[getLaunchPadType(value)]?.logo}
              alt=""
              className="h-[20px] w-[20px] rounded-full"
            />
          </Link>
        </DataTableCell>
      );
    }

    if (accessorKey === 'presaleInfo.type') {
      return (
        <DataTableCell key={header} overflow="hidden" float={'right'} w={'100%'}>
          <p className="text-right text-13 font-medium text-[#D7D6DF] whitespace-nowrap">
            {TypeSale.find((i) => i.value === value)?.label}
          </p>
        </DataTableCell>
      );
    }

    if (accessorKey === 'txns' || accessorKey === 'volume' || accessorKey === 'makers') {
      let keyBuy = '' as keyof Pair;
      let keySell = '' as keyof Pair;
      if (accessorKey === 'txns') {
        keyBuy = 'totalBuy';
        keySell = 'totalSell';
      }
      if (accessorKey === 'volume') {
        keyBuy = 'buyVol';
        keySell = 'sellVol';
      }
      if (accessorKey === 'makers') {
        keyBuy = 'buyers';
        keySell = 'sellers';
      }
      return (
        <DataTableCell key={header} float={meta?.isNumeric ? 'right' : 'left'} overflow="hidden" w={'100%'}>
          <p className="text-13 font-medium text-[#D7D6DF] text-right">
            {accessorKey === 'volume' ? (
              <Fragment>{abbreviateNumber(cell.row.original[accessorKey as keyof Pair])}</Fragment>
            ) : (
              // <Fragment>{cell.row.original[accessorKey as keyof Pair]}</Fragment>
              <Fragment>
                {formatNumeral(
                  Number(cell.row.original[accessorKey as keyof Pair]),
                  numeralType.DEFAULT,
                  undefined,
                  '',
                )}
              </Fragment>
            )}
          </p>
          <div className="flex items-center">
            <div
              className="h-[2px] rounded-full bg-noti-green opacity-50"
              style={{
                width: `${(cell.row.original[keyBuy] / cell.row.original[accessorKey as keyof Pair]) * 100}%`,
              }}
            />
            <div
              className="h-[2px] rounded-full bg-noti-red opacity-50"
              style={{
                width: `${(cell.row.original[keySell] / cell.row.original[accessorKey as keyof Pair]) * 100}%`,
              }}
            />
          </div>
        </DataTableCell>
      );
    }

    if (accessorKey === 'activity.description') {
      return value ? (
        <div className="flex items-center gap-1">
          <img className="h-4 w-4" src={cell.row.original.activity?.logo} />
          {value}
        </div>
      ) : (
        <p className="text-left">--</p>
      );
    }

    if (accessorKey === 'activity.activityStatus') {
      if (!value) {
        return <p className="text-center">--</p>;
      }
      let color = '#00B8D8';
      let bar = 50;
      if (value === 'DONE') {
        color = '#17C467';
        bar = 100;
      }
      if (value === 'PLAN') {
        color = '#00B8D8';
        bar = 0;
      }
      return (
        <div className="flex items-center justify-between border border-[#131722] rounded-full w-full p-1 bg-[#000000]">
          <p className={`text-[11px] leading-[14px] font-semibold text-[${color}]`}>{value}</p>
          <div className="rounded-full w-[73px] bg-[#131722] p-[3px]">
            <div className="flex items-center justify-between relative">
              <div className={twMerge('w-[9px] h-[9px] rounded-full', bar === 0 ? 'bg-g-100 z-[1]' : 'bg-[#000000]')} />
              <div
                className={twMerge('w-[9px] h-[9px] rounded-full', bar === 50 ? 'bg-g-100 z-[1]' : 'bg-[#000000]')}
              />
              <div
                className={twMerge('w-[9px] h-[9px] rounded-full', bar === 100 ? 'bg-g-100 z-[1]' : 'bg-[#000000]')}
              />
              {bar > 0 ? (
                <div
                  className={`bg-g-100 absolute h-[9px] opacity-40 rounded-full ${bar < 100 && 'rounded-r-none'}`}
                  style={{ width: `${bar}%` }}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      );
    }

    if (
      accessorKey === 'presaleInfo.softCap' ||
      accessorKey === 'presaleInfo.hardCap' ||
      accessorKey === 'presaleInfo.maxBuy' ||
      accessorKey === 'presaleInfo.raised'
    ) {
      return (
        <DataTableCell key={header} float={'right'} overflow="hidden">
          <div className="flex items-center gap-2">
            <p
              className={`text-[13px] leading-[19.5px] font-medium text-[${accessorKey === 'presaleInfo.raised' ? '#EDA803' : '#D7D6DF'
                }]`}
            >
              {abbreviateNumber(value)}
            </p>
            <p className="text-[13px] leading-[19.5px] font-medium text-[#323346] uppercase">
              {constants.MAP_CHAIN_ID[row.original.chainId as keyof typeof constants.MAP_CHAIN_ID]}
            </p>
          </div>
        </DataTableCell>
      );
    }

    // handle duplicate id, check by header

    switch (header) {
      case 'Badge':
        return (
          <div key={header} className="flex h-5 items-center gap-2 rounded-lg bg-[#131722]">
            {value
              ?.sort((a: Badge, b: Badge) => b.type.localeCompare(a.type))
              ?.map?.((value: Badge, index: number) => (
                <SecurityBadge badge={value} key={value?.type ?? index} />
              ))}
          </div>
        );
      case 'Social':
        return (
          <div key={header} className="mx-auto w-fit">
            <SocialStatus isUpdated={!isEmpty(value)} socials={value} />
          </div>
        );
      case 'Dev of':
        return (
          <div key={header} className="mx-auto w-fit">
            {value ? (
              <DevOf devOf={formatNumeral(value) ?? 10} />
            ) : (
              <div className="flex h-5 min-w-[50px] items-center justify-center rounded-[4px] p-1 text-[13px] font-medium bg-black text-[#323346] mr-2">
                None
              </div>
            )}
          </div>
        );
      case 'Scan':
        return (
          <div key={header} className="mx-auto w-fit">
            <ScanStatus data={value} />
          </div>
        );
      case 'Activity':
        return (
          <p key={header} className="text-left text-13 font-medium text-[#D7D6DF]">
            {value ? StringUtils.convertToReadableFormat(value) : '--'}
          </p>
        );
      case 'Detail':
        return (
          <p key={header} className="text-right text-13 font-medium text-[#D7D6DF]">
            {value ?? '--'}
          </p>
        );
      case 'Status':
        return (
          <p key={header} className="text-right text-13 font-medium text-[#D7D6DF]">
            {value ?? '--'}
          </p>
        );
      case 'Upload':
        return (
          <p key={header} className="text-right text-13 font-medium text-[#D7D6DF]">
            {value ? new Date(value).toDateString() : '--'}
          </p>
        );
      case 'Proof':
        return <Proof key={header} link={value?.link} video={value?.video} image={value?.image} />;
      case 'Dex':
        return (
          <div key={header} className="mx-auto w-fit">
            <Tooltip
              classNames={{
                content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
              }}
              content={dex?.name}
            >
              <img src={dex?.avatar} alt="" className="h-[20px] w-[20px] rounded-full" />
            </Tooltip>
          </div>
        );
      case 'Action':
        return (
          <Action
            key={header}
            onCopy={() => {
              navigator.clipboard.writeText(row.original.address);
            }}
            onShowChart={handleRedirect(row.original.chainId, row.original.address, row.original.baseToken)}
          />
        );

      case 'MCAP':
        return (
          <DataTableCell key={header} overflow="hidden" float={meta?.isNumeric ? 'right' : 'left'}>
            {/* <p className="text-right text-13 font-medium text-[#D7D6DF]">{numberFormatWithAbbreviated(value)}</p> */}
            {/* {formatNumeral(value as number, numeralType.VOLUME_NUMBER_WITH_2DECIMAL, '', '')} */}
            {formatNumeral(value as number, numeralType.VOLUME_NUMBER_WITH_2DECIMAL, '', '')}
          </DataTableCell>
        );
    }

    return (
      <DataTableCell key={header} overflow="hidden" float={meta?.isNumeric ? 'right' : 'left'}>
        <p className="text-right text-13 font-medium text-[#D7D6DF]">{numberFormat(String(value))}</p>
      </DataTableCell>
    );
  };

  return (
    <Box as="tr" className="group h-[45px] bg-transparent hover:bg-[#0d111a]">
      {row.getVisibleCells().map((cell) => {
        const meta: any = cell.column.columnDef;
        const header = cell.column.columnDef.header as string;

        return (
          <td
            className={twMerge(
              'h-full border-b border-r border-b-[#131722] border-r-[#131722] px-1 text-center text-[13px] font-medium text-[#D7D6DF] last:border-r-[0px]',
              meta?.textAlign,
            )}
            key={header ?? cell.id}
          >
            {renderCell(cell)}
          </td>
        );
      })}
    </Box>
  );
}

export default PairItem;
