export const Star2Icon: React.FC<{
  size?: string;
  color?: string;
}> = ({ size = 17, color = '#323346' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_277_7954)">
        <path
          id="Vector"
          d="M16.3661 7.52764L12.8849 11.2096L13.4205 14.49L13.6883 16.2975C13.7552 16.6992 13.3535 17.0339 12.9519 16.8331L11.1443 15.8958L8.53347 14.49L4.44978 16.6992L4.182 16.8331C3.84727 17.0339 3.51255 16.7661 3.4456 16.4314C3.4456 16.3645 3.4456 16.3645 3.4456 16.2975L4.24895 11.1427L3.98116 10.9419L0.633884 7.52764C0.366102 7.19291 0.499993 6.65734 0.901667 6.5904L5.7887 5.78705L7.9979 1.1678C8.19874 0.766128 8.7343 0.766128 8.86819 1.1678L11.2113 5.78705L13.2866 6.12178L16.0983 6.5904C16.5 6.65734 16.6339 7.19291 16.3661 7.52764Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_277_7954">
          <rect width="16" height="16" fill="white" transform="translate(0.5 0.900024)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const StarOutlineIcon: React.FC<{
  size?: string;
  color?: string;
}> = ({ size = 19, color = '#323346' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_77_8105)">
        <path
          id="Vector"
          d="M17.5 8.00001L13.9 11.8L14.4 15.2L14.7 17.1C14.8 17.5 14.3 17.9 14 17.7L12.1 16.7L9.39996 15.3L5.19996 17.6L4.89996 17.8C4.59996 18 4.19996 17.7 4.09996 17.4V17.3L4.89996 12L4.69996 11.8L1.29996 8.00001C0.999962 7.70001 1.19996 7.10001 1.59996 7.10001L6.59996 6.30001L8.89996 1.50001C9.09996 1.10001 9.59996 1.10001 9.79996 1.50001L12.1 6.30001L14.3 6.60001L17.2 7.10001C17.6 7.10001 17.8 7.70001 17.5 8.00001Z"
          stroke={color}
          strokeWidth="1.75"
          strokeMiterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_77_8105">
          <rect width="18.2" height="18.2" fill="white" transform="translate(0.299988 0.300018)" />
        </clipPath>
      </defs>
    </svg>
  );
};
