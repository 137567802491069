import { cn } from '@nextui-org/react';
import { useAppSelector } from 'shared/hooks/reduxHook';
import BoostAds from './BoostAds';
import PairTrending from './PairTrending';
import PresaleTrending from './PresaleTrending';

const Trending = () => {
  const layout = useAppSelector((state) => state.layout.layout);
  return (
    <div
      className={cn(
        'grid gap-3 md:grid-cols-[1fr_168px]',
        // because there no boost Ads --> error
        // { 'lg:grid-cols-[168px_1fr]': layout === 'right' && boostAdsData },
        // { 'lg:grid-cols-[168px_1fr]': layout === 'right' },
      )}
    >
      <div className="space-y-3 overflow-x-auto overflow-y-visible">
        <PairTrending />
        <PresaleTrending />
      </div>
      <BoostAds />
    </div>
  );
};

export default Trending;
