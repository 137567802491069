import { cn } from '@nextui-org/react';
import { ArrowRight } from 'assets/icons';
import { OnChainTabEnum } from 'configs/enums';
import useTopTrendings from 'modules/home/hooks/useTopTrendings';
import { useIsOverflow } from 'modules/trade/hooks/useIsOverflow';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import FireTitle from 'shared/components/FireTitle';
import LinkToTradePage from 'shared/components/LinkToTradePage';
import TokenIcon from 'shared/components/TokenIcon';
import Chain from 'types/Chain';
import { getChainIcon, getChainIdFromChainName } from 'utils/chain';
import { getImgUrl } from 'utils/file';
import './index.css';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import { updateTabActive, updateZone } from 'shared/state/redux/commonSlice';

let timer: any = null;

const PairTrending = () => {
  const loopRef = useRef<any>();
  const dispatch = useAppDispatch();

  const locationApp = useLocation();
  const navigate = useNavigate();
  const [selectedChain, setSelectedChain] = useState<Chain | undefined>();

  // const dexes = useDexes();

  const chainId = useMemo(() => {
    const chainName = locationApp.pathname.split('/')?.[1];
    const chainIdFromName = getChainIdFromChainName(chainName);
    return selectedChain?.chainId ?? chainIdFromName ? `${chainIdFromName}` : undefined;
  }, [locationApp.pathname, selectedChain]);

  const { data: trendingData, isLoading, refetch } = useTopTrendings({ chainId, pageIndex: 1, pageSize: 12 }, true);

  const isOverflow = useIsOverflow(loopRef, !!(isLoading === false && trendingData));
  useEffect(() => {
    clearInterval(timer);
    // 3 mins
    timer = setInterval(() => refetch(), 3 * 60 * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [chainId]);

  const showPairTrending = [
    ...(trendingData?.data ?? []),
    ...(trendingData?.data ?? []),
    ...(trendingData?.data ?? []),
  ];

  return (
    <div
      style={{
        background: 'linear-gradient(0deg, #000000, #000000)',
      }}
      className='rounded-lg'
    >
      <div className="bg-g-mix-1 flex h-10 items-center rounded-lg pl-3">
        <div className="flex flex-shrink-0 items-center gap-1 lg:gap-[9px]">
          <FireTitle title="PAIR TRENDING" titleClassName="hidden lg:block" baseClassName="lg:min-w-[170px]" />
          {/* <SelectChain selectedChain={selectedChain} setSelectedChain={setSelectedChain} /> */}
        </div>

        <div ref={loopRef} className="loop-section ml-3 lg:ml-6">
          <div
            className={cn('loop-div', { 'loop-animation': isOverflow })}
            id="trending_loop"
            style={{
              // @ts-ignore
              '--timeInSecond': `move-right-to-left linear ${showPairTrending.length}s infinite`,
            }}
          >
            <div className="loop">
              {Array.from({ length: isOverflow ? 2 : 1 }, () => (
                <>
                  {showPairTrending?.map((item: any, index: number) => {
                    return (
                      <LinkToTradePage
                        className="flex items-center gap-1 cursor-pointer mr-3 lg:mr-6"
                        key={`${item._id}_${index}`}
                        baseToken={item.baseToken}
                        chainId={item.chainId}
                        pair={item.address}
                      >
                        {/* fallbackSrc={dex?.avatar ?? StaticsImages.unknowLogo} */}
                        <span className="text-orange-3 text-13 font-semibold ">
                          #{(index % ((trendingData?.data ?? []).length || 1)) + 1}
                        </span>
                        <TokenIcon
                          iconSrc={getImgUrl(item?.baseTokenLogo, item?.baseToken)}
                          subIconSrc={getChainIcon(Number(item.chainId))}
                          iconClassName="w-[21px] h-[21px] bg-[#cccccc3d] rounded-full"
                          baseClassName="w-[21px] h-[21px]"
                        />
                        <span className="text-14 font-medium text-gray-1500">{item.baseTokenSymbol}</span>
                      </LinkToTradePage>
                    );
                  })}
                </>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-r-orange-10/50 h-full rounded-r-lg">
          <button className="link-btn" onClick={() => navigate(`#${OnChainTabEnum.TOP_TRENDINGS}`)}>
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PairTrending;
