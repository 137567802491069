import { useDisclosure, useMediaQuery } from "@chakra-ui/react";
import { cn, Input } from '@nextui-org/react';
import { searchIcon } from 'assets/icons';
import SearchModal from 'shared/components/SearchModal';

const InputSearch = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [isLargerThan1400] = useMediaQuery('(min-width: 1400px)');

  const handleInputFocus = () => {
    if (!isOpen) {
      onOpen(); // Open the modal
    }
  };

  const onTurnOffSearchMode = () => {
    onClose();
  }

  return (
    <>
      <Input
        onFocus={handleInputFocus}
        classNames={{
          base: cn('rounded-lg w-auto flex-1 bg-[#1A1A1A] p-1 max-xl:flex-1 ', {
            'max-w-[665px]': isLargerThan1400
          }),
          inputWrapper:
            'bg-transparent focus-within:!border-black-5 focus-within:!bg-transparent p-0 rounded-lg hover:!bg-transparent shadow-none min-h-full h-full',
          input: 'text-[13px] placeholder:text-[#4E4E4E] !text-white p-0',
        }}
        // className={cn({ "border !border-[#FF6F00]": isOpen })}

        placeholder="Search pair by symbol, name, contract, token"
        // onFocus={() => onOpen()}
        startContent={
          <div className="flex h-full w-7  items-center justify-center rounded-[3px] rounded-l-[6px]  bg-[#010101] p-1 xl:w-14 xl:px-[10px]">
            <img src={searchIcon} />
          </div>
        }
      />
      <SearchModal isOpen={isOpen} onClose={onTurnOffSearchMode} />
    </>
  );
};

export default InputSearch;
