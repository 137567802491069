import client from 'configs/axios';
import ApiResponse from 'types/ApiResponse';
import { IQueryParamsTrending } from 'types/Pair';
import { SignInfo, TPackage } from 'types/Trending';

export type ResearchParams = {
  signInfo: SignInfo;
  address: string;
  chainId: string;
  startAt: number;
  endAt: number;
  package: string;
  socialLinks: Record<string, string | string[]>;
  telegramContact: string;
  baseTokenLogo: string;
  txHash: string;
};

export async function updateResearch(params: ResearchParams) {
  return await client.post<
    ApiResponse<{
      data: any;
    }>
  >('/pairs/research', params);
}

export async function checkSocialUrlResearch(params: { chainId: string } & Record<string, string>) {
  const res = await client.get<ApiResponse<any>>('/pairs/research/social-links', { params });
  return res.data.data;
}

// export async function getTrendingRequests(params: { chainId: string; position: string }) {
//   const res = await client.get<ApiResponse<any>>('/pairs/trending-requests', { params });
//   return res.data.data;
// }
export async function getResearchPackages() {
  const res = await client.get<ApiResponse<TPackage[]>>('/pairs/research-packages');
  return res;
}

export async function getResearchRequests(params: { chainId: string }) {
  const res = await client.get<ApiResponse<any>>('/pairs/research-requests', { params });
  return res.data.data;
}

export async function getTopResearchs(params: IQueryParamsTrending) {
  const res = await client.get<ApiResponse<any[]>>('/pairs/research', { params });
  return res.data;
}
