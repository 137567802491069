import { cn } from '@nextui-org/react'
import React, { PropsWithChildren } from 'react'
import { Link, LinkProps } from 'react-router-dom'

type Props = Omit<LinkProps, 'to'> & PropsWithChildren<
    {
        disabled?: boolean,
        active?: boolean,
        to?: string
    }
>

export default function LinkCustom({ to, disabled, children, ...props }: Props) {
    if (to) {
        return <Link to={to} {...props}>
            {children}
        </Link>
    }
    return (
        <div className={cn(props.className, { 'opacity-50 cursor-default hover:bg-transparent': disabled })}>
            {children}
        </div>
    )
}