import client from 'configs/axios';
import { ChainIds } from 'configs/chain';
import ApiResponse from 'types/ApiResponse';
import { SocialLinks } from 'types/SocialInfo';
import { SignInfo } from 'types/Trending';

export type BoostAdsAdsParams = {
  signInfo: SignInfo;
  chainId: string;
  address: string;
  description: string;
  baseTokenLogo: string;
  banner: string;
  startAt: number;
  socialLinks: Record<string, string | string[]>;
  telegramContact: string;
  txHash: string;
  package: string;
};

export type TBoostAdsResponse = {
  _id?: string;
  address?: string;
  chainId?: string;
  factory?: string;
  description?: string;
  startAt?: number;
  endAt?: number;
  socialLinks?: SocialLinks;
  baseToken?: string;
  baseTokenSymbol?: string;
  baseTokenName?: string;
  baseTokenLogo?: string;
  banner?: string;
  quoteToken?: string;
  quoteTokenSymbol?: string;
  quoteTokenName?: string;
  telegramContact?: string;
  status?: number;
  createdBy?: string;
  createdAt?: number;
  updatedAt?: number;
  pair?: string;
  __v?: number;
}[];

export async function updateBoostAds(params: BoostAdsAdsParams) {
  return await client.post<
    ApiResponse<{
      data: any;
    }>
  >('/ads/boosts', params);
}

export async function getBoostAdsRequests(params: { chainId: string; tokenAddress?: string }) {
  const res = await client.get<ApiResponse<any>>('/ads/boost-requests', { params });
  return res.data.data;
}

export async function checkSocialUrl(params: { chainId: string } & Record<string, string>) {
  const res = await client.get<ApiResponse<any>>('/ads/boosts/social-links', { params });

  return res.data.data;
}

export async function getBoostAds(params?: { chainId?: string | ChainIds, tokenAddress?: string }) {
  const res = await client.get<ApiResponse<TBoostAdsResponse>>('/ads/boosts', { params });
  return res.data.data;
}
