import { bookIcon, docsIcon, headphoneIcon, heartIcon, lockIcon, warningIcon } from 'assets/icons';
import { trustedPartnerImg } from 'assets/images';
import { SOCIALS } from 'shared/components/Sidebar';
import { Link } from '@nextui-org/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const INFOS = [
  {
    icon: bookIcon,
    name: 'Terms of Service',
  },
  {
    icon: lockIcon,
    name: 'Privacy Policy',
  },
  {
    icon: headphoneIcon,
    name: 'Contact Ads MKT',
  },
  {
    icon: docsIcon,
    name: 'Docs',
  },
  {
    icon: warningIcon,
    name: 'Disclaimer',
  },
  {
    icon: heartIcon,
    name: 'Feedback',
  },
];

const Footer = () => {
  return (
    <footer className='pt-2'>
      <div className="relative flex justify-end gap-3 bg-[#0D0D14] px-3 py-1 max-lg:flex-col lg:items-center lg:gap-6 lg:px-6 z-10">
        <div className="flex items-center gap-6 max-lg:justify-between">
          <div className="space-y-0,5">
            {INFOS.slice(0, 3).map((item, index) => (
              <div key={index} className="flex w-fit items-center gap-1">
                <img src={item.icon} className="h-3 min-w-[20px]" />
                <span className="text-10 text-[#808080]">{item.name}</span>
              </div>
            ))}
          </div>
          <div className="space-y-0.5">
            {INFOS.slice(3, INFOS.length).map((item, index) => (
              <div key={index} className="flex w-fit items-center gap-1">
                <img src={item.icon} className="h-3 min-w-[20px]" />
                <span className="text-10 text-[#808080]">{item.name}</span>
              </div>
            ))}
          </div>
          <img src={trustedPartnerImg} className="w-[48px]" />
        </div>

        <div className="flex h-full flex-1 items-center gap-6">
          {Array.from({ length: 6 }, (_, index) => (
            <div
              className={twMerge('h-[38px] flex-1 rounded-[10px] bg-[#1A1A1A]', index > 2 && 'max-lg:hidden')}
              key={index}
            />
          ))}
        </div>

        <div className="flex flex-col justify-between max-lg:items-center">
          <span className="text-12 text-[#808080]">Join our community</span>

          <div className="flex items-center gap-4 mt-0.5">
            {SOCIALS.map((social) => (
              <Link href="">{social.icon('#FF6F00')}</Link>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
