import { Button as ButtonBase, HStack, Menu, MenuButton, MenuItem, MenuList, Text, useClipboard } from '@chakra-ui/react';
import { themeConstants } from 'configs/theme';
import { BiChevronDown } from 'react-icons/bi';
import { IoIosCopy, IoMdCopy } from 'react-icons/io';
import Box from 'shared/components/Box';
import Button from 'shared/components/Button/button';
import useAccount from 'shared/hooks/useAccount';
import useTranslation from 'shared/hooks/useTranslation';
import { formatAddress, fromRawBalance } from 'utils/format';


type Props = {}

export default function ConnectWallet({ }: Props) {
    const { t } = useTranslation('header');
    const { chain, balance, address, isConnected, connect, disconnect } = useAccount(true);
    const { onCopy, hasCopied } = useClipboard(address || '');

    if (isConnected)
        return (
            <Menu>
                <MenuButton as={ButtonBase} className='flex-shrink-0 !bg-r-orange-6 dark:!bg-r-orange-6 rounded-[6px] border border-[#F57620]  !px-3 !py-0 !h-[36px] !min-h-[36px]' rightIcon={<BiChevronDown />}>
                    <Text noOfLines={1} color="white" fontSize="13px">{formatAddress(address || '-')}</Text>
                </MenuButton>
                <MenuList zIndex={1000000} bg="#0D0D14">
                    <MenuItem fontSize="13px" as={HStack}>
                        <Text flex={1}>{t('balance')}</Text>
                        <Text fontWeight={600} color={themeConstants.green} fontSize="xs">
                            {fromRawBalance(balance || '0', chain.nativeCurrency.decimals)?.toString()} {chain?.nativeCurrency?.symbol}
                        </Text>
                    </MenuItem>
                    <MenuItem
                        variant="link"
                        backgroundColor="transparent"
                        color={themeConstants.red}
                        onClick={() => {
                            disconnect();
                        }}
                        justifyContent="start"
                        className="hover:!no-underline"
                        fontSize="13px"
                        as={ButtonBase}>

                        {t('disconnect')}
                    </MenuItem>
                </MenuList>
            </Menu>
        )
    return (
        <Box
            onClick={connect}
            boxClassName="p-[6px] !px-3  flex items-center gap-[6px] flex-shrink-0 cursor-pointer group/item"
        >
            <span className="hidden text-[13px] text-gray-700 group-hover/item:text-gray-600 xl:inline">Connect</span>
        </Box>
    );


}