import { shieldErrorIcon, shieldSuccessIcon, shieldWarningIcon } from 'assets/icons';
import { Tooltip } from '@nextui-org/react';
import React from 'react';
import { twMerge } from 'tailwind-merge';

const SCAN_STATUS: any = {
  SUCCESS: {
    name: 'success',
    icon: shieldSuccessIcon,
    color: 'text-[#17C467]',
    colorBg: 'bg-[#17C46780]',
  },
  WARNING: {
    name: 'warning',
    icon: shieldWarningIcon,
    color: 'text-[#EDA803]',
    colorBg: 'bg-[#EDA80380]',
  },
  DANGER: {
    name: 'danger',
    icon: shieldErrorIcon,
    color: 'text-[#FF7E8D]',
    colorBg: 'bg-[#FF7E8D80]',
  },
};

interface Props {
  // scanKey: string;
  // amount: number;
  data: Record<string, any>;
}

const ScanStatus: React.FC<Props> = ({ data = {} }) => {

  // const scanStatus = SCAN_STATUS[scanKey];

  const toTitleCase = (str: string) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') // Insert a space between lowercase and uppercase letters
      .replace(/^./, match => match.toUpperCase()); // Capitalize the first letter
  }

  const formatDataToArr = Object.keys(data).map(key => ({ name: toTitleCase(key), ...data[key] }))

  // const successAmount = formatDataToArr.filter(i => i.mark === "SUCCESS").length
  const warningAmount = formatDataToArr.filter(i => i.mark === "WARNING").length
  const dangerAmount = formatDataToArr.filter(i => i.mark === "DANGER").length

  const scanStatus = (
    dangerAmount > 0
      ? { ...SCAN_STATUS.DANGER, amount: dangerAmount + warningAmount }
      : warningAmount > 0
        ? { ...SCAN_STATUS.WARNING, amount: warningAmount }
        : { ...SCAN_STATUS.SUCCESS, amount: 0 }
  )

  return (
    <Tooltip
      classNames={{
        content: 'bg-black p-2 px-3 rounded-lg border border-[#D7D6DF] min-w-[240px]',
      }}
      content={
        formatDataToArr
          .filter(i => i.mark !== "SUCCESS")
          .map(item => (
            <div className="flex items-center justify-between gap-[6px] w-full">
              <span className="text-13 font-medium text-[#D7D6DF]">{item.name}</span>
              <div className="flex items-center gap-1">
                <span className="text-13 font-medium text-[#D7D6DF]">{item.value}</span>
                {item.mark === 'WARNING' && <div className="h-[10px] w-[10px] rounded-full bg-[#EDA803]" />}
                {item.mark === 'DANGER' && <div className="h-[10px] w-[10px] rounded-full bg-[#FF7E8D]" />}
              </div>
            </div>
          ))
      }
      closeDelay={0}
      placement='top'
      isOpen={formatDataToArr.filter(i => i.mark !== "SUCCESS").length > 0 ? undefined : false}
    >
      <div
        className={twMerge(
          'flex h-5 w-fit min-w-[50px] items-center justify-center gap-[6px] rounded-[4px] p-1',
          scanStatus.colorBg,
        )}
      >
        <img src={scanStatus.icon} />
        <span className={twMerge('text-[13px] font-medium', scanStatus.color)}>
          {scanStatus.amount}
        </span>
      </div>
    </Tooltip>
  );
};

export default ScanStatus;
