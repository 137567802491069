import { Box, useMediaQuery } from '@chakra-ui/react';
import { Button, cn, Dropdown, DropdownItem, DropdownMenu, DropdownTrigger, Tab, Tabs } from '@nextui-org/react';
import {
  ColumnDef,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { infoCircleBlackIcon } from 'assets/icons';
import constants from 'configs/constants';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SortIcon } from 'shared/components/Icons/Filter';
import { twMerge } from 'tailwind-merge';
import Pair, { HomeScreen, IQueryParamsInfoZone, IQueryParamsPresale, IQueryParamsTrending } from 'types/Pair';
import PairFilter from './PairFilter';
import PairItem from './PairItem';
import useTranslation from 'shared/hooks/useTranslation';
import { ClockIcon, DateIcon } from 'shared/components/Icons/Trade';
import SwitchUtcMode from 'shared/components/TimeDisplay/SwitchUtcMode';
import SwitchViewTimeAgoMode from 'shared/components/TimeDisplay/SwitchViewTimeAgoMode';
type DataTableProps<Data extends Pair> = {
  data: Data[];
  columns: ColumnDef<Data, any>[];
  currentPage: number;
  limit: number;
  homeScreen?: HomeScreen;
  filter?: IQueryParamsTrending | IQueryParamsPresale | IQueryParamsInfoZone;
  setFilter?: (values: IQueryParamsTrending | IQueryParamsPresale | IQueryParamsInfoZone) => void;
};

function PairTableV2<Data extends Pair>({
  data,
  columns,
  currentPage,
  limit,
  homeScreen,
  filter = {},
  setFilter = () => { },
}: DataTableProps<Data>) {
  const { t } = useTranslation('trade');
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [sorting, setSorting] = useState<SortingState>(
    homeScreen === HomeScreen.NEW_PAIR
      ? [
        {
          id: 'tradingStartTime',
          desc: true,
        },
      ]
      : [],
  );
  // const bgTableHeader = useColorModeValue(themeConstants.rowHoverLight, themeConstants.rowHoverDark);
  const [isLargerThan1300] = useMediaQuery('(min-width: 1300px)');
  const [, setTimer] = useState(0);

  const gridTemplateColumns = useMemo(() => {
    const screenKey = isLargerThan1300 ? 'DEFAULT' : 'MEDIUM';
    if (!homeScreen) return undefined;
    return [HomeScreen.NEW_PAIR, HomeScreen.SOCIAL_NETWORK_UPDATES].includes(homeScreen as any)
      ? constants.GRID_TEMPLATE_COLUMNS?.[homeScreen]?.[screenKey]
      : undefined;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeScreen]);

  useEffect(() => {
    let id: number;
    if (homeScreen === HomeScreen.NEW_PAIR) {
      // @ts-ignore
      id = setInterval(() => {
        setTimer((prev) => {
          return prev + 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(id);
    };
  }, [homeScreen]);

  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      sorting,
      columnVisibility,
    },
  });
  const listShowColumnVisibility = [HomeScreen.SOCIAL_INFOS]
  return (
    <div>
      {
        homeScreen && listShowColumnVisibility.includes(homeScreen)
          ?
          <div className='flex justify-end'>
            <div className='flex justify-end items-center gap-2 mr-4 rounded-t-[10px] bg-[#131722] p-[7px] lg:gap-4'>


              {/* <Dropdown>
                <DropdownTrigger>
                  <Button
                    className={cn(
                      'hover:bg-r-orange-1 h-6  rounded-lg border border-transparent bg-black text-[13px] font-bold text-[#323346] hover:border-[#F68B22] hover:bg-clip-text hover:text-transparent max-lg:min-w-20 lg:w-[47px]',
                      //  'bg-r-orange-1 border-[#F68B22] bg-clip-text text-transparent',
                    )}
                  >
                    Add more
                  </Button>

                </DropdownTrigger>
                <DropdownMenu aria-label="Actions" className='bg-[#131722]'>
                  {table
                    .getAllColumns()
                    .filter((column) => column.getCanHide())
                    .map((column) => {
                      return (

                        <DropdownItem onClick={() => {
                          column.toggleVisibility(!column.getIsVisible())
                        }}
                          key={column.id}
                          className={cn(
                            'capitalize hover:bg-g-150 flex cursor-pointer items-center justify-between gap-[6px] rounded-[4px] px-1 py-[2px] text-white',
                            !column.getIsVisible() && 'bg-g-150',
                            // option.disabled && 'bg-[#232323] hover:bg-[#232323] cursor-default opacity-90 text-[#bdbdbd]',
                          )}
                        >
                          {column.columnDef.header as string | JSX.Element ?? ''}
                        </DropdownItem>
                      )
                    })}
                </DropdownMenu>
              </Dropdown> */}
            </div>
          </div>
          : null
      }
      <div className="w-full overflow-x-auto">
        <table className="w-full bg-transparent">
          {table.getHeaderGroups().map((headerGroup) => {
            return (
              <thead key={headerGroup.id}>
                <tr className="h-[30px] bg-[#131722]">
                  {headerGroup.headers.map((header) => {
                    const meta: any = header.column.columnDef.meta;
                    // const title = header.column.columnDef.header;

                    return (
                      <th
                        className={twMerge(
                          'min-w-[86px] px-1 text-center whitespace-nowrap',
                          header.id === '_id' && 'min-w-11',
                          header.id === 'baseTokenSymbol' && 'min-w-[225px] pl-[60px]',
                          header.id === 'factory' && 'min-w-11',
                          (header.id === 'pairCreatedTime' || header.id === 'tradingStartTime') && 'min-w-[100px]',
                        )}
                        key={header.id}
                      >
                        <div className={twMerge('flex items-center justify-center gap-[2px]', meta?.align)}>
                          {meta?.isSwitchViewTimeMode && (
                            <div className="flex-1">
                              <SwitchViewTimeAgoMode
                                displayTimeAgo={filter.isDisplayTimeAgo ?? false}
                                onChange={(value) => {
                                  setFilter({
                                    ...filter,
                                    isDisplayTimeAgo: value,
                                  });
                                }}
                              />
                            </div>
                          )}
                          {meta?.isInfo && (
                            <div className={twMerge('hidden', meta?.isInfo && 'block cursor-pointer')}>
                              <img src={infoCircleBlackIcon} alt="" />
                            </div>
                          )}
                          {meta?.isFilter && <PairFilter id={header.id} filter={filter} setFilter={setFilter} />}

                          <span className="text-13 font-bold text-[#D7D6DF]">
                            {flexRender(header.column.columnDef.header, header.getContext())}
                          </span>

                          {meta?.isSort && (
                            <div
                              className={twMerge('hidden', meta?.isSort && 'block cursor-pointer')}
                              onClick={() => {
                                if (filter.sortField !== header.id) {
                                  setFilter({ ...filter, sortField: header.id, sortOrder: 'desc' });
                                } else {
                                  if (filter.sortOrder === 'desc') {
                                    setFilter({ ...filter, sortOrder: 'asc' });
                                  } else {
                                    setFilter(_.omit(filter, ['sortField', 'sortOrder']));
                                  }
                                }
                              }}
                            >
                              <SortIcon
                                color={
                                  filter.sortField === header.id && filter.sortOrder === 'desc' ? '#FF9200' : '#323346'
                                }
                                color2={
                                  filter.sortField === header.id && filter.sortOrder === 'asc' ? '#FF9200' : '#323346'
                                }
                              />
                            </div>
                          )}

                          {meta?.isUTC && (
                            <SwitchUtcMode
                              selectedKey={filter?.isUTC ? 'utc' : 'local'}
                              onChange={(value) => {
                                setFilter({
                                  ...filter,
                                  isUTC: value === 'utc' ? true : false,
                                });
                              }}
                            />
                          )}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
            );
          })}
          <tbody>
            {table.getRowModel().rows.map((row) => {
              return (
                <PairItem
                  gridTemplateColumns={gridTemplateColumns}
                  key={row.id}
                  row={row}
                  currentPage={currentPage}
                  limit={limit}
                  homeScreen={homeScreen}
                  setting={{
                    isUTC: filter.isUTC,
                    isDisplayTimeAgo: filter.isDisplayTimeAgo,
                  }}
                />
              );
            })}
          </tbody>
        </table>
      </div>

    </div>
  );
}
export default PairTableV2;
