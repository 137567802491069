import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import walletHidden from './redux/walletHiddenSlice';
import favoriteToken from './redux/favoriteTokenSlice';
import historyToken from './redux/historyTokenSlice';
import multiChart from './redux/multiChartSlice';
import tokenPrice from './redux/tokenPriceSlice';
import addressSelected from './redux/selectAddressSlice';
import heightChart from './redux/defaultHeightChartSlice';
import swapFormVisible from './redux/swapFormVisible';
import transactionTableHeight from './redux/transactionTableHeight';
import goPlusExpand from './redux/goPlusExpand';
import trending from './redux/trendingSlice';
import solanaReducer from './redux/solana';
import searchReducer from './redux/searchSlice';
import layoutReducer from './redux/layoutSlice';
import commonReducer from './redux/commonSlice';

const rootReducer = {
  walletHidden,
  favoriteToken,
  historyToken,
  multiChart,
  tokenPrice,
  addressSelected,
  heightChart,
  swapFormVisible,
  transactionTableHeight,
  goPlusExpand,
  trending,
  solana: solanaReducer,
  search: searchReducer,
  layout: layoutReducer,
  common: commonReducer
  // Add more reducers here if needed.
};
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }),
});

const persistor = persistStore(store);

export { store, persistor };

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
