import { PopoverBody, PopoverHeader } from "@chakra-ui/react";
import { twMerge } from "tailwind-merge";

const MultiTag = ({ id, tempValue = [], setTempValue }: any) => {

  const optionsList: any = {
    badges: {
      label: "Badge",
      options: [
        {
          label: 'Audit',
          value: 'AUDIT',
          color: '#00B8D880',
        },
        {
          label: 'Kyc',
          value: 'KYC',
          color: '#17C46780',
        },
        {
          label: 'Safu',
          value: 'SAFU',
          color: '#FF868D80',
        },
        {
          label: 'Doxx',
          value: 'DOXXED',
          color: '#EDA80380',
        },
        {
          label: 'AMA',
          value: 'SCAM',
          color: '#8551A780',
        },
      ],
    },

  }

  const opt = optionsList[id] || {}

  return (
    <>
      <PopoverHeader border='0' padding={3}>
        <p className="text-[13px] font-medium leading-[19.5px] text-left">
          {opt.label}
        </p>
      </PopoverHeader>

      <PopoverBody px={3} py={0}>
        <div className="flex gap-1">
          {opt.options.map((item: any) => (
            <button
              key={item.value}
              style={{ background: tempValue.includes(item.value) ? item.color : '#131722' }}
              className={twMerge(
                'rounded-[5px] text-xs font-medium bg-[#131722] text-[#323346] flex-1 pt-[1px] pb-[3px] hover:text-white',
                tempValue.includes(item.value) && 'text-white'
              )}
              onClick={() =>
                tempValue.includes(item.value)
                  ? setTempValue(tempValue.filter((i: any) => i !== item.value))
                  : setTempValue([...tempValue, item.value])
              }
            >
              {item.label}
            </button>
          ))}
        </div>
      </PopoverBody>
    </>
  )
}

export default MultiTag;