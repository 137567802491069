import BigNumber from 'bignumber.js';
import { BIG_TEN } from './bigNumber';
import { abbreviateNumber } from './format';

export const numberFormat = (value: number | string | BigNumber, maxFraction = 0) => {
  const opts = {
    maximumFractionDigits: maxFraction,
  };
  if (typeof value === 'object') value = value.toNumber();
  else value = Number(value);
  return value.toLocaleString('en-US', opts);
};

export const formatNumberPrice = (value: string | number | BigNumber, maxFraction = 10): string => {
  if (new BigNumber(value).isZero()) return '0';

  const minValue = new BigNumber(0.01).dividedBy(BIG_TEN.pow(maxFraction));
  if (new BigNumber(value).gte(0) && new BigNumber(value).lt(minValue)) return `<${minValue.toFixed()}`;

  const maxValue = new BigNumber(10000).multipliedBy(BIG_TEN.pow(maxFraction));
  if (new BigNumber(value).gt(maxValue)) return `>${abbreviateNumber(maxValue.toNumber())}`;

  return numberFormat(value, maxFraction);
};
