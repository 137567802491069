import { useQuery } from '@tanstack/react-query';
import { getTrendings } from 'apis/pair';
import constants from 'configs/constants';
import queryKey from 'configs/queryKey';
import { IQueryParamsTrending } from 'types/Pair';

interface Params {
  chainId?: string;
  pageIndex?: number;
  pageSize?: number;
}

function useTopTrendings(props: IQueryParamsTrending, autoFetch = false) {
  return useQuery(
    [queryKey.TOP_TRENDINGS, ...Object.values(props).filter((item) => !!item)],
    () => getTrendings(props),
    {
      cacheTime: constants.CACHE_TIME[queryKey.PAIR_ACTIVITY],
      enabled: autoFetch,
    },
  );
}

export default useTopTrendings;
