import { PopoverBody } from "@chakra-ui/react";
import Switch from "shared/components/Switch";

const SwitchYesNo = ({ id, tempValue, setTempValue }: any) => {

  const label: any = {
    socialLinks: 'Updated Social and info',
    devOf: 'Previous project',
    scan: 'Safe scan',
  }

  return (
    <PopoverBody className="space-y-1.5 !p-3 !pb-[0px]">
      <div className="flex items-center justify-between w-full">
        <p className="text-[13px] font-medium leading-[19.5px]">
          {label[id]}
        </p>
        <Switch value={tempValue} onChange={setTempValue} />
      </div>
    </PopoverBody>
  )
}

export default SwitchYesNo;