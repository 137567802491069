import { ArrowRight, firePurple } from 'assets/icons';
import './index.css';
import FireTitle from 'shared/components/FireTitle';
import TokenIcon from 'shared/components/TokenIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMemo, useRef, useState } from 'react';
import { getChainIcon, getChainIdFromChainName, getChainNameFromChainId } from 'utils/chain';
import { getBlockChainLogo } from 'utils/misc';
import Chain from 'types/Chain';
import useTopPresales from 'modules/home/hooks/useTopPresales';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import { buildHref } from 'utils/path';
import path from 'configs/path';
import useDexes from 'shared/hooks/useDexes';
import StaticsImages from 'assets/icons/statics';
import LinkToTradePage from 'shared/components/LinkToTradePage';
import { OnChainTabEnum } from 'configs/enums';
import { useIsOverflow } from 'modules/trade/hooks/useIsOverflow';
import { cn } from '@nextui-org/react';

const PresaleTrending = () => {
  const loopRef = useRef<any>();

  const locationApp = useLocation();
  const navigate = useNavigate();
  const [selectedChain, setSelectedChain] = useState<Chain | undefined>();
  const dispatch = useAppDispatch();

  const isOverflow = useIsOverflow(loopRef);

  const dexes = useDexes();

  const chainId = useMemo(() => {
    const chainName = locationApp.pathname.split('/')?.[1];
    const chainIdFromName = getChainIdFromChainName(chainName);
    return selectedChain?.chainId ?? chainIdFromName ? `${getChainIdFromChainName(chainName)}` : undefined;
  }, [locationApp.pathname, selectedChain]);

  const { data: topPresales } = useTopPresales({ chainId, pageIndex: 1, pageSize: 12 }, true);

  const handleRedirect = (chainId: string, pair: string, baseToken: string) => {
    return () => {
      if (pair && baseToken) {
        dispatch(addAddress({ address: pair, baseToken: baseToken }));
      }

      const redirect_path = buildHref(
        path.TRADE,
        {
          address: baseToken,
          chain: getChainNameFromChainId(Number(chainId)) as string,
        },
        { pair },
      );
      window.location.href = redirect_path;
      // redirect(redirect_path);
    };
  };
  const showPresaleTrending = [...(topPresales?.data ?? [])];

  return (
    <div
      style={{
        background: 'linear-gradient(0deg, #000000, #000000)',
      }}
      className="rounded-lg"
    >
      <div className="bg-g-mix-2 flex h-10 items-center rounded-lg pl-3">
        <div className="flex flex-shrink-0 items-center gap-1">
          <FireTitle
            title="PRESALE TRENDING"
            icon={firePurple}
            titleClassName="!bg-[linear-gradient(90deg,#C100A9_0%,#BC00FF_52.38%,#C40075_99.93%)] hidden lg:block"
            baseClassName="lg:min-w-[170px]"
          />
          {/* <Button className="bg-r-orange h-[27px] min-w-[111px] rounded-lg px-[6px] text-[14px] font-medium text-[#F79421] lg:min-w-[89px]">
    ALL CHAIN
  </Button> */}
        </div>

        <div ref={loopRef} className="loop-section ml-3 lg:ml-6">
          <div
            className={cn('loop-div', { 'loop-animation': isOverflow })}
            id="trending_loop"
            style={{
              // @ts-ignore
              '--timeInSecond': `move-right-to-left linear ${showPresaleTrending.length}s infinite`,
            }}
          >
            <div className="loop">
              {Array.from({ length: isOverflow ? 2 : 1 }, () => (
                <>
                  {showPresaleTrending?.map((item, index) => {
                    const dex = dexes?.find((dexItem) => dexItem.factory === item.factory);
                    return (
                      <LinkToTradePage
                        className="flex items-center gap-1 cursor-pointer mr-3 lg:mr-6"
                        key={item._id}
                        baseToken={item.baseToken}
                        chainId={item.chainId}
                        pair={item.address}
                      >
                        <span className="text-orange-3 text-13 font-semibold ">#{index + 1}</span>
                        <TokenIcon
                          iconSrc={getBlockChainLogo(Number(item.chainId), item.baseToken, item.baseTokenLogo)}
                          subIconSrc={getChainIcon(Number(item.chainId))}
                          fallbackSrc={dex?.avatar ?? StaticsImages.unknowLogo}
                          iconClassName="w-[21px] h-[21px] bg-[#cccccc3d] rounded-full"
                          baseClassName="w-[21px] h-[21px]"
                        />
                        <span className="text-14 font-medium text-gray-1500">{item.baseTokenSymbol}</span>
                      </LinkToTradePage>
                    );
                  })}
                </>
              ))}
            </div>
          </div>
        </div>

        <div className="bg-g-orange h-full rounded-r-lg">
          <button className="link-btn" onClick={() => navigate(`#${OnChainTabEnum.TOP_PRESALES}`)}>
            <ArrowRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default PresaleTrending;
