import React, { FC, useMemo } from 'react';
import { Text, TextProps } from '@chakra-ui/react';
import { formatNumeral } from 'utils/format';
import numeralType from 'configs/numeral';
import { themeConstants } from 'configs/theme';

function _getPercentWithLimit(_percent?: number) {
  const MAX_PERCENT = 999_999;
  _percent = _percent || 0;
  const isTooLarge = _percent > MAX_PERCENT;
  const percent = isTooLarge ? MAX_PERCENT : _percent;
  const prefix = isTooLarge ? '>' : '';

  return { percent, prefix };
}

// Helper function for converting numbers to K, M, B notation
function formatLargeNumber(value: number) {
  if (value >= 1e9) {
    return (value / 1e9).toFixed(2) + 'B%'; // Convert billions
  } else if (value >= 1e6) {
    return (value / 1e6).toFixed(2) + 'M%'; // Convert millions
  } else if (value >= 1e3) {
    return (value / 1e3).toFixed(2) + 'K%'; // Convert thousands
  } else {
    return formatNumeral(Number(value) / 100, numeralType.PRICE_CHANGE, undefined, '%'); // No conversion
  }
}

interface IProps extends TextProps {
  percent?: number;
  prefix?: string;
}
const FormatPercent: FC<IProps> = (props) => {
  const { color, content, prefix } = useMemo(() => {
    const { percent, prefix } = _getPercentWithLimit(props.percent);

    const defaultParams = {
      color: !percent ? 'white.400' : percent >= 0 ? themeConstants.green : themeConstants.red,
      content:
        // percent > 1000
        `${formatLargeNumber(Number(percent))}`,
      // : formatNumeral(Number(percent) / 100, numeralType.PRICE_CHANGE, undefined, '%'),
      // content:  `${formatLargeNumber(Number(percent))}%`,
      prefix: `${prefix}${props.prefix || ''}`,
    };
    if (percent === undefined) defaultParams.content = 'N/A';

    return defaultParams;
  }, [props.percent, props.prefix]);

  return (
    <Text color={color} {...props}>
      {prefix || ''}
      {content}
    </Text>
  );
};

export default FormatPercent;
