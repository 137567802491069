import { allIcon, radioSelectedIcon } from 'assets/icons';
import { ChevronRightCircleIcon } from 'shared/components/Icons/Arrow';
import TokenIcon from 'shared/components/TokenIcon';
import { Button, cn, useDisclosure } from '@nextui-org/react';
import { useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { MultiSelect, Option, useMultiSelect } from 'chakra-multiselect';
import useOnClickOutside from 'utils/hooks/useOnClickOutsideWithArray';

interface Props {
  options: (Option & { icon?: string; disabled?: boolean })[];
  onChange: (value: any) => void;
  [key: string]: any;
  placeholder?: string;
  searchable?: boolean;
  disabled?: boolean;
  buttonClass?: string;
  valueClass?: string;
}

const SelectField = ({
  options,
  onChange,
  placeholder,
  searchable = true,
  disabled,
  buttonClass,
  valueClass,
  ...rest
}: Props) => {
  const { isOpen, onOpenChange, onClose, onOpen } = useDisclosure();
  const [selectedOption, setSelectOption] = useState<string | null>(null);
  const [search, setSearch] = useState('');
  const ref = useRef<any>();

  useOnClickOutside([ref], () => {
    onClose();
  });

  useEffect(() => {
    setSelectOption(rest?.value);
  }, [rest?.value]);

  const handleSelect = (option: any) => {
    if (option.disabled) return;

    onChange(option.value);
    onClose();
  };

  const findSelect = options.find((item) => item.value === selectedOption);

  const searchOptions = options.filter((option) =>
    option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()),
  );

  return (
    <div className="relative  w-full" ref={ref}>
      {isOpen && searchable ? (
        <div
          className={twMerge(
            'flex h-[28px] cursor-pointer items-center justify-between gap-1 rounded-lg border border-[#b0b0b0] bg-black px-3 lg:min-w-[100px] w-full',
            isOpen && 'rounded-b-none',
            // selectedOption && 'bg-r-orange-3',
          )}
          onClick={() => {
            if (disabled) return;
            onOpen();
          }}
        >
          <input
            type="text"
            className="w-full outline-none bg-black text-sm placeholder:text-[#323346]"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
            autoFocus
          />

          <div className={twMerge('rotate-90 duration-100 transition-all', isOpen && '-rotate-90')}>
            <ChevronRightCircleIcon color="#323346" />
          </div>
        </div>
      ) : (
        <div
          className={twMerge(
            'flex h-[28px] cursor-pointer items-center justify-between gap-1 rounded-lg border  bg-black px-3 lg:min-w-[100px] w-full',
            disabled ? 'border-[#454545]' : 'border-[#b0b0b0]',
            buttonClass,
            isOpen && 'rounded-b-none border-[#b0b0b0]',
            // selectedOption && 'bg-r-orange-3',
          )}
          onClick={() => {
            if (disabled) return;

            onOpenChange();
          }}
        >
          {findSelect || (findSelect && findSelect > 0) ? (
            <div className={twMerge('flex items-center gap-1 text-13', disabled ? 'text-[#454545]' : '', valueClass)}>
              {findSelect.label}
            </div>
          ) : (
            <></>
          )}
          {!findSelect && <span className="text-13 text-[#323346]">{placeholder}</span>}

          <div className={twMerge('rotate-90 duration-100 transition-all', isOpen && '-rotate-90')}>
            <ChevronRightCircleIcon color="#323346" />
          </div>
        </div>
      )}

      {isOpen && (
        <ul className="absolute top-[100%] w-full  space-y-1 rounded-b-lg border border-t-[0px] border-[#b0b0b0] bg-black px-2 py-[10px] z-10 max-h-[200px] overflow-y-auto">
          {searchOptions.map((option, index) => {
            const isSelected = findSelect?.value === option.value;
            return (
              <li
                key={index}
                className={twMerge(
                  'hover:bg-g-150 flex cursor-pointer items-center justify-between gap-[6px] rounded-[4px] px-1 py-[2px] text-white',
                  isSelected && 'bg-g-150',
                  option.disabled && 'bg-[#232323] hover:bg-[#232323] cursor-default opacity-90 text-[#bdbdbd]',
                )}
                onClick={() => handleSelect(option)}
              >
                <div className="flex items-center gap-[6px]">
                  {option.icon}
                  <span className="text-14 font-medium">{option.label}</span>
                </div>

                {isSelected ? <img src={radioSelectedIcon} /> : <></>}
              </li>
            );
          })}

          {searchOptions.length === 0 && <div className="text-sm flex justify-center opacity-85">Empty</div>}
        </ul>
      )}
    </div>
  );
};

export default SelectField;
