import { Tooltip } from '@nextui-org/react';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { Badge } from 'types/Trending';

export const SECURITY_BADGES: any = {
  AMA: {
    name: 'ama',
    symbol: 'M',
    color: 'text-[#8551A7]',
    bgColor: 'bg-[#432953]',
  },
  SCAM: {
    name: 'ama',
    symbol: 'M',
    color: 'text-[#8551A7]',
    bgColor: 'bg-[#432953]',
  },
  DOXXED: {
    name: 'doxx',
    symbol: 'D',
    color: 'text-[#EDA803]',
    bgColor: 'bg-[#775403]',
  },
  SAFU: {
    name: 'safu',
    symbol: 'S',
    color: 'text-[#FFB2BB]',
    bgColor: 'bg-[#804347]',
  },
  KYC: {
    name: 'kyc',
    symbol: 'K',
    color: 'text-[#17C467]',
    bgColor: 'bg-[#0B6234]',
  },
  AUDIT: {
    name: 'audit',
    symbol: 'A',
    color: 'text-[#00B8D8]',
    bgColor: 'bg-[#015C6C]',
  },
};

interface Props {
  badge: Badge;
  badgeClassName?: string;
  view?: 'name' | 'symbol';
}

const SecurityBadge: React.FC<Props> = ({ badge, badgeClassName, view = 'symbol' }) => {
  const badgeKey = badge?.type?.toUpperCase();
  console.log('badgeKey:::', badgeKey);
  const securityBadge = SECURITY_BADGES[badgeKey];
  return (
    <Tooltip
      classNames={{
        content: 'border border-gray-800 bg-black text-gray-500 py-1 px-2 text-[13px] font-medium',
      }}
      content={securityBadge?.name}
    >
      {securityBadge ? (
        <div>
          {badge.url ? (
            <a href={badge.url} target="__blank">
              <div
                className={twMerge(
                  'flex h-5 w-3 items-center justify-center rounded-[4px] text-[10px] font-medium cursor-pointer',
                  `${securityBadge.color} ${securityBadge.bgColor}`,
                  !badge.url && 'text-[#131722] bg-[#0D0D14]',
                  badgeClassName,
                )}
              >
                {securityBadge?.[view]}
              </div>
            </a>
          ) : (
            <div
              className={twMerge(
                'flex h-5 w-3 items-center justify-center rounded-[4px] text-[10px] cursor-default font-bold',
                `${securityBadge.color} ${securityBadge.bgColor}`,
                !badge.url && 'text-[#171b24] bg-[#0D0D14]',
                badgeClassName,
              )}
            >
              {securityBadge?.[view]}
            </div>
          )}
        </div>
      ) : (
        <div>pl</div>
      )}
    </Tooltip>
  );
};

export default SecurityBadge;
