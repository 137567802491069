import { checkedSuccessIcon, infoCircleIcon, questionMarkCircle } from 'assets/icons';
import { Tooltip } from '@nextui-org/react';
import React, { useMemo, useState } from 'react';
import SocialLinkItem from '../SearchModal/SocialLinkItem';
import SocialInfoModal, { OnlySocialModel } from 'modules/trade/components/LeftContent/SocialTokenInfo/SocialInfoModal';
import { ThreeDotsIcon } from '../Icons/Trade';
import constants from 'configs/constants';

interface Props {
  isUpdated: boolean;
  socials?: Record<string, string>;
}

const SocialStatus: React.FC<Props> = ({ isUpdated, socials }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openModel, setOpenModel] = useState(false);

  const allSocials = useMemo(
    () =>
      constants.SocialType.map((st) => ({
        ...st,
        // @ts-ignore
        url: socials?.[st.value as keyof SocialLinks]?.toString() ?? '',
      })),
    [socials],
  );

  return (
    <div
      className="relative"
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      <Tooltip
        classNames={{
          content: 'border border-gray-800 bg-black text-gray-500 py-1 px-3 text-[13px] font-medium text-center',
        }}
        isOpen={isOpen}
        delay={0}
        content={
          isUpdated ? (
            <div className="py-1">
              <div className="mb-2">Updated Social Info</div>
              <div className="flex">
                <SocialLinkItem type="website" url={socials?.['website']} withHover />
                <SocialLinkItem type="telegram" url={socials?.['telegram']} withHover />
                <SocialLinkItem type="twitter" url={socials?.['twitter']} withHover />
                <SocialLinkItem type="reddit" url={socials?.['reddit']} withHover />
                <SocialLinkItem type="youtube" url={socials?.['youtube']} withHover />
                <SocialLinkItem type="discord" url={socials?.['discord']} withHover />
                <button
                  onClick={() => {
                    setOpenModel(true);
                    setIsOpen(false);
                  }}
                  aria-label="social info modal"
                  className='hover:bg-hv-gradient p-2 rounded-lg'
                >
                  <ThreeDotsIcon />
                </button>
              </div>
            </div>
          ) : (
            <div>
              <div>NOT Updated</div>
              <div>Social And Info</div>
            </div>
          )
        }
      >
        <div
          onMouseEnter={() => {
            setIsOpen(true);
          }}
        >
          <div className="absolute w-full h-[20px] -top-3"></div>
          <img src={isUpdated ? checkedSuccessIcon : questionMarkCircle} />
        </div>
      </Tooltip>
      <OnlySocialModel isOpen={openModel} onClose={() => setOpenModel(false)} socials={allSocials} />
    </div>
    // <img src={isUpdated ? checkedSuccessIcon : questionMarkCircle} />
  );
};

export default SocialStatus;
