import { Link, useColorModeValue } from '@chakra-ui/react';
import path from 'configs/path';
import { Link as ReactLink } from 'react-router-dom';
import { buildHref } from 'utils/path';
import LogoFull from 'assets/logo/LogoFull';

function Logo() {
  const color = useColorModeValue('black', 'white');
  return (
    <Link
      as={ReactLink}
      to={buildHref(path.HOME)}
      textDecoration="none"
      _hover={{ textDecoration: 'none' }}
      _focus={{ boxShadow: 'none' }}
    >
      <img src="/logo.png" alt="" />
    </Link>
  );
}

export default Logo;
