import { adBubbleIcon, gasStationIcon, heartBubbleIcon, searchIcon } from 'assets/icons';
import Box from 'shared/components/Box';
import InputSearch from './InputSearch';
import { Link, useNavigate } from 'react-router-dom';
import ConnectWallet from './connectWallet';
import SetupLayout from './setupLayout';

const HeaderBoard = () => {
  const navigate = useNavigate();

  return (
    <div className="flex justify-between max-xl:gap-2">
      <Box boxClassName="hidden grid-cols-3 items-center xl:gap-x-2 rounded-[10px] bg-[#0D0D14] py-0 md:grid max-xl:p-1 max-xl:pr-0 h-9">
        <div className="flex items-center gap-[6px]">
          <span className="text-[13px] text-[#333333]">BTC</span>
          <span className="text-[13px] text-noti-green">65,464$</span>
        </div>
        <div className="flex items-center gap-[6px]">
          <span className="text-[13px] text-[#333333]">ETH</span>
          <span className="text-[13px] text-noti-green">3,658$</span>
        </div>
        <div className="flex items-center gap-[6px]">
          <img src={gasStationIcon} alt="" />
          <span className="text-[13px] text-noti-green">43</span>
          <span className="text-[13px] text-[#333333]">Gwei</span>
        </div>
        <div className="hidden items-center gap-[6px] xl:flex">
          <span className="text-[13px] text-[#333333]">SOL</span>
          <span className="text-[13px] text-noti-green">165$</span>
        </div>
        <div className="hidden items-center gap-[6px] xl:flex">
          <span className="text-[13px] text-[#333333]">BNB</span>
          <span className="text-[13px] text-noti-green">665,5$</span>
        </div>
        <div className="hidden items-center gap-[6px] xl:flex">
          <span className="text-[13px] text-[#333333]">TON</span>
          <span className="text-[13px] text-noti-green">7,68$</span>
        </div>
      </Box>

      <InputSearch />

      <div className="flex gap-2">
        <Box boxClassName="p-[6px] flex items-center gap-[6px] flex-shrink-0 cursor-pointer group/item">
          <img src={heartBubbleIcon} />
          <span className="hidden text-[13px] text-gray-700 group-hover/item:text-gray-600 xl:inline">Feedback</span>
        </Box>
        <Link to="/ads" className='flex-shrink-0 '>
          <Box
            boxClassName="p-[6px] h-full flex items-center gap-[6px] flex-shrink-0 cursor-pointer group/item"
          >
            <img src={adBubbleIcon} />
            <span className="hidden text-[13px] text-gray-700 group-hover/item:text-gray-600 xl:inline">Advertise</span>
          </Box>
        </Link>
        <ConnectWallet />
        <SetupLayout />
      </div>
    </div>
  );
};

export default HeaderBoard;
