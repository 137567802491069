import { ChevronRightCircleIcon } from 'shared/components/Icons/Arrow';
import TokenIcon from 'shared/components/TokenIcon';
import { Tooltip } from '@nextui-org/react';
import { twMerge } from 'tailwind-merge';

interface IProps {
  devOf?: string;
  noArrow?: boolean;
}

const DevOf = ({ devOf, noArrow }: IProps) => {
  return (
    // <Tooltip
    //   classNames={{
    //     content: 'bg-black p-[6px] rounded-lg border border-[#D7D6DF]',
    //   }}
    //   content={
    //     <div className="flex flex-wrap items-center gap-[6px]">
    //       <div className="flex w-fit flex-1 items-center gap-1 rounded-[6px] bg-[#131722] p-1">
    //         <TokenIcon
    //           iconSrc="https://s2.coinmarketcap.com/static/img/coins/64x64/29870.png"
    //           iconClassName="w-4 h-4"
    //         />
    //         <span className="text-12 font-medium text-[#D7D6DF]">100X</span>
    //       </div>
    //       <div
    //         className={twMerge(
    //           'flex w-fit flex-1 items-center gap-1 rounded-[6px] bg-[#131722] p-1',
    //           true && 'bg-g-150',
    //         )}
    //       >
    //         <TokenIcon
    //           iconSrc="https://s2.coinmarketcap.com/static/img/coins/64x64/29870.png"
    //           iconClassName="w-4 h-4"
    //         />
    //         <span className="text-12 font-medium text-[#D7D6DF]">100X</span>
    //       </div>
    //     </div>
    //   }
    // >
    <div className="flex items-center">
      <div className="flex h-5 min-w-[50px] items-center justify-center rounded-[4px] bg-r-orange-4 p-1 text-[13px] font-medium text-[#F7931E] hover:bg-g-150">
        {devOf ?? 10}x
      </div>
      {!noArrow && (
        <div className='ml-1'>
          <svg width="6" height="9" viewBox="0 0 6 9" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.338535 2.00004C1.43854 2.90004 2.43854 3.80004 3.53854 4.70004C3.63854 4.80004 3.83854 4.90004 3.93854 5.10004C4.13854 5.30004 4.43853 5.40004 4.73853 5.40004C5.03854 5.40004 5.33854 5.30004 5.53854 5.10004C5.93854 4.70004 6.03854 3.90004 5.53854 3.50004C4.53854 2.60004 3.43854 1.70004 2.43854 0.800037C2.23854 0.700037 2.13854 0.600037 1.93854 0.400037C1.73854 0.200037 1.43854 0.100037 1.13854 0.100037C0.838535 0.100037 0.538535 0.200037 0.338535 0.400037C-0.0614647 0.800037 -0.161465 1.60004 0.338535 2.00004Z" fill="#F7931E" />
            <path d="M1.93857 8.40002C3.03857 7.50002 4.03857 6.60002 5.13857 5.70002C5.33857 5.60002 5.43857 5.40002 5.63857 5.30002C5.73857 5.10002 5.93857 4.80002 5.93857 4.50002C5.93857 4.20002 5.83857 3.90002 5.63857 3.70002C5.13857 3.30002 4.53857 3.30002 4.03857 3.70002C2.93857 4.60002 1.83857 5.50002 0.838574 6.40002C0.638574 6.50002 0.538574 6.60002 0.338574 6.80002C0.138574 6.90002 0.0385742 7.30002 0.0385742 7.60002C0.0385742 7.90002 0.138574 8.20003 0.338574 8.40002C0.838574 8.80002 1.43857 8.80002 1.93857 8.40002Z" fill="#F7931E" />
          </svg>
        </div>
      )}
    </div>
    // </Tooltip>
  );
};

export default DevOf;
