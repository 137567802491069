import React from 'react';
import { twMerge } from 'tailwind-merge';

interface BoxProps {
  children: React.ReactNode;
  boxClassName?: string;
  onClick?: () => void;
}

const Box = ({ children, boxClassName, onClick }: BoxProps) => {
  return (
    <div className={twMerge('h-auto rounded-[10px] bg-[#0D0D14]', boxClassName)} onClick={onClick}>
      {children}
    </div>
  );
};

export default Box;
