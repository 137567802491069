import { Box, Divider, Flex, Icon, Image, Spacer, Text, useColorModeValue } from '@chakra-ui/react';
import { sidebarMenu } from 'configs/menus';
import { themeConstants } from 'configs/theme';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai';
import useSidebar from 'shared/hooks/useSidebar';
import Card from './Card';
import LanguageSwitcher from './LanguageSwitcher';
import ThemeSwitcher from './ThemeSwitcher';
import useTranslation from 'shared/hooks/useTranslation';
import socialLinks from 'configs/social-links';
import ExternalLink from './ExternalLink';
import { isImagePath } from 'utils/misc';
import { FC, memo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import alphaDexLogo from 'assets/images/alpha-dex-logo.png';
import alphaDexTextLogo from 'assets/images/alpha-dex-text-logo.png';
import {
  AdvertiseIcon,
  ChartColumnIcon,
  ChartMultiIcon,
  ChartPieIcon,
  DiamondIcon,
  DocsIcon,
  GuideIcon,
  LightningCircleIcon,
  StarIcon,
  ToolsIcon,
} from './Icons/Menu';
import chevronRightIcon from 'assets/icons/chevron-right.svg';
import { ContactIcon } from './Icons/Contact';
import { EarthIcon, TelegramIcon, XIcon } from './Icons/Socials';
import LinkCustom from './LinkCustom';
import { cn } from '@nextui-org/react';
type TSideBarItem = {
  name: string;
  icon: FC<{
    size?: number;
    color?: string;
    className?: string;
  }>;
  key: string;
  path?: string;
  disabled?: boolean;
};
const SIDEBAR: TSideBarItem[] = [
  {
    name: 'Alpha Board',
    icon: DiamondIcon,
    key: 'alpha-board',
    path: '/',
    disabled: false,
  },
  {
    name: 'Alpha Swap',
    icon: LightningCircleIcon,
    key: 'alpha-swap',
    disabled: true,
  },
  {
    name: 'Portfolio',
    icon: ChartPieIcon,
    key: 'portfolio',
    disabled: true,
  },
  {
    name: 'Watchlist',
    icon: StarIcon,
    key: 'watchlist',
    disabled: true,
  },
  {
    name: 'Chart',
    icon: ChartColumnIcon,
    key: 'chart',
    disabled: true,
  },
  {
    name: 'Multi Chart',
    icon: ChartMultiIcon,
    key: 'multi-chart',
    disabled: true,
  },
  {
    name: 'Advertise',
    icon: AdvertiseIcon,
    key: 'advertise',
    path: '/ads',
  },
  {
    name: 'Tools',
    icon: ToolsIcon,
    key: 'tools',
    disabled: true,
  },
  {
    name: 'Guid To Use',
    icon: GuideIcon,
    key: 'guide-to-use',
    disabled: true,
  },
  {
    name: 'Docs',
    icon: DocsIcon,
    key: 'docs',
    disabled: true,
  },
];

export const SOCIALS = [
  {
    key: 'website',
    icon: (color?: string) => <EarthIcon color={color} />,
    link: '',
  },
  {
    key: 'telegram',
    icon: (color?: string) => <TelegramIcon color={color} />,
    link: '',
  },
  {
    key: 'telegram 2',
    icon: (color?: string) => <TelegramIcon color={color} />,
    link: '',
  },
  {
    key: 'x',
    icon: (color?: string) => <XIcon color={color} />,
    link: '',
  },
];

function SidebarMenu() {
  const { collapsed, toggle } = useSidebar();
  const { pathname } = useLocation();
  const { t } = useTranslation('header');
  const bgActive = useColorModeValue('blackAlpha.200', 'blackAlpha.700');
  const bgHover = useColorModeValue('blackAlpha.50', 'blackAlpha.500');
  const [hoverItemKey, setHoverItemKey] = useState<string>('');
  const navigate = useNavigate();

  const renderHeaderMobile = () => {
    return (
      <div className="sticky top-0 z-30 flex items-center justify-between border-b border-b-[#333] bg-[#131722] px-2 py-3 md:hidden">
        <img src={alphaDexTextLogo} alt="" className="h-[34px] md:w-[182px] object-contain" />

        <div onClick={toggle}>
          <div className="p-[6px] flex items-center gap-[6px] flex-shrink-0 w-9 h-[33px] justify-center">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="16" height="2" rx="1" fill="white" />
              <rect y="5" width="16" height="2" rx="1" fill="white" />
              <rect y="10" width="16" height="2" rx="1" fill="white" />
            </svg>
          </div>
        </div>
      </div>
    );
  };
  console.log(pathname);

  return (
    <>
      {renderHeaderMobile()}
      <div
        className={twMerge(
          'fixed md:static -left-[60px] top-0 z-30 flex min-h-dvh w-[60px] flex-col justify-between bg-[#0D0D14] pb-6 pt-1 transition-all duration-200 ease-in-out md:left-0 md:max-w-[188px] max-h-full',
          !collapsed && 'w-full max-md:left-0 max-md:translate-x-0 md:w-[188px]',
        )}
      >
        <div className="space-y-3">
          <div className="max-md:mb-6 max-md:flex max-md:items-center max-md:justify-between">
            <div className="mr-1 flex cursor-pointer justify-end p-1 max-md:hidden" onClick={toggle}>
              <img
                src={chevronRightIcon}
                alt=""
                className={twMerge('transition-all duration-300 ease-linear', !collapsed && 'rotate-180')}
              />
            </div>
            {!collapsed ? (
              <Link to="/">
                <img src={alphaDexTextLogo} alt="" className="h-[34px] w-[182px] object-contain cursor-pointer" />
              </Link>
            ) : (
              <Link to="/">
                <img src={alphaDexLogo} alt="" className="mx-auto h-[34px] w-[34px] cursor-pointer" />
              </Link>
            )}
            <button
              className="mr-3 flex h-6 w-6 items-center justify-center rounded-full bg-gray-1000 text-14 text-white md:hidden"
              onClick={toggle}
            >
              X
            </button>
          </div>
          {SIDEBAR.map(({ icon: Icon, ...item }) => {
            const isActive = item.path === pathname;
            // let color = isActive ? '#FF8A02' : '#808080';
            // color = hoverItemKey === item.key ? '#FFFFFF' : color;
            return (
              <LinkCustom
                key={item.key}
                className={twMerge(
                  'group flex cursor-pointer items-center overflow-hidden px-3 transition-all duration-500 ease-linear ',
                  !item.disabled && 'hover:bg-g-30',
                  isActive && 'bg-g-25 hover:bg-g-25',
                )}
                // onMouseEnter={() => setHoverItemKey(item.key)}
                // onMouseLeave={() => setHoverItemKey('')}
                to={item.path}
                disabled={item.disabled}
              >
                <Icon
                  className={cn('flex-shrink-0  fill-[#8C8C8C]', {
                    'fill-[#FF8A02]': isActive,
                    'group-hover:fill-white': !isActive && !item.disabled,
                  })}
                />
                {/* <div className="flex-shrink-0 [>svg]:group-hover:fill-white &:has(>svg)]:border-2 fill-white">{item.icon}</div> */}
                <span
                  className={twMerge(
                    'hidden text-[16px] font-semibold text-[#808080]',
                    hoverItemKey === item.key && 'text-white',
                    isActive && 'text-[#FF8A02]',
                    !collapsed && 'block whitespace-nowrap',
                  )}
                >
                  {item.name}
                </span>
              </LinkCustom>
            );
          })}
        </div>
        <div className={twMerge('flex items-center mt-3 gap-3 px-3', collapsed && ' flex-col ')}>
          {[
            ...SOCIALS,
            {
              key: 'contact',
              icon: (color?: string) => <ContactIcon color={color} />,
              link: '',
            },
          ].map((item) => (
            <LinkCustom key={item.key} to={item.link}>
              {item.icon()}
            </LinkCustom>
          ))}
        </div>
      </div>
    </>
  );
}

export default memo(SidebarMenu);
