import client from 'configs/axios';
import ApiResponse from 'types/ApiResponse';
type SearchResponse = TSearchItem[];

export type TSearchItem = {
  _id?: string;
  address?: string;
  chainId?: string;
  basePrice?: number;
  baseToken?: string;
  baseTokenName?: string;
  baseTokenSymbol?: string;
  factory?: string;
  liquidity?: number;
  priceChange24h?: number;
  priceUsd?: number;
  quoteToken?: string;
  quoteTokenName?: string;
  quoteTokenSymbol?: string;
  txns24h?: number;
  volume24h?: number;
  baseTokenLogo?: string;
  quoteTokenLogo?: string;
  isAdsBoost?: boolean;
  isTopPresale?: boolean;
  isTrending?: boolean;
  mcap?: string;
  pairCreatedTime?: string;
  devOf?: string;
  scan?: any;
};

export async function search(params?: { keyword?: string } | undefined) {
  const data = (await client.get<ApiResponse<SearchResponse>>('pairs/search', { params })).data;
  return data;
}
