import constants from 'configs/constants';

export const getImgUrl = (sortUrl?: string, errorUrl?: string) => {
  if (!sortUrl) {
    // return `https://cdn.stamp.fyi/avatar/${errorUrl}`;
    return '/notknowicon.svg'
  }
  if (sortUrl.includes('http')) {
    return sortUrl;
  }
  return `${constants.FILE_URL}/${sortUrl}`;
};
