import { Checkbox, PopoverBody, PopoverHeader } from "@chakra-ui/react";
import { twMerge } from "tailwind-merge";

const MultiCheck = ({ tempValue = [], setTempValue }: any) => {

  const checkOptions = [
    {
      value: 'DONE',
      label: 'DONE',
      color: '#17C467',
      bar: 100,
    },
    {
      value: 'SUBMITED',
      label: 'SUBMITED',
      color: '#00B8D8',
      bar: 50,
    },
    {
      value: 'PROCESSING',
      label: 'LOADING',
      color: '#00B8D8',
      bar: 50,
    },
    {
      value: 'PAID',
      label: 'PAID',
      color: '#00B8D8',
      bar: 50,
    },
    {
      value: 'PLAN',
      label: 'APPLIED',
      color: '#EDA803',
      bar: 0,
    },
  ]

  return (
    <>
      <PopoverHeader border='0' padding={3}>
        <p className="text-[13px] font-medium leading-[19.5px] text-left">
          Status
        </p>
      </PopoverHeader>

      <PopoverBody px={3} py={0} className="space-y-2">
        {checkOptions.map(item => (
          <div className="flex items-center w-full gap-2">
            <Checkbox
              isChecked={tempValue.includes(item.value)}
              onChange={() =>
                tempValue.includes(item.value)
                  ? setTempValue(tempValue.filter((i: any) => i !== item.value))
                  : setTempValue([...tempValue, item.value])
              }
              size="lg"
              sx={{
                ".css-1oi6yiz": {
                  boxShadow: "none",
                  borderWidth: 1,
                  borderRadius: 4,
                },
                ".css-1oi6yiz:hover": {
                  borderColor: "#FF6F00",
                }
              }}
              _checked={{
                "& .chakra-checkbox__control": {
                  boxShadow: "none",
                  background: "radial-gradient(40.52% 162.07% at 50% 50%, rgba(255, 146, 0, 0.5) 0%, rgba(255, 111, 0, 0.5) 100%) !important",
                  borderColor: "#FF6F00 !important",
                }
              }}
              iconColor="#FF6F00"
            />
            <div className="flex items-center justify-between border border-[#131722] rounded-full flex-1 p-1">
              <p className={`text-[11px] leading-[14px] font-semibold text-[${item.color}]`}>
                {item.label}
              </p>
              <div className="rounded-full w-[73px] bg-[#131722] p-[3px]">
                <div className="flex items-center justify-between relative">
                  <div
                    className={twMerge(
                      'w-[9px] h-[9px] rounded-full',
                      item.bar === 0 ? 'bg-g-100 z-10' : 'bg-[#000000]'
                    )}
                  />
                  <div
                    className={twMerge(
                      'w-[9px] h-[9px] rounded-full',
                      item.bar === 50 ? 'bg-g-100 z-10' : 'bg-[#000000]'
                    )}
                  />
                  <div
                    className={twMerge(
                      'w-[9px] h-[9px] rounded-full',
                      item.bar === 100 ? 'bg-g-100 z-10' : 'bg-[#000000]'
                    )}
                  />
                  {
                    item.bar > 0
                      ? (
                        <div
                          className={`bg-g-100 absolute h-[9px] opacity-40 rounded-full ${item.bar < 100 && 'rounded-r-none'}`}
                          style={{ width: `${item.bar}%` }}
                        />
                      )
                      : <></>
                  }
                </div>
              </div>
            </div>
          </div>
        ))}
      </PopoverBody>
    </>
  )
}

export default MultiCheck;