import { format, parse } from 'date-fns';
import { FC } from 'react';
import { DiscordIcon, EarthIcon, RedditIcon, TelegramIcon, XIcon } from 'shared/components/Icons/Socials';
export function convertSocialLinks(
  socialLinks: {
    url: string | undefined | null;
    type: string | null | undefined;
    removable: boolean | undefined | null;
  }[],
): Record<string, string | Array<string>> {
  let result: Record<string, string | Array<string>> = {};
  for (const socialLink of socialLinks) {
    if (socialLink.type === 'other') {
      result.otherLinks = socialLink.url ? [...(result.otherLinks ?? []), socialLink.url] : [...result.otherLinks];
    } else {
      if (socialLink.type) {
        result[socialLink.type] = socialLink.url ?? '';
      }
    }
  }
  return result;
}

export function replaceTimeToDate(date: Date, time: number | string): number {
  // updatedDate.setUTCHours(0, 0, 0, 0);

  console.log('xxxxxx', date.getTime(), time);
  return date.getTime() + parseInt(time.toString());
  // const localTimeZoneOffset = date.getTimezoneOffset() * 60 * 1000;

  // const minutes = Math.floor(parseInt(time.toString()) / 60000);
  // const hours = Math.floor(minutes / 60);
  // const remainingMinutes = minutes % 60;

  // const formatDate = `${format(date, 'dd/MM/yyyy')} ${hours.toString().padStart(2, '0')}:${remainingMinutes
  //   .toString()
  //   .padStart(2, '0')}`;

  // const result = parse(formatDate, 'dd/MM/yyyy HH:mm', new Date());
  // console.log('cach 2: ', result.getTime() - localTimeZoneOffset);

  // return result.getTime() - localTimeZoneOffset;
}

export function reconvertSocialLinks(
  convertedLinks: Record<string, string | Array<string>>,
): {
  url: string | null;
  type: string | null;
  removable: boolean | null;
  icon?: FC<{
    size?: number;
    color?: string;
  }
  >
}[] {
  let result: {
    url: string | null; type: string | null; removable: boolean | null, icon?: FC<{
      size?: number;
      color?: string;
    }>
  }[] = [];
  if (convertedLinks) {
    for (const [type, urls] of Object.entries(convertedLinks)) {
      if (type === 'otherLinks' && Array.isArray(urls)) {
        for (const url of urls) {
          result.push({ url, type: 'other', removable: null });
        }
      } else if (typeof urls === 'string') {
        result.push({ url: urls, type, removable: null, icon: getIconSocial(type) });
      }
    }
  }
  return result;
}

export const getIconSocial = (type: string) => {
  switch (type) {
    case 'twitter':
      return XIcon;
    case 'facebook':
      return EarthIcon;
    case 'telegram':
      return TelegramIcon;
    case 'medium':
      return EarthIcon;
    case 'reddit':
      return RedditIcon;
    case 'github':
      return EarthIcon;
    case 'youtube':
      return EarthIcon;
    case 'discord':
      return DiscordIcon;
    default:
      return EarthIcon;
  }
}