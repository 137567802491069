import React, { useEffect } from 'react'

type Props = {
    key: string,
    onSuccess: () => void
}

export default function useKeyChange({ key, onSuccess }: Props) {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === key) {
                onSuccess()
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);
}