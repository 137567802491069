function convertToReadableFormat(input: string): string {
    const words = input.split('_');

    const formattedWords = words.map((word, index) => {
        if (index === 0) {
            return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
        } else {
            return word.toLowerCase();
        }
    });

    return formattedWords.join(' ');
}

export const StringUtils = {
    convertToReadableFormat
};