import {
  PopoverBody,
  PopoverHeader,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import { useState } from 'react';
import SelectField from 'shared/components/Form/SingleSelect';
import SelectFieldV2 from 'shared/components/Form/SingleSelectV2';
import NumberInput from 'shared/components/NumberInput';
import { abbreviateNumber } from 'utils/format';

const NumberSlider = ({ id, tempValue, setTempValue }: any) => {
  const optionsList: any = {
    liquidity: {
      label: 'Liquidity ($)',
      min: 0,
      max: 100000000,
    },
    txns24h: {
      label: 'Transactions',
      min: 0,
      max: 500000,
    },
    volume: {
      label: 'Change (%)',
      min: 0,
      max: 500000,
    },
    presaleInfo_softCap: {
      label: 'Softcap',
      min: 0,
      max: 500,
    },
    presaleInfo_hardCap: {
      label: 'Hardcap',
      min: 0,
      max: 500,
    },
    presaleInfo_maxBuy: {
      label: 'Max buy',
      min: 0,
      max: 500,
    },
    presaleInfo_contributors: {
      label: 'Contributed',
      min: 0,
      max: 500,
    },
    holders: {
      label: 'Holder',
      min: 0,
      max: 1000,
    },
    mcap: {
      label: 'Marketcap (%)',
      min: 0,
      max: 500000,
    },
    makers: {
      label: 'Makers',
      min: 0,
      max: 1000,
    },
    txns: {
      label: 'Transactions',
      min: 0,
      max: 500000,
    },
    pairCreatedTime: {
      label: 'Age (hours)',
      min: 0,
      max: 720,
    },
  };

  const opt = optionsList[id] || {};

  return (
    <>
      <PopoverHeader border="0" padding={3}>
        <p className="text-[13px] font-medium leading-[19.5px] text-left text-white">{opt.label}</p>
      </PopoverHeader>

      <PopoverBody px={3} py={0}>
        {id === 'pairCreatedTime' ? (
          <AgeFilterComponent opt={opt} id={id} tempValue={tempValue} setTempValue={setTempValue} />
        ) : (
          <>
            <div className="flex gap-3">
              <div className="relative flex-1 h-fit leading-none">
                <NumberInput
                  value={tempValue?.[0] || ''}
                  placeholder="Min"
                  className="!border-transparent !bg-[#131722] leading-none !px-1 !h-5 !text-[13px] font-medium  focus:!shadow-[0_0_0_1px_#FF9200]"
                  min={opt.min}
                  max={opt.max}
                  onChange={(e) => setTempValue([Number(e.target.value), tempValue?.[1]])}
                />
                <p className="absolute text-13 leading-none text-white font-medium right-1 top-1/2 -translate-y-1/2">
                  {abbreviateNumber(opt.min)}
                </p>
              </div>

              <div className="flex-1 h-fit relative leading-none">
                <NumberInput
                  value={tempValue?.[1] || ''}
                  placeholder="Max"
                  className="!border-transparent !bg-[#131722] leading-none !px-1 !h-5 !text-[13px] font-medium  focus:!shadow-[0_0_0_1px_#FF9200]"
                  min={opt.min}
                  max={opt.max}
                  onChange={(e) => setTempValue([tempValue?.[0], Number(e.target.value)])}
                />
                <p className="absolute text-13 text-white font-medium right-1 top-1/2 -translate-y-1/2">
                  {abbreviateNumber(opt.max)}
                </p>
              </div>
            </div>

            <div className="p-1.5 pb-2">
              <RangeSlider
                aria-label={['min', 'max']}
                min={opt.min}
                max={opt.max}
                value={[tempValue?.[0] ?? 0, tempValue?.[1] ?? 0]}
                onChange={setTempValue}
              >
                {Array.from({ length: 6 }, (_, idx) => (
                  <RangeSliderMark
                    key={idx}
                    value={(opt.max / 5) * idx}
                    mt="-3px"
                    className={`z-10 relative ${idx === 5 && 'right-[0%] !left-[unset]'}`}
                  >
                    <div className="bg-[#D7D6DF] h-1.5 w-1.5 rounded-full" />
                    <p className="text-[#323346] !text-[13px] font-medium leading-[18px] mt-1 absolute left-[50%] translate-x-[-50%]">
                      {abbreviateNumber((opt.max / 5) * idx)}
                    </p>
                  </RangeSliderMark>
                ))}

                <RangeSliderTrack
                  bg="radial-gradient(166.45% 55.5% at 50% 50%, rgba(255, 111, 0, 0.50) 0%, rgba(255, 146, 0, 0.50)100%)"
                  h={2}
                  borderRadius={999}
                >
                  <RangeSliderFilledTrack bg="radial-gradient(40.52% 162.07% at 50% 50%, #FF9200 0%, #FF6F00 100%)" />
                </RangeSliderTrack>

                <RangeSliderThumb index={0} className="p-0.5 !bg-g-100 !shadow-none !z-20">
                  <div className="bg-[#D7D6DF] h-full w-full rounded-full" />
                </RangeSliderThumb>
                <RangeSliderThumb index={1} className="p-0.5 !bg-g-100 !shadow-none !z-20">
                  <div className="bg-[#D7D6DF] h-full w-full rounded-full" />
                </RangeSliderThumb>
              </RangeSlider>
            </div>
          </>
        )}
      </PopoverBody>
    </>
  );
};

export default NumberSlider;

const AgeFilterComponent = ({ id, tempValue, setTempValue, opt }: any) => {
  const [timeSelected, setTimeSelected] = useState('hour');
  const timeOptions = [
    { value: 'minute', label: "Minute", },
    { value: 'hour', label: "Hour", },
    { value: 'day', label: "Day", },
    { value: 'month', label: "Month", },
    { value: 'year', label: "Year", },
  ];
  const timeSlider = {
    options: [
      { label: '0', value: 0 },
      { label: '5d', value: 120 },
      { label: '10d', value: 240 },
      { label: '20d', value: 480 },
      { label: '30d', value: 720 },
    ],
  };

  return (
    <>
      <div className="space-y-3">
        <div className="flex gap-2">
          <div className="relative flex-1 leading-none">
            <NumberInput
              value={tempValue?.[0] || ''}
              placeholder="Min"
              className="!border-transparent !bg-[#131722] !px-1 !h-5 !text-[13px] font-medium leading-[19.5px] focus:!shadow-[0_0_0_1px_#FF9200]"
              min={opt.min}
              max={opt.max}
              onChange={(e) => setTempValue([Number(e.target.value), tempValue?.[1]])}
            />
            <p className="absolute text-13 text-white font-medium right-1 top-1/2 -translate-y-1/2">{opt.min}</p>
          </div>
          <div className="relative z-[51]">
            <SelectField
              onChange={(value) => {
                setTimeSelected(value);
              }}
              value={timeSelected}
              buttonClass="bg-[#131722] lg:min-w-[90px] border-transparent rounded-[6px] h-5 px-1 "
              valueClass="font-medium"
              options={timeOptions}
              searchable={false}
            />
          </div>
        </div>

        <div className="flex gap-2">
          <div className="relative flex-1 leading-none">
            <NumberInput
              value={tempValue?.[1] || ''}
              placeholder="Max"
              className="!border-transparent !bg-[#131722] !px-1 !h-5 !text-[13px] font-medium leading-[19.5px] focus:!shadow-[0_0_0_1px_#FF9200]"
              min={opt.min}
              max={opt.max}
              onChange={(e) => setTempValue([tempValue?.[0], Number(e.target.value)])}
            />
            <p className="absolute text-13 text-white font-medium right-1 top-1/2 -translate-y-1/2">{opt.max}</p>
          </div>

          <div className="relative z-50">
            <SelectField
              onChange={(value) => {
                setTimeSelected(value);
              }}
              value={timeSelected}
              buttonClass="bg-[#131722] lg:min-w-[90px] border-transparent rounded-[6px] h-5 px-1 "
              valueClass="font-medium"
              options={timeOptions}
              searchable={false}
            />
          </div>
        </div>
      </div>

      <div className="p-1.5 pb-2">
        <RangeSlider
          aria-label={['min', 'max']}
          min={opt.min}
          max={opt.max}
          value={[
            (timeSelected === 'hour' ? tempValue?.[0] : Number(tempValue?.[0] ?? 0) / 60) ?? 0,
            (timeSelected === 'hour' ? tempValue?.[1] : Number(tempValue?.[1] ?? 0) / 60) ?? 0,
          ]}
          onChange={(values) => {
            let minValue = values[0];
            let maxValue = values[1];
            if (timeSelected !== 'hour') {
              minValue *= 60;
            }
            if (timeSelected !== 'hour') {
              maxValue = maxValue * 60;
            }
            debugger;
            setTempValue([minValue, maxValue]);
          }}
        >
          {timeSlider.options.map((op, idx) => (
            <RangeSliderMark
              key={idx}
              value={op.value}
              mt="-3px"
              className={`z-10 relative ${idx === 5 && 'right-[0%] !left-[unset]'}`}
            >
              <div className="bg-[#D7D6DF] h-1.5 w-1.5 rounded-full" />
              <p className="text-[#323346] text-[13px] font-medium leading-[18px] mt-1 absolute left-[50%] translate-x-[-50%]">
                {op.label}
              </p>
            </RangeSliderMark>
          ))}

          <RangeSliderTrack
            bg="radial-gradient(166.45% 55.5% at 50% 50%, rgba(255, 111, 0, 0.50) 0%, rgba(255, 146, 0, 0.50)100%)"
            h={2}
            borderRadius={999}
          >
            <RangeSliderFilledTrack bg="radial-gradient(40.52% 162.07% at 50% 50%, #FF9200 0%, #FF6F00 100%)" />
          </RangeSliderTrack>

          <RangeSliderThumb index={0} className="p-0.5 !bg-g-100 !shadow-none !z-20">
            <div className="bg-[#D7D6DF] h-full w-full rounded-full" />
          </RangeSliderThumb>
          <RangeSliderThumb index={1} className="p-0.5 !bg-g-100 !shadow-none !z-20">
            <div className="bg-[#D7D6DF] h-full w-full rounded-full" />
          </RangeSliderThumb>
        </RangeSlider>
      </div>
    </>
  );
};
