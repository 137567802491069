/* eslint-disable react/no-unescaped-entities */
import {
  closeIcon,
  closeSquare,
  firePurple,
  fireRed,
  rocketIcon,
  search2Icon,
  searchIcon,
  Seed,
  slashIcon,
  clearIcon,
} from 'assets/icons';
import Box from 'shared/components/Box';
import { ChartColumnSquareIcon } from 'shared/components/Icons/Menu';
import { EarthIcon, TelegramIcon, XIcon } from 'shared/components/Icons/Socials';
import TokenIcon from 'shared/components/TokenIcon';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalOverlay,
  ModalContent,
  Spinner,
  useToast,
} from '@chakra-ui/react';
import { Input, cn } from '@nextui-org/react';
import { useQuery } from '@tanstack/react-query';
import queryKey from 'configs/queryKey';
import { search, TSearchItem } from 'apis/search';
import constants from 'configs/constants';
import { ChangeEvent, EventHandler, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'react-use';
import Loading from '../Loading';
import { getBoostAds } from 'apis/boost-ads';
import useTopResearchs from 'modules/home/hooks/useTopResearchs';
import { reconvertSocialLinks } from 'utils/convert';
import { useAppDispatch, useAppSelector } from 'shared/hooks/reduxHook';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import { buildHref } from 'utils/path';
import path from 'configs/path';
import { getChainIcon, getChainNameFromChainId } from 'utils/chain';
import { getBlockChainLogo } from 'utils/misc';
import { getImgUrl } from 'utils/file';
import LinkToTradePage from '../LinkToTradePage';
import FormatNumberDecimal from '../FormatNumberDecimal';
import FormatPercent from '../FormatPercent';
import { themeConstants } from 'configs/theme';
import { addSearchItem, selectPreviousSearch } from 'shared/state/redux/searchSlice';
import ScanStatus from '../ScanStatus';
import DevOf from '../DevOf';
import { formatNumeral, subAddress } from 'utils/format';
import { CopyOutlineIcon } from '../Icons/Filter';
import { useTranslation } from 'react-i18next';
import { StarOutlineIcon } from '../Icons/Star';
import SocialLinkItem from './SocialLinkItem';
import { Link } from 'react-router-dom';
import { formatDistanceToNowStrict } from 'date-fns';
import useKeyChange from 'shared/hooks/useKeyChange';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SearchModal = ({ isOpen, onClose }: Props) => {
  const [keyword, setKeyword] = useState<string | undefined>();
  const [debouncedKeyword, setDebouncedKeyword] = useState<string | undefined>();
  const inputRef = useRef<HTMLInputElement>(null);

  const previousSearch = useAppSelector(
    (state: { search: { previousSearch: TSearchItem[] } }) => state.search.previousSearch,
  );

  useKeyChange({
    key: '/',
    onSuccess: () => {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    },
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 0);
    } else {
      setKeyword('');
    }
  }, [isOpen]);

  useDebounce(
    () => {
      setDebouncedKeyword(keyword);
    },
    500,
    [keyword],
  );
  const { data: searchData, isLoading } = useQuery(
    [queryKey.SEARCH, debouncedKeyword],
    () => search({ keyword: debouncedKeyword }),
    {
      enabled: !!debouncedKeyword,
    },
  );
  const { data: boostAds, isLoading: isLoadingBoostAds } = useQuery([queryKey.BOOSTS], () => getBoostAds(), {
    refetchInterval: constants?.REFRESH_INTERVAL?.[queryKey.BOOSTS] || 1000 * 10,
  });

  const { data: researchs, isLoading: isLoadingResearch } = useTopResearchs({}, true);

  const boostAdsFirst = boostAds?.[0] ?? undefined;

  const onChangeInputSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setKeyword(event.target.value);
  };

  const handleRedirect = (chainId: string, pair: string, baseToken: string) => {
    return () => {
      if (pair && baseToken) {
        dispatch(addAddress({ address: pair, baseToken: baseToken }));
      }
      const redirect_path = buildHref(
        path.TRADE,
        {
          address: baseToken,
          chain: getChainNameFromChainId(Number(chainId)) as string,
        },
        { pair },
      );
      window.location.href = redirect_path;
    };
  };

  const onClickSearchItem = (searchItem: TSearchItem) => {
    dispatch(addSearchItem(searchItem));

    onClose();
  };

  const scrollRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  // Bắt đầu kéo
  const handleMouseDown = (e: React.MouseEvent) => {
    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      setIsDragging(true);
      setStartX(e.pageX - scrollDiv.offsetLeft); // Lưu vị trí con trỏ chuột so với phần tử
      setScrollLeft(scrollDiv.scrollLeft); // Lưu vị trí scroll hiện tại
    }
  };

  // Kéo chuột để cuộn
  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDragging) return; // Chỉ kéo khi trạng thái dragging đang là true

    const scrollDiv = scrollRef.current;
    if (scrollDiv) {
      e.preventDefault(); // Ngăn chặn hành vi mặc định của chuột khi kéo
      const x = e.pageX - scrollDiv.offsetLeft;
      const walk = (x - startX) * 2; // Hệ số 2 để tăng tốc độ cuộn
      scrollDiv.scrollLeft = scrollLeft - walk; // Cập nhật vị trí cuộn
    }
  };

  // Kết thúc kéo
  const handleMouseUp = () => {
    setIsDragging(false); // Dừng kéo
  };

  const showName = (str: string) => {
    if (str.length > 15) {
      return str.substring(0, 15 - 3) + '...';
    }
    return str;
  };
  return (
    <Modal autoFocus={false} blockScrollOnMount={false} isCentered isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      {/* @ts-ignore */}
      <ModalContent bg={'#010016'} w={'full'} maxW={'1200px'}>
        {/* <ModalCloseButton>
          <img src={closeSquare} alt="" />
        </ModalCloseButton> */}
        <ModalBody className="flex flex-col gap-3 lg:gap-6 !px-3">
          <Input
            ref={inputRef}
            onChange={onChangeInputSearch}
            value={keyword}
            classNames={{
              base: 'rounded-lg border border-[#FF920080] bg-g-150 p-1 max-xl:flex-1 w-full',
              inputWrapper:
                'bg-transparent focus-visible:border-[#FF920080] focus-within:!border-black-5 focus-within:!bg-transparent p-0 rounded-lg hover:!bg-transparent shadow-none min-h-full h-full',
              input: 'text-[13px] focus-visible:border-[#FF920080] placeholder:text-white font-medium !text-white p-0',
            }}
            // className={cn({ "border !border-[#FF6F00]": isOpen })}
            placeholder="Search pair by symbol, name, contract, token"
            className=""
            startContent={
              <div className="flex h-full w-7  items-center justify-center rounded-[3px] rounded-l-[6px]  bg-[#010101] p-1 xl:w-14 xl:px-[10px]">
                <img src={search2Icon} />
              </div>
            }
            endContent={
              <div className="flex h-[24px] ">
                {keyword ? (
                  <button
                    onClick={() => {
                      setKeyword('');
                      inputRef.current?.focus();
                    }}
                    className="bg-[#131722] rounded-[4px] px-2 flex items-center gap-1 justify-center"
                  >
                    <img src={clearIcon} className="h-[13px] aspect-square" />
                    <p className="text-13 font-medium text-white">Clear</p>
                  </button>
                ) : (
                  <img src={slashIcon} className="h-full aspect-square" />
                )}
              </div>
            }
          />
          {debouncedKeyword && (
            <div className="space-y-2 max-h-[70vh] overflow-auto">
              {isLoading && debouncedKeyword && (
                <div className="text-white">
                  <Loading />
                </div>
              )}
              {searchData?.data.map((item) => (
                <SearchItem key={item._id} data={item} onClickSearchItem={() => onClickSearchItem(item)} />
              ))}
              {!searchData?.data && !isLoading && <div>No result</div>}
            </div>
          )}
          {!searchData?.data && (
            <div className="w-full">
              <div className="flex w-full gap-3 max-lg:flex-col lg:items-start lg:gap-6">
                <h5 className="text-14 font-bold text-white">HISTORY</h5>
                <div
                  className={cn('flex-1 flex items-center gap-6 overflow-hidden', { 'cursor-grabbing': isDragging })}
                  ref={scrollRef}
                  onMouseDown={handleMouseDown}
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseUp} // Dừng kéo khi rời khỏi khu vực
                  onMouseUp={handleMouseUp}
                >
                  {previousSearch?.map((search) => (
                    <LinkToTradePage
                      onClick={onClose}
                      key={search.address}
                      baseToken={search.baseToken ?? ''}
                      chainId={search.chainId ?? ''}
                      pair={search.address ?? ''}
                    >
                      <div className="flex items-center gap-1">
                        <p className="text-14 font-medium text-white whitespace-nowrap">{search.baseTokenSymbol}</p>
                        <p className="text-14 font-medium uppercase  text-[#323346] whitespace-nowrap">
                          /{search.chainId ? getChainNameFromChainId(search.chainId) : ''}
                        </p>
                      </div>
                    </LinkToTradePage>
                  ))}
                </div>
              </div>
              {boostAdsFirst && (
                <Box
                  boxClassName="bg-[#131722] flex items-center gap-3 cursor-pointer"
                  onClick={handleRedirect(boostAdsFirst.chainId!, boostAdsFirst.pair ?? '', boostAdsFirst.baseToken!)}
                >
                  <img
                    src={getImgUrl(boostAdsFirst.baseTokenLogo)}
                    className="h-12 w-12 rounded-full bg-[#323346] lg:h-[60px] lg:w-[60px]"
                  />
                  <div className="flex-1 space-y-1">
                    <div className="flex items-center gap-3">
                      <span className="text-13 font-medium text-white">{boostAdsFirst.baseTokenSymbol}</span>
                      <div className="flex items-center gap-[6px] rounded-[6px] border border-[#F68321] bg-black px-3">
                        <span className="bg-r-orange-1 bg-clip-text text-14 text-transparent">Ads Boost</span>
                        <img src={rocketIcon} />
                      </div>
                    </div>
                    <p className="text-13 font-medium text-[#58585B]">{boostAdsFirst.description}</p>
                  </div>
                  <div>
                    <img src={closeIcon} />
                  </div>
                </Box>
              )}
              <div className="mt-5 mb-3">
                {' '}
                <div className="flex max-md:flex-col md:items-center md:justify-between">
                  <h5 className="text-14 font-bold text-white mb-4">POPULAR SEARCHERS</h5>
                </div>
                {isLoadingResearch ? (
                  <div className="text-white">
                    <Loading p={10} />
                  </div>
                ) : researchs?.data && researchs?.data.length > 0 ? (
                  <div className="grid grid-cols-2 gap-y-2 lg:grid-cols-5 lg:gap-y-4 gap-x-3 lg:gap-x-6 max-h-[60vh] overflow-y-auto">
                    {researchs?.data.map((token) => {
                      return (
                        <Link
                          to={buildHref(
                            path.TRADE,
                            {
                              address: token.baseToken,
                              chain: getChainNameFromChainId(Number(token.chainId)) as string,
                            },
                            {
                              pair: token.address,
                            },
                          )}
                        >
                          <Box
                            boxClassName="p-3 flex gap-3 cursor-pointer bg-[#131722]"
                            key={token._id}
                          // onClick={handleRedirect(token.chainId, token.pair, token.baseToken)}
                          >
                            <TokenIcon
                              iconSrc={getBlockChainLogo(Number(token.chainId), token.baseToken, token.baseTokenLogo)}
                              subIconSrc={getChainIcon(Number(token.chainId))}
                              iconClassName="w-8 h-8 lg:w-[46px] lg:h-[46px]"
                              subIconClassName="w-[10px] h-[10px] lg:w-4 lg:h-4 top-0"
                              fallbackSrc={getImgUrl(undefined, token.baseToken)}
                            />
                            <div className="flex flex-1 flex-col gap-2 lg:items-end">
                              <span className="text-13 font-medium text-left text-white whitespace-nowrap line-clamp-1">
                                {showName(token.baseTokenName)}
                              </span>
                              <ul className="flex h-fit w-fit items-center gap-[9px] rounded-[5px] bg-black p-1 lg:justify-between">
                                <SocialLinkItem type="website" url={token.socialLinks['website']} />
                                <SocialLinkItem type="telegram" url={token.socialLinks['telegram']} />
                                <SocialLinkItem type="twitter" url={token.socialLinks['twitter']} />
                                <Link
                                  to={buildHref(path.TRADE, {
                                    address: token.baseToken,
                                    chain: getChainNameFromChainId(Number(token.chainId)) as string,
                                  })}
                                >
                                  <ChartColumnSquareIcon color="#D7D6DF" size={14} />
                                </Link>
                              </ul>
                            </div>
                          </Box>
                        </Link>
                      );
                    })}
                    {/* {Array.from({ length: 25 }, (_, index) => (
    <Box boxClassName="p-3 flex gap-3" key={index}>
      <TokenIcon
        iconSrc="https://s2.coinmarketcap.com/static/img/coins/64x64/29870.png"
        subIconSrc="https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png"
        iconClassName="w-8 h-8 lg:w-[46px] lg:h-[46px]"
        subIconClassName="w-[10px] h-[10px] lg:w-4 lg:h-4 top-0"
      />
      <div className="flex flex-1 flex-col gap-2 lg:items-end">
        <span className="text-13 font-medium text-white">Book Of Memes</span>
        <ul className="flex h-fit w-fit items-center gap-[9px] rounded-[5px] bg-black p-1 lg:justify-between">
          <Link>
            <EarthIcon color="#D7D6DF" size={16} />
          </Link>
          <Link>
            <TelegramIcon color="#D7D6DF" size={16} />
          </Link>
          <Link>
            <XIcon color="#D7D6DF" size={16} />
          </Link>
          <Link>
            <ChartColumnSquareIcon color="#D7D6DF" size={16} />
          </Link>
        </ul>
      </div>
    </Box>
  ))} */}
                  </div>
                ) : (
                  <p className="text-white text-center pb-4">No result</p>
                )}
              </div>
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SearchModal;

const SearchItem = ({ data = {}, onClickSearchItem }: { data: TSearchItem; onClickSearchItem: () => void }) => {
  const toast = useToast();
  const { t } = useTranslation('trade');

  // const Divider = () => <div className="h-14 w-[1px] mx-4 bg-[#323346] shrink-0" />;

  const handleCopy = (event: MouseEvent<HTMLButtonElement>, address: string | undefined) => {
    event.preventDefault();
    event.stopPropagation();
    if (!address) return;
    navigator.clipboard.writeText(address);
    toast({
      title: t('addressCopiedToClipboard'),
      status: 'success',
      duration: 5000,
      isClosable: true,
    });
  };
  const age = useMemo(() => {
    const date = data.pairCreatedTime ? new Date(Number(data.pairCreatedTime) * 1000) : 0;
    const ageString = formatDistanceToNowStrict(date);
    return ageString;
  }, [data]);
  // console.log(data)
  return (
    <div onClick={onClickSearchItem}>
      <LinkToTradePage
        className="grid grid-cols-[60px_1fr_64px_150px_140px_140px_120px_30px] items-stretch gap-3  divide-x divide-[#323346] cursor-pointer border border-[#4E506E] rounded-[10px] p-3 !pr-4 bg-[#0D0D14] min-w-max"
        key={data._id}
        baseToken={data.baseToken ?? ''}
        chainId={data.chainId ?? ''}
        pair={data.address ?? ''}
        onClick={onClickSearchItem}
      >
        {/* <TokenIcon
        iconSrc={getImgUrl(data?.baseTokenLogo, data?.baseToken)}
        subIconSrc={getChainIcon(Number(data.chainId))}
        iconClassName="w-8 h-8 rounded-full"
        subIconClassName='w-3 h-3'
      />
      <div className=''>
        <span className="text-13 font-medium text-white">
          {data?.baseTokenSymbol} / <span className='text-gray-600'>{getChainNameFromChainId(data?.chainId!)?.toUpperCase()}</span>
        </span>
        <div className='flex items-center gap-2'>
          <span className="text-13 font-medium text-white">
            <FormatNumberDecimal
              fontSize={13}
              prefix="$" value={data.priceUsd} color={'white'} />
          </span>
          <span className=" flex items-center gap-2 text-13 font-medium text-gray-400">
            24H Change:
            <FormatPercent
              color={Number(data.priceChange24h) > 0 ? themeConstants.green : themeConstants.red}
              className='!text-13'
              percent={Number(data.priceChange24h)}
            />
          </span>
          <span className=" flex items-center gap-2 text-13 font-medium text-gray-400">
            Liquidity:
            <FormatNumberDecimal
              fontSize={13}
              prefix="$" value={data.liquidity} color={'white'} />
          </span>
          <span className=" flex items-center gap-2 text-13 font-medium text-gray-400">
            Volume:
            <FormatNumberDecimal
              fontSize={13}
              prefix="$" value={data.volume24h} color={'white'} />
          </span>
        </div>
      </div> */}

        <TokenIcon
          baseClassName="shrink-0"
          iconSrc={getImgUrl(data?.baseTokenLogo, data?.baseToken)}
          // subIconSrc={getChainIcon(Number(data.chainId))}
          iconClassName="w-14 h-14 rounded-full"
          subIconClassName="w-5 h-5"
        />
        {/* grid grid-cols-[1fr_100px_100px_100px_100px_100px_50px] */}
        <div className="space-y-1 pl-3 flex flex-col justify-center">
          <div className="flex items-center gap-2">
            <p className="text-13 font-bold text-[#D7D6DF] uppercase">
              {data.baseTokenSymbol} / {data.quoteTokenSymbol}
            </p>
            {data.chainId && <img src={getChainIcon(Number(data.chainId))} className="w-[15px] h-[15px]" />}
            {data.isTrending && <img src={fireRed} alt="" className="w-[11px]" />}
            {data.isTopPresale && <img src={firePurple} alt="" className="w-[11px]" />}
            {data.isAdsBoost && <img src={rocketIcon} alt="" className="w-[15px]" />}
          </div>
          <div className="flex items-center gap-4 ">
            <p className="text-[#4E506E] text-xs font-medium">{data.baseTokenName}</p>
            <div className="flex items-center gap-1">
              <Seed />
              <p className="text-[#4E506E] text-xs font-medium">{age}</p>
            </div>
          </div>
          <div className="flex gap-4">
            <div className="flex items-center gap-1.5 group cursor-pointer">
              <span className="text-[#4E506E] text-xs font-medium group-hover:underline">
                Token: {subAddress(data?.baseToken, 5)}
              </span>
              <button onClick={(event) => handleCopy(event, data?.baseToken)} type="button">
                <CopyOutlineIcon size={13} color="#4E506E" />
              </button>
            </div>
            <div className="flex items-center gap-1.5 group cursor-pointer">
              <span className="text-[#4E506E] text-xs font-medium group-hover:underline">
                Pair: {subAddress(data?.address, 5)}
              </span>
              <button onClick={(event) => handleCopy(event, data?.address)} type="button">
                <CopyOutlineIcon size={13} color="#4E506E" />
              </button>
            </div>
          </div>
        </div>

        {/* <Divider /> */}

        <div className="space-y-3 pl-3 flex flex-col justify-center">
          <ScanStatus data={data.scan ?? ''} />
          <DevOf devOf={formatNumeral(data.devOf) ?? 10} noArrow />
        </div>

        {/* <Divider /> */}

        <div className="space-y-1 pl-3 flex flex-col justify-center">
          <p className="text-[#4E506E] text-xs font-medium text-right">Price</p>
          <div className="flex items-center gap-1.5 justify-end">
            <FormatPercent
              color={Number(data.priceChange24h) > 0 ? themeConstants.green : themeConstants.red}
              className="!text-13"
              percent={Number(data.priceChange24h)}
            />
            <FormatNumberDecimal fontSize={13} prefix="$" value={data.priceUsd} color={'white'} />
          </div>
        </div>

        {/* <Divider /> */}

        <div className="space-y-1 text-right flex flex-col justify-center">
          <p className="text-[#4E506E] text-xs font-medium ">Mcap</p>
          <FormatNumberDecimal
            fontSize={13}
            prefix="$"
            value={data.mcap}
            abbreviate
            color={'white'}
            justifyContent={'end'}
          />
        </div>

        {/* <Divider /> */}

        <div className="space-y-1 text-right flex flex-col justify-center">
          <p className="text-[#4E506E] text-xs font-medium ">24h Vol</p>
          <FormatNumberDecimal fontSize={13} prefix="$" value={data.volume24h} color={'white'} justifyContent={'end'} />
        </div>

        {/* <Divider /> */}

        <div className="space-y-1 text-right flex flex-col justify-center">
          <p className="text-[#4E506E] text-xs font-medium ">Liquidity</p>
          <FormatNumberDecimal
            fontSize={13}
            prefix="$"
            value={data.liquidity}
            color={'white'}
            abbreviate
            justifyContent={'end'}
          />
        </div>

        {/* <Divider /> */}

        <div className="shrink-0 flex items-center justify-end">
          <StarOutlineIcon />
        </div>
      </LinkToTradePage>
    </div>
  );
};
