export const getLaunchPadType = (link: string) => {
  if (link.includes('pinksale.finance')) return 'pinksale.finance';

  if (link.includes('gempad.app')) return 'gempad.app';
  if (link.includes('redkitepad.com')) return 'redkitepad.com';

  if (link.includes('www.bscs.finance')) return 'bscs.finance';

  return 'unknow';
};
