/* eslint-disable react/no-unescaped-entities */
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useDisclosure } from '@chakra-ui/react';
import { cn } from '@nextui-org/react';
import { closeSquare, coingeckoIcon } from 'assets/icons';
import { SocialOption } from 'configs/constants';
import path from 'configs/path';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  BitbucketIcon,
  CoingecgoIcon,
  CoinmaketcapIcon,
  GithubIcon,
  GitlabIcon,
  ThreeDotsIcon,
} from 'shared/components/Icons/Trade';

interface Props {
  socials: SocialOption[];
  onClick?: () => void;
}
const sourceCodes = [
  {
    value: 'github',
    url: '',
    label: 'Github',
    icon: GithubIcon,
  },
  {
    value: 'gitlab',
    url: '',
    label: 'Gitlab',
    icon: GitlabIcon,
  },
  {
    value: 'bitbucket',
    url: '',
    label: 'Bitbucket',
    icon: BitbucketIcon,
  },
  {
    value: 'whitepaper',
    url: '',
    label: 'Whitepaper',
  },
];

const otherlinks = [
  {
    value: 'coinmarketcap',
    url: '',
    label: 'Coinmarketcap',
    icon: CoinmaketcapIcon,
  },
  {
    value: 'coingecgo',
    url: '',
    label: 'Coingecgo',
    icon: CoingecgoIcon,
  },
  {
    value: 'NTF_Collection',
    url: '',
    label: 'NTF Collection',
  },
  {
    value: 'Feature_Platform',
    url: '',
    label: 'Feature Platform',
  },
  {
    value: 'Bubble_Map',
    url: '',
    label: 'Bubble Map',
  },
];
const SocialInfoModal = ({ socials, onClick }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <button
        onClick={() => {
          onOpen();
          onClick && onClick();
        }}
        aria-label="social info modal"
      >
        <ThreeDotsIcon />
      </button>
      <OnlySocialModel isOpen={isOpen} onClose={onClose} socials={socials} />
    </>
  );
};

export default SocialInfoModal;

export const OnlySocialModel = ({
  isOpen,
  onClose,
  socials,
}: {
  isOpen: boolean;
  onClose: any;
  socials: SocialOption[];
}) => {
  return (
    <Modal autoFocus={false} blockScrollOnMount={false} isCentered isOpen={isOpen} onClose={onClose} trapFocus={false}>
      <ModalOverlay backdropFilter="auto" backdropBlur="2px" />
      {/* @ts-ignore */}
      <ModalContent bg={'#0D0D14'} className="border !border-[#262626]" w={'full'} maxW={'1047px'}>
        <ModalCloseButton>
          <img src={closeSquare} alt="" />
        </ModalCloseButton>
        <ModalBody border="1px solid transparent" className="space-y-4 !p-4">
          <div className="space-y-3">
            <h5 className="text-orange-2 text-13 font-bold">Community</h5>
            <div className="grid grid-cols-4 w-full gap-4">
              {socials.map(({ icon, ...social }) => (
                <Item icon={icon} url={social.url} label={social.label} key={social.value} />
              ))}
            </div>
          </div>
          <div className="space-y-3">
            <h5 className="text-orange-2 text-13 font-bold">Source Code</h5>
            <div className="grid grid-cols-4 w-full gap-4">
              {sourceCodes.map(({ icon, ...item }) => (
                <Item icon={icon} url={item.url} label={item.label} key={item.value} />
              ))}
            </div>
          </div>
          {/* <div className="space-y-3">
            <h5 className="text-orange-2 text-13 font-bold">Other link</h5>
            <div className="grid grid-cols-4 w-full gap-4">
              {otherlinks.map(({ icon, ...link }) => (
                <Item icon={icon} url={link.url} label={link.label} key={link.value} />
              ))}
            </div>
          </div> */}
          <div>
            <p className="text-[#323346] text-13 font-medium">
              Update the missing info by{' '}
              <Text
                as={Link}
                fontSize={'13px'}
                cursor={'pointer'}
                fontWeight="semibold"
                bg="radial-gradient(40.52% 162.07% at 50% 50%, #FF9200 0%, #FF6F00 100%)"
                bgClip="text"
                textColor="transparent"
                to={`${path.ADS}?tab=social-info`}
                target="_blank"
              >
                Update social Info
              </Text>
            </p>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

const Item = ({
  url,
  label,
  icon: Icon,
}: {
  url?: string;
  icon?: FC<{
    size?: number;
    color?: string;
  }>;
  label?: string;
}) => {
  if (!url)
    return (
      <div
        className={cn(
          'bg-[#131722] !text-[#D7D6DF] text-13 font-medium flex items-center justify-center gap-[2px] py-0.5 rounded-[6px] hover:text-white border border-[#323346]',
          { 'opacity-30': !url },
        )}
      >
        {Icon && <Icon size={16} color="#D7D6DF" />}
        <span className="ml-1">{label}</span>
      </div>
    );
  return (
    <Link
      to={url?.toString() ?? ''}
      className={cn(
        'bg-[#131722] !text-[#D7D6DF] text-13 font-medium flex items-center justify-center gap-[2px] py-0.5 rounded-[6px] hover:bg-g-50 hover:text-white border border-[#323346]',
        { 'opacity-30': !url },
      )}
      target="_blank"
    >
      {Icon && <Icon size={16} color="#D7D6DF" />}
      <span className="ml-1">{label}</span>
    </Link>
  );
};
