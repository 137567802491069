import { PairLabel } from 'configs/enums';
import { IKycDetail } from './dex_type';
import Decimal from 'decimal.js';
import { Badge, SocialLinks } from './Trending';
import { PresaleInfo } from './DevArea';
import { AnyAsyncThunk } from '@reduxjs/toolkit/dist/matchers';

interface Pair {
  _id: string;
  address: string;
  chainId: string;
  basePrice: number;
  baseToken: string;
  baseTokenName: string;
  baseTokenSymbol: string;
  factory: string;
  fdv: number;
  liquidity: number;
  pairCreatedTime: number;
  priceChange: number;
  priceChange1h: number;
  priceChange24h: number;
  priceChange5m: number;
  priceChange6h: number;
  priceUsd: number;
  quoteToken: string;
  quoteTokenSymbol: string;
  txns24h: number;
  volume24h: number;
  href?: string;
  totalBuy24h: number;
  totalSell24h: number;
  tradingStartTime: number;
  baseTokenLogo: string;
  quoteTokenLogo: string;
  createdAt?: number;
  athPreSale?: number;
  athListing?: number;
  baseTokenInfo?: BaseTokenInfo | null;
  lastAction?: string;
  badges?: Badge[];
  socialLinks?: SocialLinks;
  devOf?: number;
  isTrending?: boolean;
  isTopPresale?: boolean;
  activity?: any;
  presaleInfo?: PresaleInfo;
  holders: number;
  mcap: number;
  txns: number;
  totalBuy: number;
  totalSell: number;
  volume: number;
  buyVol: number;
  sellVol: number;
  makers: number;
  buyers: number;
  sellers: number;
  isAdsBoost?: boolean;
  scan: Record<string, any>;
  baseTokenDecimal?: number;
  quoteTokenDecimal?: number;
}

interface BaseTokenInfo {
  address: string;
  symbol: string;
  chainId: string;
  description?: string;
  status: number;
  telegram?: string;
  twitter?: string;
  updateBy?: string;
  website?: string;
  owner?: string;
  discord?: string;
  validators: IKycDetail;
  github?: string;
  updatedAt?: number;
  devOf?: number;
}

export interface IPairSearch {
  _id: string;
  address: string;
  quoteTokenName: string;
  chainId: string;
  basePrice: number;
  baseToken: string;
  baseTokenName: string;
  baseTokenSymbol: string;
  factory: string;
  liquidity: number;
  priceChange24h: number;
  priceUsd: number;
  quoteToken: string;
  quoteTokenSymbol: string;
  txns24h: number;
  volume24h: number;
  baseTokenLogo: string;
  quoteTokenLogo: string;
  href?: string;
}

export interface Score {
  avg: number;
  information: number;
  transaction: number;
  holder: number;
  lpLock: number;
  kyc: number;
  maxScore?: number;
}

export interface IPairStatistics {
  volume: number;
  buyVol: number;
  sellVol: number;
  totalBuy: number;
  totalSell: number;
  txns: number;
  makers: number;
  buyers: number;
  sellers: number;
}
export interface IPairDetail {
  // baseTokenInfo: any;
  _id: string;
  address: string;
  chainId: string;
  factory: string;
  token0: string;
  decimal0: number;
  token1: string;
  decimal1: number;
  blockNumber: number;
  baseToken: string;
  baseTokenInfo: BaseTokenData;
  quoteToken: string;
  status: number;
  basePrice: number;
  liquidity: number;
  priceUsd: number;
  reserve0: string;
  labels: PairLabel[];
  reserve1: string;
  fdv: number;
  quoteTokenInfo: QuoteTokenData;
  pairCreatedTime: number;
  newInformation: NewInformation;
  baseTokenPairs: BaseTokenPair[];
  totalSupply: string;
  totalBurned: string;
  tradingStartTime: number;
  holders: number;
  baseTokenLogo: string;
  score?: Score;
  ath?: number;
  athPrice?: number;
  athPriceUsd?: number;
  isHoneypotSetByAdmin?: boolean | null;
  lpMint?: string;
  token0Symbol: string;
  token1Symbol: string;
  adsBoost?: IAdsBoost;
  addLpTime?: number;
  trendingInfo?: {
    position: number;
    _id: string;
  };
  socialAds: ISocialAds;
  statistics?: {
    priceChange1h: number;
    priceChange24h: number;
    priceChange5m: number;
    priceChange6h: number;
    totalBuy24h: number;
    totalSell24h: number;
    txns24h: number;
    volume24h: number;
    mcap: number;
    priceUsd: number;
  };
  activities?: Activity;
}

export interface Activity {
  _id: string;
  address: string;
  chainId: string;
  type: string;
  logo: string;
  description: string;
  startAt: number;
  proof: Proof;
  activityStatus: string;
  status: number;
  createdBy: string;
  createdAt: number;
  updatedAt: number;
  __v: number;
}

export interface Proof {
  link: string;
  image: string;
  video: string;
}

export interface UserProfit {
  totalBoughtValueUSDBaseToken: number;
  totalSellValueUSDBaseToken: number;
  totalBuyTxns: number;
  totalSellTxns: number;
  totalBaseTokensBought: number;
  totalBaseTokensSold: number;
  totalQouteTokenBought: number;
  totalQouteTokenSold: number;
  currentTokenBalance: number;
  externalTransferedValue: number;
  realizedPNL: number;
  unrealizedPNL: number;
  totalPNL: number;
}

export interface ISocialAds {
  _id?: string;
  address?: string;
  chainId?: string;
  __v?: number;
  badges?: Badge[];
  createdAt?: number;
  createdBy?: string;
  description?: string;
  lockedSupply?: any[];
  logo?: string;
  name?: string;
  owner?: string;
  presales?: Presale[];
  previousProjects?: TPreviousProject[];
  socialLinks?: SocialLinks;
  status?: number;
  updatedAt?: number;
  banner?: string;
  updatedBy?: string;

  adsBoost?: {
    address: string;
    chainId: string;
    factory: string;
    description: string;
    startAt: number;
    endAt: number;
    socialLinks: {
      website: string;
      twitter: string;
      telegram: string;
      tiktok: string;
    };
    baseToken: string;
    baseTokenSymbol: string;
    baseTokenName: string;
    baseTokenLogo: string;
    banner: string;
    quoteToken: string;
    quoteTokenSymbol: string;
    quoteTokenName: string;
    telegramContact: string;
    status: number;
    createdBy: string;
    createdAt: number;
    updatedAt: number;
  };
  statistics?: {
    priceChange1h: number;
    priceChange24h: number;
    priceChange5m: number;
    priceChange6h: number;
    totalBuy24h: number;
    totalSell24h: number;
    txns24h: number;
    volume24h: number;
  };
}

export interface TPreviousProject {
  chainId?: string | number;
  presale?: number;
  raised?: number;
  athRoi?: number;
  athVol?: number;
  athCap?: number;
  website?: string;
  twitter?: string;
  telegram?: string;
  devUsername?: string;
  link?: string;
}

export interface IAdsBoost {
  _id?: string;
  address?: string;
  chainId?: string;
  factory?: string;
  description?: string;
  startAt?: number;
  endAt?: number;
  socialLinks?: SocialLinks;
  baseToken?: string;
  baseTokenSymbol?: string;
  baseTokenName?: string;
  baseTokenLogo?: string;
  banner?: string;
  quoteToken?: string;
  quoteTokenSymbol?: string;
  quoteTokenName?: string;
  telegramContact?: string;
  status?: number;
  createdBy?: string;
  createdAt?: number;
  updatedAt?: number;
  pair?: string;
  __v?: number;
}

export type Presale = {
  link?: string;
  pairAddress?: string;
  type?: string;
  liqInitPct?: number;
  liqLockDuration?: number;
  contributors?: number;
  softCap?: number;
  hardCap?: number;
  maxBuy?: number;
  raised?: number;
  startAt?: number;
  endAt?: number;
};
export interface IPairExtraInfo {
  ath: number | null;
  athPrice: number | null;
  athPriceUsd: number | null;
  listingPrice: number | null;
  listingPriceUsd: number | null;
}

export interface BaseTokenPair {
  address: string;
  baseTokenInfo: BaseTokenData;
}

export interface BaseTokenPair {
  _id: string;
  address: string;
  chainId: string;
  factory: string;
  liquidity: number;
  priceUsd: number;
  quoteReserve: number;
  baseTokenInfo: BaseTokenData;
  quoteTokenInfo: QuoteTokenData;
  labels?: PairLabel[];
}

export interface BaseTokenData {
  address: string;
  decimal: number;
  name: string;
  symbol: string;
  totalSupply: string;
}

export interface QuoteTokenData {
  address: string;
  decimal: number;
  name: string;
  symbol: string;
}

export interface NewInformation {
  _id: string;
  volume24h: number;
  priceChange24h: number;
  txns24h: number;
  // address: string;
  // chainId: string;
  // __v: number;
  // baseToken: string;
  // baseTokenName: string;
  // baseTokenSymbol: string;
  // factory: string;
  // fdv: number;
  // liquidity: number;
  // priceChange1h: number;
  // priceChange5m: number;
  // priceChange6h: number;
  // priceUsd: number;
  // quoteToken: string;
  // quoteTokenName: string;
  // quoteTokenSymbol: string;
  // router: string;
  // status: number;
  // time: number;
  // totalBuy1h: number;
  // totalBuy24h: number;
  // totalBuy5m: number;
  // totalBuy6h: number;
  // totalSell1h: number;
  // totalSell24h: number;
  // totalSell5m: number;
  // totalSell6h: number;
  // txns1h: number;
  // txns5m: number;
  // txns6h: number;
  // volume1h: number;
  // volume5m: number;
  // volume6h: number;
  // updateAt: number;
  // basePrice: number;
  // updatedAt: Date;
  // latestBuildCandleTime: number;
  // createdAt?: string;
}

export interface IPairHistoryTransaction {
  _id: string;
  time: number;
  hash: string;
  pair: string;
  chainId: string;
  liquidity: string;
  quotePrice: string;
  token0: string;
  reserve0: string;
  reserve1: string;
  token1: string;
  logIndex: number;
  isBuy: boolean;
  basePrice: string;
  priceUsd: string;
  amountUsd: string;
  txFrom: string;
  txTo: string;
  isContract?: boolean;
  isHidden: boolean;
  baseAmount: string;
  quoteAmount: string;

  /**Calculate and assign into item */
  makerTradeInfo?: IMakerTradeInfo;
}

export interface IWalletTransaction {
  _id: string;
  time: number;
  hash: string;
  pair: string;
  // amountIn: string;
  // amountOut: string;
  isBuy: boolean;
  priceUsd: string;
  amountUsd: string;
  baseToken: string;
  quoteToken: string;
  basePrice: string;
  isReserve: boolean;
  baseAmount: string;
  currentValue: string;
  value: string;
  baseTokenName: string;
  baseTokenSymbol: string;
  quoteTokenSymbol: string;
  quoteAmount: string;
  chainId?: number;
}

export interface ITopBuyerSeller {
  from: number;
  to: number;
  data: IAmountBuyerSeller[];
}

export interface IAmountBuyerSeller {
  countBuy: number;
  countSell: number;
  bought: number;
  balance: number;
  sell: number;
  _id: string;
}

export interface IPair24hChange {
  priceChange24h: number;
  txns24h: number;
  volume24h: number;
}

export enum HomeScreen {
  NEW_PAIR = 'NEW_PAIR',
  SOCIAL_NETWORK_UPDATES = 'SOCIAL_NETWORK_UPDATES',
  SOCIAL_INFOS = 'SOCIAL_INFOS',
  PRESALES = 'PRESALES',
}

export interface IMakerTradeInfo {
  firstSeenOn: number | null;
  totalTx: number;
  totalBuy: number;
  totalSell: number;
  volume: Decimal;
  volumeBuy: Decimal;
  volumeSell: Decimal;
  profit: Decimal;
  isPositive: boolean | null;
  priceUsd: {
    min: Decimal;
    max: Decimal;
    avg: Decimal;
  };
  baseToken: {
    buy: Decimal;
    sell: Decimal;
  };
  quoteToken: {
    buy: Decimal;
    sell: Decimal;
  };
}

export default Pair;

export interface IQueryParamsTrending {
  keyword?: string;
  pageSize?: number;
  pageIndex?: number;
  scanSecurity?: boolean;
  haveDevInfo?: boolean;
  factory?: string;
  badge?: Array<string>;
  minTax?: number;
  maxTax?: number;
  minLq?: number;
  maxLq?: number;
  minHolder?: number;
  maxHolder?: number;
  minDev?: number;
  maxDev?: number;
  minInsiders?: number;
  maxInsiders?: number;
  minTopHolder?: number;
  maxTopHolder?: number;
  minMCap?: number;
  maxMCap?: number;
  minFDV?: number;
  maxFDV?: number;
  minClog?: number;
  maxClog?: number;
  minAge?: number;
  maxAge?: number;
  volHours?: number;
  minVol?: number;
  maxVol?: number;
  txsHours?: number;
  minTxs?: number;
  maxTxs?: number;
  makerHours?: number;
  minMaker?: number;
  maxMaker?: number;
  chainId?: string | number;
  statisticDuration?: number | undefined;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  isUTC?: boolean;
  isDisplayTimeAgo?: boolean;
  ageType?: string;
}

export interface IQueryParamsPresale {
  keyword?: string;
  pageSize?: number;
  pageIndex?: number;
  scanSecurity?: boolean;
  haveDevInfo?: boolean;
  devNoToken?: boolean;
  launchpad?: Array<string>;
  saleType?: Array<string>;
  badge?: Array<string>;
  softcap?: number;
  hardcap?: number;
  minBuy?: number;
  maxBuy?: number;
  minContributor?: number;
  maxContributor?: number;
  minLq?: number;
  maxLq?: number;
  minTax?: number;
  maxTax?: number;
  minTGE?: number;
  maxTGE?: number;
  chainId?: string | number;
  factory?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  isUTC?: boolean;
  isDisplayTimeAgo?: boolean;
}
export interface IQueryParamsTopPresale {
  pageSize?: number;
  pageIndex?: number;
  scanSecurity?: boolean;
  haveDevInfo?: boolean;
  factory?: string;
  badge?: Array<string>;
  minTax?: number;
  maxTax?: number;
  minLq?: number;
  maxLq?: number;
  minHolder?: number;
  maxHolder?: number;
  minDev?: number;
  maxDev?: number;
  minInsiders?: number;
  maxInsiders?: number;
  minTopHolder?: number;
  maxTopHolder?: number;
  minMCap?: number;
  maxMCap?: number;
  minFDV?: number;
  maxFDV?: number;
  minClog?: number;
  maxClog?: number;
  minAge?: number;
  maxAge?: number;
  volHours?: number;
  minVol?: number;
  maxVol?: number;
  txsHours?: number;
  minTxs?: number;
  maxTxs?: number;
  makerHours?: number;
  minMaker?: number;
  maxMaker?: number;
  chainId?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  isUTC?: boolean;
  isDisplayTimeAgo?: boolean;
}

export interface IQueryParamsInfoZone {
  activityType: (string | undefined)[];
  detail: (string | undefined)[];
  activityStatus: (string | undefined)[];
  pageIndex?: number;
  pageSize?: number;
  chainId?: number | string;
  keyword?: string;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
  isUTC?: boolean;
  isDisplayTimeAgo?: boolean;
}
