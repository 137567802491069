import { useQuery } from '@tanstack/react-query';
import { getPresales } from 'apis/presale';
import { ChainIds } from 'configs/chain';
import constants from 'configs/constants';
import queryKey from 'configs/queryKey';

interface Params {
  chainId?: string;
  factory?: string;
  pageIndex?: number;
  pageSize?: number;
}

function useTopPresales({ chainId, factory, pageIndex, pageSize }: Params, autoFetch = false) {
  return useQuery(
    [queryKey.TOP_PRESALES, chainId, factory, pageIndex, pageSize],
    () =>
      getPresales({
        chainId,
        factory,
        pageIndex,
        pageSize,
      }),
    {
      cacheTime: constants.CACHE_TIME[queryKey.PAIR_ACTIVITY],
      enabled: autoFetch,
    },
  );
}

export default useTopPresales;
