import { useEffect, useState } from "react";

export const useIsOverflow = (ref: any, enabled?: boolean) => {

  const [isOverflow, setIsOverflow] = useState(false);

  useEffect(() => {
    if (enabled) {

      const { current } = ref;
      const checkOverflow = () => {
        if (current) {
          const isOverflow =
            current.scrollHeight > current.clientHeight ||
            current.scrollWidth > current.clientWidth;
          setIsOverflow(isOverflow);
        }
      };
      // const trigger = () => {
      //   // const scrollWidth = current.scrollWidth / (isOverflow ? 2 : 1);
      //   // const hasOverflow = scrollWidth > current.clientWidth;
      //   // setIsOverflow(hasOverflow);
      //   const hasOverflow = current.scrollHeight > current.clientHeight || current.scrollWidth > current.clientWidth;
      //   setIsOverflow(hasOverflow);
      // };
      if (current) {
        if ('ResizeObserver' in window) {
          new ResizeObserver(checkOverflow).observe(current);
        }
        checkOverflow();
      }

    }
  }, [ref, enabled]);

  return isOverflow;
};