import { Link } from 'react-router-dom';
import { DiscordIcon, EarthIcon, RedditIcon, TelegramIcon, XIcon, YoutubeIcon } from '../Icons/Socials';
import { getIconSocial } from 'utils/convert';
import { twMerge } from 'tailwind-merge';

interface Props {
  type: 'website' | 'telegram' | 'twitter' | 'discord' | 'reddit' | 'youtube';
  url?: string;
  withHover?: boolean;
}

const SocialLinkItem = ({ type, url, withHover }: Props) => {
  const Placehoder = () => {
    if (type === 'website') return <EarthIcon color="#1a1a1a" size={16} />;
    if (type === 'telegram') return <TelegramIcon color="#1a1a1a" size={16} />;
    if (type === 'twitter') return <XIcon color="#1a1a1a" size={16} />;
    if (type === 'reddit') return <RedditIcon color="#1a1a1a" size={16} />;
    if (type === 'discord') return <DiscordIcon color="#1a1a1a" size={16} />;
    if (type === 'youtube') return <YoutubeIcon color="#1a1a1a" size={16} />;
  };

  const Icon = getIconSocial(type);

  return (
    <div>
      {url ? (
        <Link to={url ?? ''} target="_blank">
          <div className={twMerge(withHover ? 'hover:bg-hv-gradient p-2 rounded-lg' : '')}>
            {Icon && <Icon size={16} color="white" />}
          </div>
        </Link>
      ) : (
        <div className={twMerge(withHover ? 'p-2 rounded-lg' : '')}>
          <Placehoder />
        </div>
      )}
    </div>
  );
};

export default SocialLinkItem;
