import { Popover, PopoverArrow, PopoverContent, PopoverFooter, PopoverTrigger, useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { FilterCircleIcon } from "shared/components/Icons/Filter";
import NumberSlider from "./NumberSlider";
import MultiTag from "./MultiTag";
import MultiSelect from "./MultiSelect";
import SwitchYesNo from "./SwitchYesNo";
import MultiCheck from "./MultiCheck";
import { arrMultiCheck, arrMultiSelect, arrMultiTag, arrNumberSlider, arrSwitchYesNo, keysPairFilter } from "./keysFilter";
import { IQueryParamsTrending, IQueryParamsPresale, IQueryParamsInfoZone } from "types/Pair";
import _ from "lodash";

interface Props {
  id: string;
  filter: IQueryParamsTrending | IQueryParamsPresale | IQueryParamsInfoZone;
  setFilter: (values: IQueryParamsTrending | IQueryParamsPresale | IQueryParamsInfoZone) => void;
}

const PairFilter = ({ id, filter, setFilter }: Props) => {

  const { onOpen, onClose, isOpen } = useDisclosure()

  const [tempValue, setTempValue] = useState<any>()

  // console.log(filter)

  const keys = keysPairFilter[id]

  const isFiltered = Array.isArray(keys)
    ? keys.some(i => filter[i as keyof typeof filter])
    : Array.isArray(filter[keys as keyof typeof filter])
      // @ts-ignore
      ? filter[keys].filter(i => !!i).length > 0
      : filter[keys as keyof typeof filter]

  const renderFilter = () => {
    //
    if (arrNumberSlider.includes(id)) {
      return <NumberSlider id={id} tempValue={tempValue} setTempValue={setTempValue} />
    }
    if (arrMultiTag.includes(id)) {
      return <MultiTag id={id} tempValue={tempValue} setTempValue={setTempValue} />
    }
    if (arrMultiSelect.includes(id)) {
      return <MultiSelect id={id} tempValue={tempValue} setTempValue={setTempValue} />
    }
    if (arrSwitchYesNo.includes(id)) {
      return <SwitchYesNo id={id} tempValue={tempValue} setTempValue={setTempValue} />
    }
    if (arrMultiCheck.includes(id)) {
      return <MultiCheck tempValue={tempValue} setTempValue={setTempValue} />
    }
    return <></>;
  };

  const hanldeOpen = () => {
    keys &&
      setTempValue(
        Array.isArray(keys)
          ? keys.map((item: any) => filter[item as keyof typeof filter])
          : filter[keys as keyof typeof filter]
      )
    onOpen()
  }

  const resetFilter = () => {
    setTempValue(undefined)
    setFilter(_.omit(filter, keys))
    onClose()
  }

  const applyFilter = () => {
    setFilter(
      Object.assign(
        {},
        filter,
        ...Array.isArray(keys)
          ? keys.map((i: any, idx: number) => ({ [i]: tempValue[idx] }))
          : [{ [keys]: _.isEmpty(tempValue) ? undefined : tempValue }]
      )
    )
    onClose()
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={hanldeOpen}
      onClose={onClose}
      arrowShadowColor="none"
      arrowSize={10}
    >
      <PopoverTrigger>
        <button>
          <FilterCircleIcon
            color={isOpen || isFiltered ? '#FF9200' : '#323346'}
          />
        </button>
      </PopoverTrigger>

      <PopoverContent
        w={arrMultiCheck.includes(id) ? 193 : 289}
        color='white'
        bg="#0d0d14"
        style={{ boxShadow: "none", borderColor: "#D9D9D9", borderRadius: 10 }}
        zIndex={999}
      >
        <PopoverArrow bg="#0d0d14" borderColor="#D9D9D9" borderTop="1px solid" borderLeft="1px solid" />

        {renderFilter()}

        <PopoverFooter border="0" display="flex" alignItems="center" gap={3} padding={3}>
          <button
            className="bg-g-50 hover:bg-g-100 flex-1 rounded-[6px] pt-0.5 pb-1 text-[13px] font-semibold leading-[19.5px]"
            onClick={() => resetFilter()}
          >
            Reset
          </button>
          <button
            className="bg-g-50 flex-1 rounded-[6px] pt-0.5 pb-1 text-[13px] font-semibold leading-[19.5px] hover:bg-g-100"
            onClick={() => applyFilter()}
          >
            Apply
          </button>
        </PopoverFooter>
      </PopoverContent>
    </Popover>
  );
};

export default PairFilter;
