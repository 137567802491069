import {
  format,
  intervalToDuration,
  formatDistance,
  isBefore,
  differenceInMinutes,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
} from 'date-fns';
import i18next from 'i18next';
export function formatTimeUnix(time: number) {
  return format(new Date(time * 1000), 'dd/mm/yyyy hh:mm:ss');
}

export function getCurrentDate() {
  try {
    return new Date(format(new Date(), 'yyyy-MM-dd'));
  } catch (error) {
    return null;
  }
}

export function formatTime(time?: number | Date, formatType?: string) {
  if (!time) return '-';
  return format(time, formatType || 'yyyy-MM-dd');
}

export function getUnixStartOfDayUTC(date: Date) {
  const formatedDate = format(date, 'yyyy-MM-dd');
  return Math.floor(new Date(formatedDate + 'T00:00:00.000Z').getTime() / 1000);
}

export function formatTimePair(milliseconds: number) {
  try {
    const duration = intervalToDuration({
      start: new Date(milliseconds * 1000),
      end: new Date(),
    });
    // return durations;
    // If years exist, we show only the years
    if (duration.years && duration.years > 0) {
      return `${duration.years}y`;
    }

    // If months exist and no years, we show only the months
    if (duration.months && duration.months > 0) {
      return `${duration.months}mo`;
    }

    // If days exist and no months or years, we show only the days
    if (duration.days && duration.days > 0) {
      return `${duration.days}d`;
    }

    // If hours exist and no days, months, or years, we show only the hours
    if (duration.hours && duration.hours > 0) {
      return `${duration.hours}h`;
    }

    // If less than 1 hour, we show minutes
    if (duration.minutes && duration.minutes > 0) {
      return `${duration.minutes}m`;
    }

    // If no minutes but seconds exist, we show seconds (optional, depending on your needs)
    if (duration.seconds && duration.seconds > 0) {
      return `${duration.seconds}s`;
    }

    return ''; // Default case when no values are present
    // return i18next.t(getFormat(durations), durations);
  } catch {
    return '';
  }
  // Check if input is undefined, null, or not a number
  if (milliseconds == null || isNaN(milliseconds)) {
    return '';
  }

  const seconds = Math.floor(milliseconds / 1000);
  const minute = 60;
  const hour = 60 * minute;
  const day = 24 * hour;
  const month = 30 * day; // Approximate month
  const year = 12 * month; // Approximate year

  if (seconds < hour) {
    // Less than 1 hour -> show minutes
    return Math.floor(seconds / minute) + 'm';
  } else if (seconds < day) {
    // Less than 1 day -> show hours
    return Math.floor(seconds / hour) + 'h';
  } else if (seconds < month) {
    // Less than 1 month -> show days
    return Math.floor(seconds / day) + 'd';
  } else if (seconds < year) {
    // Less than 1 year -> show months
    return Math.floor(seconds / month) + 'mo';
  } else {
    // 1 year or more -> show years
    return Math.floor(seconds / year) + 'y';
  }
}

function getFormat(durations: Duration) {
  const keys = Object.keys(durations).filter((item: any) => (durations as any)[item]);
  const results = [];
  if (keys.includes('days')) {
    results.push('{{days}}d');
  }

  if (keys.includes('hours')) {
    results.push('{{hours}}h');
  }

  if (keys.includes('minutes')) {
    results.push('{{minutes}}m');
  }

  if (!keys.includes('days') && keys.includes('seconds')) {
    results.push('{{seconds}}s');
  }

  return results.join(' ');
}

function timeToDuration(start: number) {
  const end = Date.now();

  const ONE_MINUTE_MS = 1000 * 60;
  const ONE_HOUR_MS = ONE_MINUTE_MS * 60;
  const ONE_DAY_MS = ONE_HOUR_MS * 24;

  const minutes = Math.floor(Math.round((end - start) % ONE_HOUR_MS) / ONE_MINUTE_MS);
  const hours = Math.floor(Math.round((end - start) % ONE_DAY_MS) / ONE_HOUR_MS);
  const days = Math.floor(Math.round(end - start) / ONE_DAY_MS);
  return { minutes, hours, days };
}

export function formatTimeTokenSocialNetwork(time: number) {
  try {
    const durations = timeToDuration(time * 1000);
    return i18next.t(getFormat(durations), durations);
  } catch {
    return '';
  }
}

export function formatDistanceTime(start?: number, end?: number) {
  if (!start || !end) return '';
  return formatDistance(start, end, { addSuffix: true });
}

export function getDateInfo(start?: number, end?: number) {
  const startAt = new Date(start!);
  const endAt = new Date(end!);
  const hoursStart = startAt.getHours();
  const minutesStart = startAt.getMinutes();
  const hoursEnd = endAt.getHours();
  const minutesEnd = endAt.getMinutes();
  const startTime = hoursStart * 60 * 60 * 1000 + minutesStart * 60 * 1000;
  const endTime = hoursEnd * 60 * 60 * 1000 + minutesEnd * 60 * 1000;

  startAt.setHours(0, 0, 0, 0);
  endAt.setHours(0, 0, 0, 0);
  return {
    startAt,
    endAt,
    startTime,
    endTime,
  };
}

export function compareTime(time1: number, time2: number, reverse?: boolean) {
  let disabled = false;
  let startAt = new Date(time1);
  if (reverse) {
    startAt = new Date(time2);
  }

  const hourStart = startAt?.getHours();
  const minuteStart = startAt?.getMinutes();

  const valueStartAt = hourStart * 60 * 60 * 1000 + minuteStart * 60 * 1000;

  disabled = reverse ? time1 >= valueStartAt : time2 <= valueStartAt;

  return disabled;
}

export const formatUTCDateWithTimestamp = (timestamp: number) => {
  if (!timestamp) return '--';
  const date = new Date(timestamp);

  // Format date to YYYY/MM/DD HH:mm UTC
  const year = date.getUTCFullYear();
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const day = String(date.getUTCDate()).padStart(2, '0');
  const hours = String(date.getUTCHours()).padStart(2, '0');
  const minutes = String(date.getUTCMinutes()).padStart(2, '0');

  const formattedDate = `${year}/${month}/${day} ${hours}:${minutes} UTC`;

  return formattedDate;
};

export const getMinuteBeforeDateDistance = (date?: Date | number) => {
  const now = new Date();
  if (date && isBefore(date, now)) {
    return differenceInMinutes(now, date);
  }
  return null;
};
export const timeAgoDetailed = (pastDate: Date) => {
  const now = new Date();

  if (pastDate > now) {
    const daysAhead = differenceInDays(pastDate, now);
    const totalMinutesAhead = differenceInMinutes(pastDate, now);
    const totalHoursAhead = differenceInHours(pastDate, now);
    const totalSecondsAhead = differenceInSeconds(pastDate, now);

    if (daysAhead > 0) {
      return `Next ${daysAhead} day${daysAhead > 1 ? 's' : ''}`;
    }

    if (totalHoursAhead > 0) {
      return `Next ${totalHoursAhead}h`;
    }

    if (totalMinutesAhead > 0) {
      return `Next ${totalMinutesAhead}m`;
    }

    return `Next ${totalSecondsAhead}s`;
  }

  const daysAgo = differenceInDays(now, pastDate);
  const totalMinutesAgo = differenceInMinutes(now, pastDate);
  const totalHoursAgo = differenceInHours(now, pastDate);
  const totalSecondsAgo = differenceInSeconds(now, pastDate);

  const hours = totalHoursAgo - daysAgo * 24;
  const minutes = totalMinutesAgo % 60;

  let result = '';
  
  if (totalSecondsAgo < 60) {
    return `${totalSecondsAgo}s ago`;
  }

  if (daysAgo > 0) {
    if (daysAgo === 1) return `${daysAgo} day ago`.trim();
    return `${daysAgo} days ago`.trim();
  }

  if (hours > 0 || daysAgo > 0) {
    result += `${hours}h `;
  }
  result += `${minutes}m ago`;

  return result.trim();
};
