import path from 'configs/path';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import { getChainNameFromChainId } from 'utils/chain';
import { buildHref } from 'utils/path';

type Props = Omit<LinkProps, 'to'> &
  PropsWithChildren<{
    pathDestination?: string;
    chainId: string;
    pair: string;
    baseToken: string;
  }>;

export default function LinkToTradePage({ baseToken, chainId, pair, children, ...props }: Props) {
  const dispatch = useAppDispatch();
  const redirectURL = useMemo(() => {
    const redirect_path = buildHref(
      path.TRADE,
      {
        address: baseToken,
        chain: getChainNameFromChainId(Number(chainId)) as string,
      },
      { pair },
    );
    return redirect_path;
  }, [baseToken, chainId]);

  const setPairToGlobalState = useCallback(() => {
    if (pair && baseToken) {
      dispatch(addAddress({ address: pair, baseToken: baseToken }));
    }
  }, [pair, baseToken]);

  return (
    <Link to={redirectURL} onClick={setPairToGlobalState} {...props}>
      {children}
    </Link>
  );
}
