import { useQuery } from '@tanstack/react-query';
import { useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { ChainIds } from 'configs/chain';
import queryKey from 'configs/queryKey';
import { formatEther } from 'ethers/lib/utils';
import { getProvider } from 'utils/chain';

interface Params {
  interval?: number | false;
  enabled?: boolean;
  walletAddress?: string;
  walletChainId?: ChainIds;
}

function useAccountBalance({ enabled = true, interval, walletAddress, walletChainId }: Params) {
  const { address: address_, isConnected, chainId: chainId_ } = useWeb3ModalAccount();
  const chainId = walletChainId || chainId_;
  const address = walletAddress || address_;
  return useQuery(
    [queryKey.ACCOUNT_BALANCE, chainId, address, enabled],
    async () => {
      try {
        if (!address || chainId === 501424) return null;
        const provider = getProvider(chainId!);
        const balance = await provider.getBalance(address);
        return {
          raw: balance,
          formatted: formatEther(balance),
        };
      } catch (e) {
        return null;
      }
    },
    {
      enabled: !!isConnected && !!address && enabled && chainId !== 501424,
      // staleTime: Infinity,
      refetchInterval(data, query) {
        return interval || false;
      },
    },
  );
}

export default useAccountBalance;
