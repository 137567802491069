import { Input, InputProps } from '@chakra-ui/react';
import { ChangeEvent, forwardRef, useCallback } from 'react';
import { escapeRegExp } from 'utils/misc';

interface Props
  extends Omit<
    InputProps,
    'type' | 'autoCapitalize' | 'autoComplete' | 'autoCorrect' | 'inputMode' | 'spellCheck' | 'pattern'
  > {
  disabled?: boolean;
  readOnly?: boolean;
}

const inputRegex = RegExp('^\\d*(?:\\\\[.])?\\d*$'); // match escaped "." characters via in a non-capturing group

/**
 * WARN:
 * Don't use this input with built-in function formik.handleChange.
 * Althought, I try to forwading ref, but it's not working.
 * Only using with customize onChange props.
 */
function NumberInput(props: Props, ref: any) {
  const handleInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      /**
       * Replace all non-numeric characters.
       * When user typing or paste
       */
      const nextUserInput = e.target.value.replace(/[^0-9.,]/g, '').replace(/,/g, '.');
      if (nextUserInput === '' || inputRegex.test(escapeRegExp(nextUserInput))) {
        props.onChange?.({
          ...e,
          target: {
            ...e.target,
            value: nextUserInput,
          },
        });
      }
    },
    [props],
  );

  return (
    <Input
      ref={ref}
      placeholder="0.0"
      {...props}
      type="text"
      autoCapitalize="off"
      autoComplete="off"
      autoCorrect="off"
      inputMode="decimal"
      spellCheck={false}
      minlength={1}
      maxLength={79}
      pattern="^[0-9]*[.,]?[0-9]*$"
      onChange={handleInputChange}
    />
  );
}

export default forwardRef(NumberInput);
