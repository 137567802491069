import client from 'configs/axios';
import { ChainIds } from 'configs/chain';
import { bsc, ethereum, pulse } from 'configs/networks';
import { fetchMainTokenPriceFromRPC } from 'utils/web3Common';

const getTokenPriceFromCoinGecko = () => {
  try {
    return client.get('/api/v3/simple/price', {
      params: {
        ids: 'ethereum,binancecoin,avalanche-2,fantom,polygon,pulsechain,bitrock,bone-shibaswap,cybria,solana',
        vs_currencies: 'usd',
      },
      baseURL: 'https://api.coingecko.com',
    });
  } catch (error) {}
};

interface ITokenPriceFromAlpha {
  ethereum?: { usd: number };
  binancecoin?: { usd: number };
  dex?: { usd: number };
  'matic-network'?: { usd: number };
  pulsechain?: { usd: number };
  solana?: { usd: number };
}
async function getTokenPriceFromAlpha(): Promise<ITokenPriceFromAlpha | null> {
  try {
    const { data } = await client.get(process.env.REACT_APP_API_BASE_API_URL + '/tokens/price', {
      params: {
        ids: 'solana',
      },
      headers: {
        secret: '5ff3a258-2700-11ed-a261-0242ac120002',
      },
    });
    return data.data;
  } catch (error) {
    return null;
  }
}

// FLAG: ADD_NEW_CHAIN
export async function getNativeTokenPrice() {
  try {
    const [fromGecko, wbnbPrice, wethPrice, wPulse] = await Promise.all([
      getTokenPriceFromCoinGecko(),
      fetchMainTokenPriceFromRPC(bsc.id),
      fetchMainTokenPriceFromRPC(ethereum.id),
      fetchMainTokenPriceFromRPC(pulse.id),
    ]);

    return {
      [ChainIds.Ethereum]: wethPrice || (fromGecko?.data?.ethereum?.usd as number),
      [ChainIds.Bsc]: wbnbPrice || (fromGecko?.data?.binancecoin?.usd as number),
      [ChainIds.BscTest]: fromGecko?.data?.binancecoin?.usd as number,
      [ChainIds.Avalanche]: fromGecko?.data?.['avalanche-2']?.usd as number,
      [ChainIds.FantomOpera]: fromGecko?.data?.fantom?.usd as number,
      [ChainIds.Arbitrum]: wethPrice || (fromGecko?.data?.ethereum?.usd as number),
      [ChainIds.Polygon]: fromGecko?.data?.polygon?.usd as number,
      [ChainIds.Pulse]: wPulse || fromGecko?.data?.['pulsechain']?.usd,
      [ChainIds.Bitrock]: fromGecko?.data?.['bitrock']?.usd,
      [ChainIds.Shibarium]: fromGecko?.data?.['bone-shibaswap']?.usd,
      [ChainIds.Cybria]: fromGecko?.data?.['cybria']?.usd,
      // [ChainIds.Solana]: fromGecko?.data?.['solana']?.usd,
      [ChainIds.Base]: wethPrice || (fromGecko?.data?.ethereum?.usd as number),
    };
  } catch (e) {
    return null;
  }
}

export async function getSolanaPriceUsdFromCoinGeeko() {
  try {
    const data = await getTokenPriceFromAlpha();
    return data?.solana?.usd?.toString();
  } catch (e) {
    return;
  }
}
