import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer, REHYDRATE } from 'redux-persist';
import { generatePersistConfig } from 'utils/misc';
export type TLayout = 'left' | 'right';
interface State {
    layout: TLayout;
}

const rehydrateAction = createAction<State | undefined>(REHYDRATE);

const initialState: State = {
    layout: 'right',
};

const search = createSlice({
    name: 'layout',
    initialState,
    reducers: {
        updateLayout: (state: State, action: PayloadAction<State['layout']>) => {
            state.layout = action.payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(rehydrateAction, (state, { payload }) => {
            if (payload?.layout) state.layout = payload.layout;
        });
    },
});

export const { updateLayout } =
    search.actions;

const persistConfig = generatePersistConfig('layout', ['layout']);
export default persistReducer<State>(persistConfig, search.reducer);
