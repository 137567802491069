import { Box, useMediaQuery } from '@chakra-ui/react';
import { Tab, Tabs } from '@nextui-org/react';
import {
  ColumnDef,
  SortingState,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { infoCircleBlackIcon } from 'assets/icons';
import constants from 'configs/constants';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { SortIcon } from 'shared/components/Icons/Filter';
import { twMerge } from 'tailwind-merge';
import Pair, { HomeScreen, IQueryParamsInfoZone, IQueryParamsPresale, IQueryParamsTrending } from 'types/Pair';
import useTranslation from 'shared/hooks/useTranslation';
import { ClockIcon, DateIcon } from 'shared/components/Icons/Trade';
import SwitchUtcMode from 'shared/components/TimeDisplay/SwitchUtcMode';
interface Props {
  displayTimeAgo: boolean;
  onChange: (isDisplayTimeAgo: boolean) => void;
}
const SwitchViewTimeAgoMode = ({ displayTimeAgo, onChange }: Props) => {
  return (
    <Box display={'flex'} alignItems="center">
      <button
        onClick={() => {
          onChange(!displayTimeAgo);
        }}
      >
        {displayTimeAgo ? <ClockIcon /> : <DateIcon className="fill-[#7E818C] stroke-[#7E818C]" />}
      </button>
    </Box>
  );
};

export default SwitchViewTimeAgoMode;
