import Card from 'shared/components/Card';
import Pair, { HomeScreen, IQueryParamsInfoZone, IQueryParamsPresale, IQueryParamsTrending } from 'types/Pair';
import { createColumnHelper } from '@tanstack/react-table';
import { ChainIds } from 'configs/chain';
import SimplePagination from 'shared/components/SimplePagination';
import { Pagination } from 'types';
import Loading from 'shared/components/Loading';
import { useMemo } from 'react';
import PairTableV2 from './table/PairTableV2';

interface Props {
  isLoading: boolean;
  data: any;
  pagination: Pagination;
  chainId?: ChainIds;
  factory?: string;
  onNext?: () => void;
  onPrevious?: () => void;
  homeScreen?: HomeScreen;
  hiddenColumns?: string[];
  filter?: IQueryParamsTrending | IQueryParamsPresale | IQueryParamsInfoZone;
  setFilter?: (values: IQueryParamsTrending | IQueryParamsPresale | IQueryParamsInfoZone) => void;
}

const columnHelper = createColumnHelper<Pair>();

const DefaultColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      // isFilter: true,
    },
  }),
  columnHelper.accessor('devOf', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('scan', {
    cell: (info) => info.getValue(),
    header: 'Scan',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('pairCreatedTime', {
    cell: (info) => info.getValue(),
    header: 'Age',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      // isInfo: true,
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('priceUsd', {
    cell: (info) => info.getValue(),
    header: 'Price',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isInfo: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('liquidity', {
    cell: (info) => info.getValue(),
    header: 'Liquidity',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('holders', {
    cell: (info) => info.getValue(),
    header: 'Holder',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('mcap', {
    cell: (info) => info.getValue(),
    header: 'MCAP',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('priceChange', {
    cell: (info) => info.getValue(),
    header: 'Change',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      // isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('txns', {
    cell: (info) => info.getValue(),
    header: 'Txns',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('volume', {
    cell: (info) => info.getValue(),
    header: 'Volume',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('makers', {
    cell: (info) => info.getValue(),
    header: 'Maker',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      isFilter: true,
      isSort: true,
    },
  }),
  // columnHelper.accessor('priceChange5m', {
  //   cell: (info) => info.getValue(),
  //   header: '5M',
  //   meta: {
  //     isNumeric: true,
  //   },
  // }),
  // columnHelper.accessor('priceChange1h', {
  //   cell: (info) => info.getValue(),
  //   header: '1H',
  //   meta: {
  //     isNumeric: true,
  //   },
  // }),
  // columnHelper.accessor('priceChange6h', {
  //   cell: (info) => info.getValue(),
  //   header: '6H',
  //   meta: {
  //     isNumeric: true,
  //   },
  // }),
  // columnHelper.accessor('priceChange24h', {
  //   cell: (info) => info.getValue(),
  //   header: '24H',
  //   meta: {
  //     isNumeric: true,
  //   },
  // }),
  // columnHelper.accessor('fdv', {
  //   cell: (info) => info.getValue(),
  //   header: 'FDV',
  //   meta: {
  //     isNumeric: true,
  //   },
  // }),
  columnHelper.accessor('factory', {
    cell: (info) => info.getValue(),
    header: 'Dex',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
    meta: {
      isInfo: true,
    },
  }),
];

const NewPairColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
      isSort: true,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('devOf', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('scan', {
    cell: (info) => info.getValue(),
    header: 'Scan',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('priceUsd', {
    cell: (info) => info.getValue(),
    header: 'Price',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('tradingStartTime', {
    cell: (info) => info.getValue(),
    header: 'Age',
    meta: {
      isNumeric: true,
      textAlign: 'left',
    },
  }),
  columnHelper.accessor('totalBuy24h', {
    cell: (info) => info.getValue(),
    header: 'Buys',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('totalSell24h', {
    cell: (info) => info.getValue(),
    header: 'Sells',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('txns24h', {
    cell: (info) => info.getValue(),
    header: 'Txns',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('volume24h', {
    cell: (info) => info.getValue(),
    header: 'Volume',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange5m', {
    cell: (info) => info.getValue(),
    header: '5M',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange1h', {
    cell: (info) => info.getValue(),
    header: '1H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange6h', {
    cell: (info) => info.getValue(),
    header: '6H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('priceChange24h', {
    cell: (info) => info.getValue(),
    header: '24H',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('liquidity', {
    cell: (info) => info.getValue(),
    header: 'Liquidity',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('fdv', {
    cell: (info) => info.getValue(),
    header: 'FDV',
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor('factory', {
    cell: (info) => info.getValue(),
    header: 'Dex',
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
  }),
];

const SocialNetworkUpdatedColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
      isSort: true,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('devOf', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('scan', {
    cell: (info) => info.getValue(),
    header: 'Scan',
    meta: {
      isSort: true,
    },
  }),
  columnHelper.accessor('lastAction', {
    cell: (info) => info.getValue(),
    header: 'Activity',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isSort: true,
    },
  }),
  columnHelper.accessor('lastAction', {
    cell: (info) => info.getValue(),
    header: 'Detail',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue()?.status,
    header: 'Status',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue(),
    header: 'Upload',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isSort: true,
    },
  }),
  columnHelper.accessor('baseTokenInfo', {
    cell: (info) => info.getValue(),
    header: 'Proof',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
    },
  }),
  columnHelper.accessor('factory', {
    cell: (info) => info.getValue(),
    header: 'Dex',
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
  }),
];

const SocialInfos = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
      isSort: true,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('devOf', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('scan', {
    cell: (info) => info.getValue(),
    header: 'Scan',
    meta: {
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('activity.type', {
    cell: (info) => {
      return info.getValue();
    },
    header: 'Activity',
    meta: {
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('activity.description', {
    cell: (info) => info.getValue(),
    header: 'Detail',
    meta: {
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: true,
      isSort: true,
    },
  }),
  // columnHelper.accessor('tradingStartTime', {
  columnHelper.accessor('activity.startAt', {
    cell: (info) => info.getValue(),
    header: 'Time',
    meta: {
      align: 'justify-start',
      textAlign: 'text-left',
      isInfo: false,
      isFilter: true,
      isSort: true,
      isUTC: true
    },
  }),
  columnHelper.accessor('activity.activityStatus', {
    cell: (info) => info.getValue(),
    header: 'Status',
    meta: {
      align: 'justify-center',
      textAlign: 'text-center',
      isFilter: true,
      isSort: true
    },
  }),
  columnHelper.accessor('activity.proof', {
    cell: (info) => info.getValue(),
    header: 'Proof',
    meta: {
      // align: 'justify-centr',
      // textAlign: 'text-right',
      // isFilter: true,
      isFilter: true,
      isSort: true
    },
  }),
  columnHelper.accessor('activity.updatedAt', {
    cell: (info) => info.getValue(),
    header: 'Upload',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isDisplayTimeAgo: true,
      isInfo: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
    meta: {
      isInfo: true,
    },
  }),
];

const PresaleColumns = [
  columnHelper.accessor('baseTokenSymbol', {
    cell: (info) => info.getValue(),
    header: 'Pair',
    meta: {
      isInfo: true,
      align: 'justify-start',
      textAlign: 'text-left',
      isFilter: false,
    },
  }),
  columnHelper.accessor('badges', {
    // cell: (info) => {
    //   return ['M', 'D', 'S', 'K', 'A'];
    // },
    cell: (info) => info.getValue(),
    header: 'Badge',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('socialLinks', {
    cell: (info) => info.getValue(),
    header: 'Social',
    meta: {
      // isFilter: true,
    },
  }),
  columnHelper.accessor('devOf', {
    cell: (info) => info.getValue(),
    header: 'Dev of',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('scan', {
    cell: (info) => info.getValue(),
    header: 'Scan',
    meta: {
      isFilter: true,
    },
  }),
  columnHelper.accessor('presaleInfo.link', {
    cell: (info) => info.getValue(),
    header: 'Launchpad',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isFilter: true,
    },
  }),
  columnHelper.accessor('presaleInfo.type', {
    cell: (info) => info.getValue(),
    header: 'Type presale',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      isFilter: true,
    },
  }),
  columnHelper.accessor('presaleInfo.softCap', {
    cell: (info) => info.getValue(),
    header: 'Soft cap',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      textAlign: 'text-right',
      isFilter: true,
      isSort: true,
    },
  }),
  columnHelper.accessor('presaleInfo.hardCap', {
    cell: (info) => info.getValue(),
    header: 'Hard cap',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      textAlign: 'text-right',
      isFilter: true,
    },
  }),
  columnHelper.accessor('presaleInfo.maxBuy', {
    cell: (info) => info.getValue(),
    header: 'Max buy',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      textAlign: 'text-right',
      isFilter: true,
    },
  }),
  columnHelper.accessor('presaleInfo.raised', {
    cell: (info) => info.getValue(),
    header: 'Raised',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      textAlign: 'text-right',
      // isFilter: true,
    },
  }),
  columnHelper.accessor('presaleInfo.contributors', {
    cell: (info) => info.getValue(),
    header: 'Buyer',
    meta: {
      isNumeric: true,
      align: 'justify-end',
      textAlign: 'text-right',
      isFilter: true,
      isSort: true,
    },
  }),
  // columnHelper.accessor('presaleInfo.startAt', {
  //   cell: (info) => info.getValue(),
  //   header: 'Countdown',
  //   meta: {
  //     align: 'justify-end',
  //     textAlign: 'text-right',
  //   },
  // }),
  columnHelper.accessor('presaleInfo.startAt', {
    cell: (info) => info.getValue(),
    header: 'Start',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      // isFilter: true,
      isSort: true,
      isUTC: true
    },
  }),
  columnHelper.accessor('presaleInfo.endAt', {
    cell: (info) => info.getValue(),
    header: 'End',
    meta: {
      align: 'justify-end',
      textAlign: 'text-right',
      // isFilter: true,
      isSort: true,

      isSwitchViewTimeMode: true

    },
  }),
  columnHelper.accessor('address', {
    cell: (info) => info.getValue(),
    header: 'Action',
    meta: {
      isInfo: true,
    },
  }),
];

function getColumns(homeScreen?: HomeScreen, hiddenColumns?: string[]) {
  let columns = [];
  if (homeScreen === HomeScreen.NEW_PAIR) {
    columns = NewPairColumns;
  } else if (homeScreen === HomeScreen.SOCIAL_NETWORK_UPDATES) {
    columns = SocialNetworkUpdatedColumns;
  } else if (homeScreen === HomeScreen.SOCIAL_INFOS) {
    columns = SocialInfos;
  } else if (homeScreen === HomeScreen.PRESALES) {
    columns = PresaleColumns;
  } else {
    columns = DefaultColumns;
  }

  return columns.filter((column) => {
    if (!hiddenColumns) {
      return true;
    }
    if (!hiddenColumns.includes('all')) {
      return true;
    }
    return !hiddenColumns.includes((column as any).accessorKey);
  });
}

function PairActivity({
  isLoading,
  data,
  pagination,
  onNext,
  onPrevious,
  homeScreen,
  hiddenColumns,
  filter,
  setFilter,
}: Props) {
  // hiddenColumns={['priceChange5m']}
  // ['priceChange5m', 'priceChange1m']

  const columnsData = useMemo(() => {
    return getColumns(homeScreen, hiddenColumns);
  }, [homeScreen, hiddenColumns]);

  return (
    <Card px={3} py={0} bg="transparent">
      <PairTableV2
        data={data?.data || ([] as any)}
        columns={columnsData}
        homeScreen={homeScreen}
        currentPage={pagination.page}
        limit={pagination.limit}
        //
        filter={filter}
        setFilter={setFilter}
      />
      {isLoading ? <Loading p={10} borderTopWidth={1} /> : null}
      {data ? (
        <div className="mt-3 flex items-center justify-end gap-2">
          <SimplePagination
            onNext={onNext}
            onPrevious={onPrevious}
            page={pagination.page}
            pageLimit={pagination.limit}
            totalItems={data?.totalItems ? Number(data?.totalItems) : data?.data?.length}
            isPreviousDisabled={pagination.page === 1}
            isNextDisabled={pagination.page >= Number(data?.totalPages)}
            buttonGroupProps={{
              mt: 2,
            }}
          />
        </div>
      ) : null}
    </Card>
  );
}

export default PairActivity;
