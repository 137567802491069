import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InfoZoneTabEnum, OnChainTabEnum } from 'configs/enums';
import { TZone } from 'types/Common';
export type TLayout = 'left' | 'right';

interface State {
  tabOnChainZoneActive: OnChainTabEnum;
  tabOnChainInfoZoneActive: InfoZoneTabEnum;
  zone: TZone;
}

const initialState: State = {
  tabOnChainZoneActive: OnChainTabEnum.TOP_TRENDINGS,
  zone: 'ONCHAIN',
  tabOnChainInfoZoneActive: InfoZoneTabEnum.ALL_INFOMATION,
};

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updateTabActive: (state: State, action: PayloadAction<State['tabOnChainZoneActive']>) => {
      state.tabOnChainZoneActive = action.payload;
    },
    updateInfozoneTabActive: (state: State, action: PayloadAction<State['tabOnChainInfoZoneActive']>) => {
      state.tabOnChainInfoZoneActive = action.payload;
    },
    updateZone: (state: State, action: PayloadAction<State['zone']>) => {
      state.zone = action.payload;
    },
  },
});

export const { updateTabActive, updateZone, updateInfozoneTabActive } = common.actions;
export default common.reducer;
