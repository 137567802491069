export const FilterIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 17, color = '#8C8C8C' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Subtract"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.65824 16H13.8418C15.4502 16 16.75 14.7002 16.75 13.0918V2.90824C16.75 1.29982 15.4502 0 13.8418 0L3.65824 0C2.04982 0 0.75 1.29982 0.75 2.90824V13.0918C0.75 14.7002 2.04982 16 3.65824 16ZM12.7291 11.7265H13.7203C14.0663 11.7265 14.3188 12.007 14.3375 12.3437C14.3469 12.6803 14.0383 12.9609 13.7203 12.9609H13.7016C13.4336 12.9671 13.1655 12.965 12.8974 12.9629L12.897 12.9629C12.7631 12.9619 12.6292 12.9609 12.4953 12.9609H10.0079H7.13704H6.44505C6.19257 13.578 5.56603 14.0176 4.82728 14.0176C3.8641 14.0176 3.0786 13.2695 3.0786 12.3437C3.0786 11.4179 3.8641 10.6698 4.82728 10.6698C5.56603 10.6698 6.19257 11.1093 6.44505 11.7265H7.48304H10.3539H12.7291ZM12.6447 6.27468C11.934 6.27468 11.3168 6.68614 11.0456 7.27527H10.3536H7.48277H4.99534C4.86131 7.27527 4.72727 7.27423 4.59324 7.27319C4.32517 7.27111 4.0571 7.26903 3.78903 7.27527H3.77033C3.45238 7.27527 3.14379 7.5558 3.15314 7.89245C3.1812 8.21974 3.43368 8.50028 3.77968 8.50028H4.77091H7.14613H10.017H11.0175C11.2607 9.14552 11.8966 9.60373 12.654 9.60373C13.6172 9.60373 14.4027 8.85563 14.4027 7.92985C14.4027 7.00408 13.6078 6.27468 12.6447 6.27468ZM6.417 3.90884C6.14581 4.48861 5.53798 4.90007 4.82728 4.90007C3.8641 4.90007 3.0786 4.16132 3.0786 3.23555C3.0786 2.30977 3.8641 1.56167 4.82728 1.56167C5.58474 1.56167 6.22997 2.02923 6.46375 2.67447H7.48304H10.3539H12.7291H13.7203C14.0663 2.67447 14.3188 2.95501 14.3375 3.29165C14.3469 3.6283 14.0383 3.90884 13.7203 3.90884H13.7016C13.4336 3.91507 13.1655 3.91299 12.8974 3.91091C12.7634 3.90988 12.6293 3.90884 12.4953 3.90884H10.0079H7.13704H6.417Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export const FilterCircleIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 14, color = '#323346' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_302_8512)">
        <g id="Group">
          <path
            id="Vector"
            d="M7.09665 13.3933C10.6847 13.3933 13.5933 10.4847 13.5933 6.89667C13.5933 3.30867 10.6847 0.400024 7.09665 0.400024C3.50865 0.400024 0.600006 3.30867 0.600006 6.89667C0.600006 10.4847 3.50865 13.3933 7.09665 13.3933Z"
            fill="black"
          />
          <g id="Group_2">
            <path
              id="Vector_2"
              d="M2.94661 5.22055C5.37363 5.22055 7.79395 5.22055 10.221 5.22055C10.5629 5.22055 10.9048 5.22055 11.2468 5.22055C12.4536 5.22055 12.4536 3.34329 11.2468 3.34329C8.81974 3.34329 6.39942 3.34329 3.9724 3.34329C3.63047 3.34329 3.28854 3.34329 2.94661 3.34329C1.7398 3.34329 1.7398 5.22055 2.94661 5.22055Z"
              fill={color}
            />
          </g>
          <g id="Group_3">
            <path
              id="Vector_3"
              d="M4.78361 8.63983C6.13792 8.63983 7.48552 8.63983 8.83983 8.63983C9.03426 8.63983 9.22198 8.63983 9.41641 8.63983C9.90584 8.63983 10.3752 8.21074 10.355 7.7012C10.3349 7.19166 9.94607 6.76257 9.41641 6.76257C8.06211 6.76257 6.7145 6.76257 5.3602 6.76257C5.16577 6.76257 4.97804 6.76257 4.78361 6.76257C4.29418 6.76257 3.82487 7.19166 3.84498 7.7012C3.8718 8.21074 4.26066 8.63983 4.78361 8.63983Z"
              fill={color}
            />
          </g>
          <g id="Group_4">
            <path
              id="Vector_4"
              d="M6.2452 11.7977C6.74804 11.7977 7.24417 11.7977 7.74701 11.7977C7.82076 11.7977 7.8878 11.7977 7.96155 11.7977C8.20291 11.7977 8.45098 11.6904 8.62529 11.5228C8.7862 11.3619 8.90688 11.0937 8.90018 10.859C8.88677 10.6177 8.81302 10.3629 8.62529 10.1953C8.44427 10.0277 8.21632 9.92041 7.96155 9.92041C7.45871 9.92041 6.96258 9.92041 6.45974 9.92041C6.38599 9.92041 6.31895 9.92041 6.2452 9.92041C6.00384 9.92041 5.75577 10.0277 5.58146 10.1953C5.42055 10.3629 5.29316 10.6311 5.30657 10.8657C5.31998 11.1071 5.39373 11.3619 5.58146 11.5295C5.76248 11.6971 5.99043 11.7977 6.2452 11.7977Z"
              fill={color}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_302_8512">
          <rect width="13" height="13" fill="white" transform="translate(0.600006 0.400024)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SortIcon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  color2?: string;
}> = ({ width = 5, height = 14, color = '#666666', color2 = '#666666' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 5 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_77_7835)">
        <g id="Group">
          <path id="Vector" d="M0.100006 8.95001H4.90001L2.50001 13.15" fill={color} />
          <path id="Vector_2" d="M0.100006 4.85002H4.90001L2.50001 0.650024" fill={color2} />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_7835">
          <rect width="4.8" height="12.5" fill="white" transform="translate(0.100006 0.650024)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const CopyOutlineIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 18, color = '#323346' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_77_8101)">
        <g id="Group">
          <path
            id="Vector"
            d="M0.799988 6.55002V13.95C0.799988 15.45 1.89999 16.65 3.29999 16.65H10.7C12.1 16.65 13.2 15.45 13.2 13.95V6.55002C13.2 5.05002 12.1 3.85002 10.7 3.85002H3.29999C1.89999 3.75002 0.799988 5.05002 0.799988 6.55002ZM2.09999 6.25002C2.09999 5.75002 2.49999 5.25002 2.99999 5.25002H10.8C11.3 5.25002 11.7 5.65002 11.7 6.25002V14.15C11.7 14.65 11.3 15.15 10.8 15.15H2.99999C2.49999 15.15 2.09999 14.75 2.09999 14.15V6.25002Z"
            fill={color}
          />
          <path
            id="Vector_2"
            d="M15.6 10.25V2.85002C15.6 2.35002 15.2 1.95002 14.8 1.95002H7.5C7 1.95002 6.7 2.35002 6.7 2.85002H5C5 1.35002 6.1 0.150024 7.5 0.150024H14.8C16.2 0.150024 17.3 1.35002 17.3 2.85002V10.25C17.3 11.75 16.2 12.95 14.8 12.95H13.9V11.15H14.8C15.3 11.15 15.6 10.75 15.6 10.25Z"
            fill={color}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_8101">
          <rect width="16.5" height="16.5" fill="white" transform="translate(0.799988 0.150024)" />
        </clipPath>
      </defs>
    </svg>
  );
};
