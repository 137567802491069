import { ethers } from 'ethers';
import TrustedToken from 'types/TrustedToken';
import { parseEther, parseUnits } from 'ethers/lib/utils';

import { ChainIds } from './chain';
import {
  arbitrum,
  avalandche,
  base,
  bitrock,
  bsc,
  cybria,
  ethereum,
  fantomOpera,
  polygon,
  pulse,
  shibarium,
  bscTest,
} from './networks';

import IcBNB from 'assets/icons/tokens/bnb.svg';
import IcBUSD from 'assets/icons/tokens/busd.svg';
import IcDAI from 'assets/icons/tokens/dai.svg';
import IcETH from 'assets/icons/tokens/eth.svg';
import IcUSDC from 'assets/icons/tokens/usdc.svg';
import IcUSDT from 'assets/icons/tokens/usdt.svg';
import IcWETH from 'assets/icons/tokens/weth.png';
import IcWMATIC from 'assets/icons/tokens/wmatic.png';
import IcWPLS from 'assets/icons/tokens/wpls.png';
import IcWBrock from 'assets/icons/tokens/wbrock.png';
import IcBitrock from 'assets/icons/chain/brock.png';
import IcApeSwap from 'assets/icons/dex/apeswap.svg';
import IcBiSwap from 'assets/icons/dex/biswap.png';
import IcCyba from 'assets/icons/chain/cybria.png';
import IcMdex from 'assets/icons/dex/mdex.png';
import IcPancakeSwap from 'assets/icons/dex/pancakeswap.svg';
import IcBabySwap from 'assets/icons/dex/babyswap.svg';
import IcBaseSwap from 'assets/icons/dex/baseswap.webp';
import IcShushiSwap from 'assets/icons/dex/shushiswap.svg';
import IcUniSwap from 'assets/icons/dex/uniswap.png';
import IcAlienFi from 'assets/icons/dex/alien.png';
import IcQuickSwap from 'assets/icons/dex/quickswap.png';
import IcMMFinance from 'assets/icons/dex/mmfinance.png';
import IcPulseX from 'assets/icons/dex/pulsex.png';
import IcWBone from 'assets/icons/tokens/wbone.png';
import IcWcyba from 'assets/icons/tokens/wcyba.png';
import IcDogSwap from 'assets/icons/dex/dogswap.webp';
import IcWsol from 'assets/icons/tokens/sol.svg';

const wBNB = {
  name: 'Wrapped BNB',
  address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  symbol: 'WBNB',
  decimals: 18,
  isWrappedToken: true,
  icon: IcBNB,
};

const wtBnb = {
  name: 'Wrapped BNB',
  address: '0xae13d989dac2f0debff460ac112a837c89baa7cd',
  symbol: 'WBNB',
  decimals: 18,
  isWrappedToken: true,
  icon: IcBNB,
};

const wEth = {
  name: 'Wrapped Ether',
  address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  symbol: 'WETH',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWETH,
};

const ArBwEth = {
  name: 'Wrapped Ether',
  address: '0x82aF49447D8a07e3bd95BD0d56f35241523fBab1',
  symbol: 'WETH',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWETH,
};
const BaseWeth = {
  name: 'Wrapped Ether',
  address: '0x4200000000000000000000000000000000000006',
  symbol: 'WETH',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWETH,
};

const wMatic = {
  name: 'Wrapped Matic',
  address: '0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270',
  symbol: 'WMATIC',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWMATIC,
};

const wPluse = {
  name: 'Wrapped Pluse',
  address: '0xA1077a294dDE1B09bB078844df40758a5D0f9a27',
  symbol: 'WPLS',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWPLS,
};

const wBrock = {
  name: 'Wrapped Bitrock',
  address: '0x413f0E3A440abA7A15137F4278121450416882d5',
  symbol: 'WBROCK',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWBrock,
};

const wBone = {
  name: 'Wrapped BONE',
  address: '0x213c25900f365F1BE338Df478CD82beF7Fd43F85',
  symbol: 'WBONE',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWBone,
};

const wCyba = {
  name: 'Wrapped CYBRIA',
  address: '0x8e3607E6BF321a9D87273CA9021ec51cf1C55fFE',
  symbol: 'WCYBA',
  decimals: 18,
  isWrappedToken: true,
  icon: IcWcyba,
};

const wSol = {
  name: 'Wrapped SOLANA',
  address: 'So11111111111111111111111111111111111111112',
  symbol: 'WSOL',
  decimals: 9,
  isWrappedToken: true,
  icon: IcWsol,
};

export const SOL_ADDRESS = '11111111111111111111111111111111';

const wrappedTokens = {
  [ChainIds.Bsc]: wBNB,
  [ChainIds.BscTest]: wtBnb,
  [ChainIds.Ethereum]: wEth,
  [ChainIds.Arbitrum]: ArBwEth,
  [ChainIds.Polygon]: wMatic,
  [ChainIds.Pulse]: wPluse,
  [ChainIds.Bitrock]: wBrock,
  [ChainIds.Shibarium]: wBone,
  [ChainIds.Cybria]: wCyba,
  // [ChainIds.Solana]: wSol,
  [ChainIds.Base]: BaseWeth,
  [ChainIds.Avalanche]: {
    address: '',
  },
  [ChainIds.FantomOpera]: {
    address: '',
  },
};

const trustedTokens: Record<ChainIds, TrustedToken[]> = {
  [ChainIds.Bsc]: [
    { ...bsc.nativeCurrency, isNativeToken: true, address: ethers.constants.AddressZero, icon: IcBNB } as TrustedToken,
    wBNB,
    {
      name: 'Binance-Peg BUSD Token',
      address: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
      symbol: 'BUSD',
      decimals: 18,
      icon: IcBUSD,
    },
    {
      name: 'Binance-Peg BSC-USD',
      address: '0x55d398326f99059ff775485246999027b3197955',
      symbol: 'USDT',
      decimals: 18,
      icon: IcUSDT,
    },
    {
      name: 'Binance-Peg USD Coin',
      address: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      symbol: 'USDC',
      decimals: 18,
      icon: IcUSDC,
    },
    {
      name: 'Binance-Peg Dai Token',
      address: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      symbol: 'DAI',
      decimals: 18,
      icon: IcDAI,
    },
  ],
  [ChainIds.BscTest]: [
    { ...bsc.nativeCurrency, isNativeToken: true, address: ethers.constants.AddressZero, icon: IcBNB } as TrustedToken,
    wtBnb,
  ],
  [ChainIds.Ethereum]: [
    {
      ...ethereum.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcETH,
    } as TrustedToken,
    wEth,
    {
      name: 'Binance USD',
      address: '0x4Fabb145d64652a948d72533023f6E7A623C7C53',
      symbol: 'BUSD',
      decimals: 18,
      icon: IcBUSD,
    },
    {
      name: 'Tether USD',
      address: '0xdac17f958d2ee523a2206206994597c13d831ec7',
      symbol: 'USDT',
      decimals: 6,
      icon: IcUSDT,
    },
    {
      name: 'USD Coin',
      address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
      symbol: 'USDC',
      decimals: 6,
      icon: IcUSDC,
    },
    {
      name: 'Dai Stablecoin',
      address: '0x6b175474e89094c44da98b954eedeac495271d0f',
      symbol: 'DAI',
      decimals: 18,
      icon: IcDAI,
    },
  ],
  [ChainIds.Avalanche]: [
    { ...avalandche.nativeCurrency, isNativeToken: true, address: ethers.constants.AddressZero } as TrustedToken,
  ],
  [ChainIds.FantomOpera]: [
    { ...fantomOpera.nativeCurrency, isNativeToken: true, address: ethers.constants.AddressZero } as TrustedToken,
  ],
  [ChainIds.Arbitrum]: [
    {
      ...arbitrum.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcETH,
    } as TrustedToken,
    ArBwEth,
    {
      name: 'Tether USD',
      address: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
      symbol: 'USDT',
      decimals: 6,
      icon: IcUSDT,
    },
    {
      name: 'USD Coin',
      address: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
      symbol: 'USDC',
      decimals: 6,
      icon: IcUSDC,
    },
    {
      name: 'Dai Stablecoin',
      address: '0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1',
      symbol: 'DAI',
      decimals: 18,
      icon: IcDAI,
    },
  ],
  [ChainIds.Polygon]: [
    {
      ...polygon.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcWMATIC,
    } as TrustedToken,
    ArBwEth,
    {
      name: 'Tether USD (PoS)',
      address: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
      symbol: 'USDT',
      decimals: 6,
      icon: IcUSDT,
    },
    {
      name: 'USD Coin (PoS)',
      address: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
      symbol: 'USDC',
      decimals: 6,
      icon: IcUSDC,
    },
    {
      name: 'Dai Stablecoin (Pos)',
      address: '0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063',
      symbol: 'DAI',
      decimals: 18,
      icon: IcDAI,
    },
  ],
  [ChainIds.Pulse]: [
    {
      ...pulse.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcWPLS,
    } as TrustedToken,
    ArBwEth,
    {
      name: 'Tether USD',
      address: '0x0Cb6F5a34ad42ec934882A05265A7d5F59b51A2f',
      symbol: 'USDT',
      decimals: 6,
      icon: IcUSDT,
    },
    {
      name: 'USD Coin',
      address: '0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07',
      symbol: 'USDC',
      decimals: 6,
      icon: IcUSDC,
    },
    {
      name: 'Dai Stablecoin',
      address: '0xefD766cCb38EaF1dfd701853BFCe31359239F305',
      symbol: 'DAI',
      decimals: 18,
      icon: IcDAI,
    },
  ],
  [ChainIds.Bitrock]: [
    {
      ...bitrock.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcBitrock,
    } as TrustedToken,
    wBrock,
  ],
  [ChainIds.Shibarium]: [
    {
      ...shibarium.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcWBone,
    } as TrustedToken,
    wBone,
  ],
  [ChainIds.Cybria]: [
    {
      ...cybria.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcWcyba,
    } as TrustedToken,
    wCyba,
    {
      name: 'Tether USD',
      address: '0x84c7f870137b48c00F601FD13667651338007599',
      symbol: 'USDT',
      decimals: 18,
      icon: IcUSDT,
    },
  ],
  [ChainIds.Base]: [
    {
      ...base.nativeCurrency,
      isNativeToken: true,
      address: ethers.constants.AddressZero,
      icon: IcETH,
    } as TrustedToken,
    BaseWeth,
    {
      name: 'USD Coin',
      address: '0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913',
      symbol: 'USDC',
      decimals: 6,
      icon: IcUSDC,
    },
  ],
};

const adapters: Record<
  string,
  {
    name: string;
    icon: string;
    website: string;
  }
> = {
  'pancakeswap v3': {
    name: 'PancakeSwap V3',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/',
  },
  'PancakeSwap V3': {
    name: 'PancakeSwap V3',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/swap?outputCurrency=',
  },
  'PancakeSwap V2': {
    name: 'PancakeSwap V2',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/swap?outputCurrency=',
  },
  PancakeSwap: {
    name: 'PancakeSwap V2',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/swap?outputCurrency=',
  },
  'pancakeswap v3 eth': {
    name: 'PancakeSwap V3',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/',
  },
  'pancakeswap v2': {
    name: 'PancakeSwap V2',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/',
  },
  apeswap: {
    name: 'ApeSwap',
    icon: IcApeSwap,
    website: 'https://apeswap.finance/',
  },
  biswap: {
    name: 'Biswap',
    icon: IcBiSwap,
    website: 'https://biswap.org/',
  },
  mdex: {
    name: 'Mdex',
    icon: IcMdex,
    website: 'https://mdex.com/#/',
  },
  babyswap: {
    name: 'BabySwap',
    icon: IcBabySwap,
    website: 'https://home.babyswap.finance/',
  },
  sushiswap: {
    name: 'SushiSwap',
    icon: IcShushiSwap,
    website: 'https://www.sushi.com/',
  },
  'uniswap v3': {
    name: 'Uniswap V3',
    icon: IcUniSwap,
    website: 'https://app.uniswap.org/#/swap',
  },
  'uniswap v3 eth': {
    name: 'Uniswap V3',
    icon: IcUniSwap,
    website: 'https://app.uniswap.org/#/swap',
  },
  'uniswap v3 polygon': {
    name: 'Uniswap V3',
    icon: IcUniSwap,
    website: 'https://app.uniswap.org/#/swap',
  },
  'uniswap v3 arbitrum': {
    name: 'Uniswap V3',
    icon: IcUniSwap,
    website: 'https://app.uniswap.org/#/swap',
  },
  'uniswap v2': {
    name: 'Uniswap V2',
    icon: IcUniSwap,
    website: 'https://app.uniswap.org/#/swap',
  },
  'sushiswap arbitrum': {
    name: 'SushiSwap',
    icon: IcShushiSwap,
    website: 'https://www.sushi.com/',
  },
  alienfi: {
    name: 'AlienFi',
    icon: IcAlienFi,
    website: 'https://www.alien.fi/',
  },
  quickswap: {
    name: 'QuickSwap',
    icon: IcQuickSwap,
    website: 'https://quickswap.exchange/',
  },
  'sushiswap polygon': {
    name: 'SushiSwap',
    icon: IcShushiSwap,
    website: 'https://www.sushi.com/',
  },
  mmfinance: {
    name: 'MMFinance',
    icon: IcMMFinance,
    website: 'https://polymm.finance/',
  },
  pulsex: {
    name: 'PulseX',
    icon: IcPulseX,
    website: 'https://pulsex.com/',
  },
  rock: {
    name: 'RockSwap',
    icon: IcBitrock,
    website: 'https://rock-swap.io/',
  },
  dogswap: {
    name: 'DogSwap',
    icon: IcDogSwap,
    website: 'https://dog-swap.finance/',
  },
  cybaswap: {
    name: 'CybaSwap',
    icon: IcCyba,
    website: 'https://swap.cybria.io/',
  },
  baseswap: {
    name: 'BaseSwap',
    icon: IcBaseSwap,
    website: 'https://baseswap.com/',
  },
  'pancakeswap v2 base': {
    name: 'PancakeSwap V2 Base',
    icon: IcPancakeSwap,
    website: 'https://pancakeswap.finance/',
  },
  'uniswap v2 base': {
    name: 'Uniswap V2 Base',
    icon: IcUniSwap,
    website: 'https://app.uniswap.org/#/swap',
  },
};

/**
 * Base on pancakeswap, we need to minus a little bit amount of native token to save for gas fee.
 * Ex: 0.01 BNB is safe number for Binance Smart Chain
 * Each chain will have an amount.
 */
const minAmountsOfNativeTokenForGasFee: Record<ChainIds, ethers.BigNumber> = {
  [ChainIds.Bsc]: parseEther('0.01'),
  [ChainIds.BscTest]: parseEther('0.01'),
  [ChainIds.Ethereum]: parseEther('0.001'),
  [ChainIds.Arbitrum]: parseEther('0.001'),
  [ChainIds.Polygon]: parseEther('0.01'),
  [ChainIds.Pulse]: parseEther('0.01'),
  [ChainIds.Avalanche]: parseEther('0.01'),
  [ChainIds.FantomOpera]: parseEther('0.01'),
  [ChainIds.Bitrock]: parseEther('0.01'),
  [ChainIds.Shibarium]: parseEther('0.01'),
  [ChainIds.Cybria]: parseEther('0.01'),
  // [ChainIds.Solana]: parseUnits('0.01', 9),
  [ChainIds.Base]: parseEther('0.001'),
};

const amm = {
  trustedTokens,
  wrappedTokens,
  minAmountsOfNativeTokenForGasFee,
  adapters,
  MAX_HOPS: 2,
  INCREASE_GAS_LIMIT_PERCENT: 110, // new gas = 110% old gas = 10% increase
};

export default amm;
