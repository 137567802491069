import { useQuery } from '@tanstack/react-query'
import { getBoostAds } from 'apis/boost-ads'
import { ChainIds } from 'configs/chain'
import constants from 'configs/constants'
import queryKey from 'configs/queryKey'
import React from 'react'

type Props = {
    chainId?: string | ChainIds,
    addressInParams?: string,
    enabled?: boolean
}

export default function useGetBoostAds({ chainId, addressInParams, enabled = true }: Props) {
    return useQuery(
        [queryKey.BOOSTS, chainId, addressInParams],
        () => getBoostAds({ chainId, tokenAddress: addressInParams }),
        {
            refetchInterval: constants?.REFRESH_INTERVAL?.[queryKey.BOOSTS] || 1000 * 10,
            enabled
        }
    )
}