import { string } from 'yup';

export const ContactIcon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
}> = ({ width = 20, height = 16, color = '#808080' }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_374_1884)">
        <path
          d="M3.94995 9.8V6.4C3.94995 3.4 6.64995 1 10.05 1C13.35 1 16.15 3.4 16.15 6.4V9.8C16.15 12.8 13.45 15.2 10.05 15.2"
          stroke={color}
          strokeMiterlimit="10"
        />
        <path
          d="M4.54998 5.40002C4.44998 5.40002 4.34998 5.40002 4.34998 5.40002C2.34998 5.40002 0.849976 6.90002 0.849976 8.80002V9.40002C0.849976 11.3 2.34998 12.8 4.24998 12.8C4.34998 12.8 4.44998 12.8 4.44998 12.8V5.40002H4.54998Z"
          fill={color}
        />
        <path
          d="M15.45 5.40002C15.55 5.40002 15.65 5.40002 15.65 5.40002C17.55 5.40002 19.05 6.90002 19.05 8.80002V9.40002C19.05 11.3 17.55 12.8 15.65 12.8C15.55 12.8 15.45 12.8 15.45 12.8V5.40002Z"
          fill={color}
        />
        <path
          d="M12.15 15.1C12.15 15.6 11.75 16 11.25 16H8.84995C8.34995 16 7.94995 15.6 7.94995 15.1C7.94995 14.6 8.34995 14.2 8.84995 14.2H11.15C11.75 14.1 12.15 14.5 12.15 15.1Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_374_1884">
          <rect width="18.3" height="15.5" fill="white" transform="translate(0.849976 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LinkOutlineIcon: React.FC<{
  size?: number;
  color?: string;
}> = ({ size = 16, color = '#224FDA' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2775_1886)">
        <path
          d="M11.4 5.45001C11.1 5.45001 10.9 5.65001 10.9 5.95001V10.05C10.9 10.35 10.7 10.55 10.4 10.55H2.19998C1.89998 10.55 1.69998 10.35 1.69998 10.05V1.95001C1.69998 1.65001 1.89998 1.45001 2.19998 1.45001H6.29998C6.59998 1.45001 6.79998 1.25001 6.79998 0.950012C6.79998 0.650012 6.59998 0.450012 6.29998 0.450012H2.19998C1.29998 0.450012 0.599976 1.15001 0.599976 2.05001V10.25C0.599976 11.15 1.29998 11.85 2.19998 11.85H10.4C11.3 11.85 12 11.15 12 10.25V6.05001C11.9 5.75001 11.7 5.45001 11.4 5.45001Z"
          fill={color}
          stroke={color}
          stroke-width="0.2"
          stroke-miterlimit="10"
        />
        <path
          d="M11.7 0.950006C11.7 0.650006 11.4 0.350006 11.1 0.350006H8.49998C8.19998 0.350006 7.89998 0.650006 7.89998 0.950006C7.99998 1.25001 8.19998 1.45001 8.49998 1.45001H9.99998L5.49998 6.05001C5.29998 6.25001 5.29998 6.55001 5.49998 6.75001C5.59998 6.85001 5.79998 6.95001 5.89998 6.95001C5.99998 6.95001 6.19998 6.85001 6.29998 6.75001L10.6 2.45001V3.75001C10.6 4.05001 10.9 4.35001 11.2 4.35001C11.5 4.35001 11.8 4.05001 11.8 3.75001V1.15001V1.05001C11.7 0.950006 11.7 0.950006 11.7 0.950006Z"
          fill={color}
          stroke={color}
          stroke-width="0.2"
          stroke-miterlimit="10"
        />
      </g>
      <defs>
        <clipPath id="clip0_2775_1886">
          <rect width="11.5" height="11.5" fill="white" transform="translate(0.5 0.25)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const InfoCircleFilledIcon: React.FC<{
  size?: number;
  color?: string;
  colorBg?: string;
}> = ({ size = 14, color = '#323346', colorBg = 'black' }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Asset 1 11" clip-path="url(#clip0_302_8666)">
        <g id="Layer 2">
          <g id="Layer 1">
            <path
              id="Vector"
              d="M6.58999 13.495C10.2351 13.495 13.19 10.5401 13.19 6.89501C13.19 3.24993 10.2351 0.295013 6.58999 0.295013C2.94491 0.295013 -0.0100098 3.24993 -0.0100098 6.89501C-0.0100098 10.5401 2.94491 13.495 6.58999 13.495Z"
              fill={colorBg}
            />
            <path
              id="Vector_2"
              d="M5.76999 3.69501H7.48999V4.56501H5.76999V3.69501ZM5.76999 5.47501H7.48999V9.90501H5.76999V5.47501Z"
              fill={color}
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_302_8666">
          <rect width="13.21" height="13.21" fill="white" transform="translate(-0.0100098 0.295013)" />
        </clipPath>
      </defs>
    </svg>
  );
};
