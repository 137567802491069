import { Text } from '@chakra-ui/react';
import { boost } from 'assets/icons';
import path from 'configs/path';
import useGetBoostAds from 'modules/trade/hooks/useGetBoostAds';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Loading from 'shared/components/Loading';
import TokenIcon from 'shared/components/TokenIcon';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import { getChainIcon, getChainNameFromChainId } from 'utils/chain';
import { getImgUrl } from 'utils/file';
import { getBlockChainLogo } from 'utils/misc';
import { buildHref } from 'utils/path';

type Props = {};

export default function BoostAds({}: Props) {
  const dispatch = useAppDispatch();

  const { data: boostAds, isLoading: isLoadingBoostAds } = useGetBoostAds({});

  const boostAdsData = boostAds?.[0];
  const handleRedirect = (chainId: string, pair: string, baseToken: string) => {
    return () => {
      if (pair && baseToken) {
        dispatch(addAddress({ address: pair, baseToken: baseToken }));
      }
      const redirect_path = buildHref(
        path.TRADE,
        {
          address: baseToken,
          chain: getChainNameFromChainId(Number(chainId)) as string,
        },
        { pair },
      );
      window.location.href = redirect_path;
    };
  };

  if (isLoadingBoostAds) {
    return <Loading />;
  }
  if (!boostAdsData) {
    return (
      <Link
        to={`${path.ADS}?tab=boost-ads`}
        target="_blank"
        className="border-[2px] cursor-pointer rounded-lg border-[#FF9200] flex flex-col justify-between items-center text-12 bg-gradient-to-r from-[#500000] via-[#7E3317] to-[#4C100C]"
      >
        <div className="flex gap-x-4 items-center flex-1">
          <div>
            {/* @ts-ignore */}
            <Text
              fontSize={'13px'}
              cursor={'pointer'}
              fontWeight="bold"
              bg="radial-gradient(40.52% 162.07% at 50% 50%, #FF9200 0%, #FF6F00 100%)"
              bgClip="text"
              textColor="transparent"
            >
              BOOST ADS
            </Text>

            <div
              style={{
                background: 'radial-gradient(40.52% 162.07% at 50% 50%, #FF9200 0%, #FF6F00 100%)',
              }}
              className="text-black font-bold rounded-md px-1 py-[0.5px]"
            >
              ORDER NOW
            </div>
          </div>

          <div>
            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                opacity="0.3"
                d="M18 36C27.9411 36 36 27.9411 36 18C36 8.05887 27.9411 0 18 0C8.05887 0 0 8.05887 0 18C0 27.9411 8.05887 36 18 36Z"
                fill="url(#paint0_radial_4927_4665)"
              />
              <path
                d="M22.0578 20.7782L22.0814 20.8995C22.1884 21.6761 22.1257 22.4486 22.1637 23.2262C22.2131 24.2445 22.0578 24.66 21.2678 25.3563L19.8052 26.7213C19.6736 26.8406 19.547 26.9733 19.401 27.0751C18.97 27.3785 18.321 27.325 18.0854 26.821C17.9373 26.5022 17.8664 26.1339 17.7707 25.7966C17.6092 25.2278 17.3942 24.6487 17.3274 24.0593L17.0456 24.263C16.5385 24.6374 15.9728 24.9305 15.3248 24.8349C14.6408 24.7341 14.2417 24.1951 13.8025 23.7158L12.1259 21.9302C11.7052 21.4951 11.4748 21.2955 11.2928 20.7134L11.2856 20.6918C11.085 20.0263 11.5746 19.3125 11.9973 18.8558C12.1064 18.7375 12.2288 18.6223 12.309 18.4824C11.7371 18.4186 11.1745 18.2376 10.617 18.1029C10.2395 18.0113 9.83939 17.9486 9.47321 17.8231C8.88796 17.6225 8.91367 16.7575 9.22739 16.3409L9.24076 16.3234C9.35081 16.1805 9.51847 16.0653 9.6491 15.9387L11.1004 14.5687C11.8986 13.824 12.0837 13.5062 13.2954 13.6728L15.9779 14.0534L12.8624 17.7974C13.3838 18.2304 13.8261 18.7632 14.2777 19.2672L15.1016 20.1127C15.3206 19.945 15.5171 19.7393 15.7208 19.5521L17.1381 18.2232C17.4673 17.893 17.9867 17.3859 18.3961 17.9465C18.6758 18.3312 18.3086 18.6994 18.0464 18.9525L15.9347 20.9798L18.1574 23.3414C18.9803 23.0019 19.7353 22.4722 20.4573 21.9579L21.8809 20.9242C21.9395 20.8779 21.995 20.8173 22.0578 20.7782Z"
                fill="url(#paint1_radial_4927_4665)"
              />
              <path
                d="M16.45 13.6203C18.8404 11.2834 21.9395 9.79197 25.3389 9.83209C26.3171 9.8434 26.9795 9.9658 26.9363 11.2011L26.8653 12.2328C26.6658 13.9186 26.1165 15.6579 25.2268 17.1144L25.0643 17.3725C24.5397 18.2859 23.759 19.2024 22.9979 19.9193L22.5772 20.304C22.4013 20.4531 22.2049 20.5951 22.0526 20.7689L22.0619 20.7843C21.9991 20.8234 21.9436 20.8841 21.885 20.9304L20.4614 21.9641C19.7404 22.4784 18.9854 23.0081 18.1615 23.3475L15.9388 20.9859L18.0505 18.9586C18.3127 18.7045 18.6799 18.3363 18.4002 17.9527C17.9918 17.3911 17.4724 17.8992 17.1422 18.2293L15.7249 19.5583C15.5212 19.7444 15.3247 19.9512 15.1057 20.1188L14.2818 19.2733C13.8313 18.7704 13.389 18.2365 12.8665 17.8035L15.982 14.0595C16.0283 14.041 16.0756 13.9752 16.1126 13.9392L16.45 13.6203Z"
                fill="url(#paint2_radial_4927_4665)"
              />
              <path
                d="M9.91029 26.6472C10.655 25.9509 11.4007 25.2556 12.1454 24.5592C12.2493 24.4626 12.3531 24.3648 12.457 24.2682C12.6905 24.0501 12.6936 23.6243 12.457 23.4062C12.2071 23.1768 11.845 23.1738 11.5951 23.4062C10.8504 24.1026 10.1047 24.7979 9.36 25.4942C9.25611 25.5909 9.15223 25.6886 9.04834 25.7853C8.81486 26.0034 8.81177 26.4292 9.04834 26.6472C9.29829 26.8776 9.66137 26.8807 9.91029 26.6472Z"
                fill="#F7931E"
              />
              <path
                d="M12.6371 26.5248C12.9384 26.244 13.2388 25.9622 13.5401 25.6814C13.5823 25.6423 13.6245 25.6032 13.6656 25.5642C13.7777 25.4603 13.8436 25.2844 13.8436 25.1332C13.8436 24.984 13.7767 24.8051 13.6656 24.7022C13.5494 24.5952 13.3972 24.516 13.2347 24.5243C13.0701 24.5315 12.9261 24.5891 12.8037 24.7022C12.5023 24.983 12.202 25.2648 11.9006 25.5456C11.8584 25.5847 11.8163 25.6238 11.7751 25.6629C11.663 25.7668 11.5972 25.9427 11.5972 26.0939C11.5972 26.243 11.664 26.422 11.7751 26.5248C11.8913 26.6318 12.0436 26.711 12.2061 26.7028C12.3707 26.6956 12.5157 26.638 12.6371 26.5248Z"
                fill="#F7931E"
              />
              <path
                d="M10.2188 23.9359C10.5202 23.6551 10.8206 23.3732 11.1219 23.0924C11.1641 23.0533 11.2063 23.0143 11.2474 22.9752C11.3595 22.8713 11.4254 22.6954 11.4254 22.5442C11.4254 22.3951 11.3585 22.2161 11.2474 22.1132C11.1312 22.0063 10.979 21.9271 10.8164 21.9353C10.6519 21.9425 10.5079 22.0001 10.3855 22.1132C10.0841 22.394 9.78376 22.6759 9.48238 22.9567C9.44021 22.9957 9.39804 23.0348 9.3569 23.0739C9.24478 23.1778 9.17896 23.3537 9.17896 23.5049C9.17896 23.654 9.24581 23.833 9.3569 23.9359C9.47313 24.0428 9.62536 24.122 9.78787 24.1138C9.95244 24.1066 10.0975 24.049 10.2188 23.9359Z"
                fill="#F7931E"
              />
              <path
                d="M21.8643 16.5918C22.9499 16.5918 23.8299 15.7118 23.8299 14.6262C23.8299 13.5407 22.9499 12.6606 21.8643 12.6606C20.7787 12.6606 19.8987 13.5407 19.8987 14.6262C19.8987 15.7118 20.7787 16.5918 21.8643 16.5918Z"
                fill="#1A1A1A"
              />
              <defs>
                <radialGradient
                  id="paint0_radial_4927_4665"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(18 18) scale(18)"
                >
                  <stop offset="6.1e-07" stop-color="#FF9200" />
                  <stop offset="1" stop-color="#FF6F00" />
                </radialGradient>
                <radialGradient
                  id="paint1_radial_4927_4665"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(15.5859 20.44) rotate(1.9542) scale(6.69976)"
                >
                  <stop offset="6.1e-07" stop-color="#FF9200" />
                  <stop offset="1" stop-color="#FF6F00" />
                </radialGradient>
                <radialGradient
                  id="paint2_radial_4927_4665"
                  cx="0"
                  cy="0"
                  r="1"
                  gradientUnits="userSpaceOnUse"
                  gradientTransform="translate(19.8318 16.5263) rotate(1.9542) scale(6.89761)"
                >
                  <stop offset="6.1e-07" stop-color="#FF9200" />
                  <stop offset="1" stop-color="#FF6F00" />
                </radialGradient>
              </defs>
            </svg>
          </div>
        </div>

        <div className="font-semibold bg-[#000000] rounded-lg flex flex-col justify-center items-center px-1 py-0.5 w-full">
          <div>Best way to showcase</div>
          <div>your token to investor</div>
        </div>
      </Link>
    );
  }

  return (
    <div
      onClick={handleRedirect(boostAdsData!.chainId!, boostAdsData!.pair!, boostAdsData!.baseToken!)}
      className="border-[2px] cursor-pointer rounded-lg border-[#FF9200]"
    >
      <div className="relative after:content-normal after:absolute after:inset-0 after:z-0 after:bg-g-orange-8 after:opacity-50">
        <div className="pl-[7px] py-[6.5px] relative z-[1] flex gap-2 items-center">
          <TokenIcon
            iconSrc={getBlockChainLogo(
              Number(boostAdsData?.chainId),
              boostAdsData?.baseToken,
              boostAdsData?.baseTokenLogo,
            )}
            subIconSrc={getChainIcon(Number(boostAdsData?.chainId))}
            iconClassName="lg:w-[26px] lg:h-[26px]"
            subIconClassName="w-[10px] h-[10px]  bottom-0"
          />
          <div className="space-y-1">
            <div className="flex gap-2 items-center">
              <p className="bg-g-orange-9 font-semibold leading-none text-[13px] text-transparent bg-clip-text">
                Ads Boosting
              </p>

              <img src={boost} />
            </div>
            <p className="text-[13px] leading-none text-white font-bold">{boostAdsData?.baseTokenName}</p>
          </div>
        </div>
      </div>
      <div className="w-full p-1.5">
        <img src={getImgUrl(boostAdsData?.banner)} className="h-[40px] w-full rounded-md object-cover" />
      </div>
    </div>
  );
}
