import { useToast } from '@chakra-ui/react';
import { useWallet } from '@solana/wallet-adapter-react';
import { useDisconnect, useWeb3Modal, useWeb3ModalAccount } from '@web3modal/ethers5/react';
import { Chain, ChainIds, ChainNames } from 'configs/chain';
import { useCallback, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { solanaActions } from 'shared/state/redux/solana';
import { getChainIdFromChainName } from 'utils/chain';
import { getChain } from 'utils/web3Common';
import { useAppDispatch } from './reduxHook';
import useAccountBalance from './useAccountBalance';

const useAccount: {
  (enableBalance?: boolean): {
    balance: string | undefined;
    address: string | undefined;
    isConnected: boolean;
    chain: Chain;
    disconnect: () => Promise<void>;
    connect: () => void;
  };
} = (enableBalance = false) => {
  const locationApp = useLocation();
  const chainId = useMemo(() => {
    const chainName = locationApp.pathname.split('/');
    const chainNameIndex = chainName.findIndex((name) => Object.values(ChainNames).includes(name as ChainNames));
    const id = getChainIdFromChainName(chainName[chainNameIndex]) as ChainIds;
    return id;
  }, [locationApp.pathname]);
  const { disconnect } = useDisconnect();
  const dispatch = useAppDispatch();
  // const solanaBalance = useSolanaAccountBalance(enableBalance && chainId === ChainIds.Solana);

  const { data: balance } = useAccountBalance({ enabled: enableBalance });
  const { select, wallet, publicKey, disconnect: disconnectSolana, connected } = useWallet();
  const toast = useToast();
  const { address, chainId: accountChainId, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal();

  // useEffect(() => {
  //   if (wallet === null && chainId === ChainIds.Solana) select(DEFAULT_PROVIDER.name as WalletName);
  // }, [select, wallet, chainId]);

  const handleDisconnect = useCallback(async () => {
    try {
      await disconnect();
      localStorage.removeItem('WALLETCONNECT_DEEPLINK_CHOICE');
    } catch (e: any) {}
  }, [disconnect]);

  const handleDisconnectSolana = useCallback(async () => {
    try {
      dispatch(solanaActions.disableAutoConnect());
      await disconnectSolana();
    } catch (e: any) {
      toast({
        description: e.toString(),
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [disconnectSolana, dispatch, toast]);

  const handleConnect = () => {
    open();
  };
  const openWalletConnectModal = useCallback(async () => {
    dispatch(solanaActions.openConnectWalletModal());
  }, [dispatch]);
  const isShowSolanaPhantomWallet = false; //chainId === ChainIds.Solana || (!chainId && connected && !isConnected);
  return {
    balance: balance?.raw.toString(),
    address: isShowSolanaPhantomWallet ? publicKey?.toBase58() : address,
    isConnected: isShowSolanaPhantomWallet ? connected : isConnected,
    chain: getChain(chainId || accountChainId),
    disconnect: isShowSolanaPhantomWallet ? handleDisconnectSolana : handleDisconnect,
    connect: isShowSolanaPhantomWallet ? openWalletConnectModal : handleConnect,
  };
};

export default useAccount;
