import { Box, Flex, Image } from '@chakra-ui/react';
import StaticsImages from 'assets/icons/statics';
import constants from 'configs/constants';
import { getBlockChainLogo } from 'utils/misc';
import PairItemRank from './PairItemRank';
import { useAppDispatch } from 'shared/hooks/reduxHook';
import { addAddress } from 'shared/state/redux/selectAddressSlice';
import { buildHref } from 'utils/path';
import path from 'configs/path';
import { getChainIcon, getChainNameFromChainId } from 'utils/chain';
import TokenIcon from 'shared/components/TokenIcon';
import { firePurple, fireRed, rocketIcon } from 'assets/icons';
import { getImgUrl } from 'utils/file';
import { Link } from 'react-router-dom';

interface Props {
  pair: string;
  rank: number;
  tokenName: string;
  baseTokenSymbol: string;
  quoteTokenSymbol: string;
  dexLogo?: string;
  baseToken: string;
  quoteToken: string;
  chainId: string;
  baseTokenLogo: string;
  quoteTokenLogo: string;
  isTrending?: boolean;
  isTopPresale?: boolean;
  isAdsBoost?: boolean;
}

function PairItemName({
  pair,
  rank,
  dexLogo,
  baseTokenSymbol,
  quoteTokenSymbol,
  tokenName,
  baseToken,
  quoteToken,
  chainId,
  baseTokenLogo,
  quoteTokenLogo,
  isTrending,
  isTopPresale,
  isAdsBoost,
}: Props) {
  const dispatch = useAppDispatch();

  const handleRedirect = (chainId: string, pair: string, baseToken: string) => {
    return () => {
      if (pair && baseToken) {
        dispatch(addAddress({ address: pair, baseToken: baseToken }));
      }

      const redirect_path = buildHref(
        path.TRADE,
        {
          address: baseToken,
          chain: getChainNameFromChainId(Number(chainId)) as string,
        },
        { pair },
      );
      window.location.href = redirect_path;
      // redirect(redirect_path);
    };
  };

  const getRedirectPath = (chainId: string, pair: string, baseToken: string) => {
    const redirect_path = buildHref(
      path.TRADE,
      {
        address: baseToken,
        chain: getChainNameFromChainId(Number(chainId)) as string,
      },
      {
        pair,
      },
    );
    return redirect_path;
  };

  if (1) {
    return (
      <Link
        to={getRedirectPath(chainId, pair, baseToken)}
        className="flex items-center gap-[7px] cursor-pointer"

        // onClick={handleRedirect(chainId, pair, baseToken)}
      >
        {/* <PairItemRank no={rank} /> */}
        <span className="text-[#323346] min-w-9 text-start">#{rank}</span>
        <TokenIcon
          // iconSrc={getBlockChainLogo(Number(chainId), baseToken, baseTokenLogo)}

          iconSrc={getImgUrl(baseTokenLogo, baseToken)}
          subIconSrc={getChainIcon(Number(chainId))}
          fallbackSrc={dexLogo ?? StaticsImages.unknowLogo}
          iconClassName="w-7 h-7"
          subIconClassName="w-[9px] h-[9px]"
        />
        <div>
          <span className="text-13 font-medium text-white group-hover:bg-r-orange-1 group-hover:bg-clip-text group-hover:text-transparent">
            {baseTokenSymbol}
          </span>
          <span className="text-13 font-medium text-[#323346] group-hover:text-[#323346]">/{quoteTokenSymbol}</span>
        </div>
        {/* {(isTrending || isTopPresale) && (
          <img src={isTrending ? fireIcon : purpleFireIcon} alt="" className="w-[9px]" />
        )} */}
        {isTrending && <img src={fireRed} alt="" className="w-[11px]" />}
        {isTopPresale && <img src={firePurple} alt="" className="w-[11px]" />}
        {isAdsBoost && <img src={rocketIcon} alt="" className="w-[15px]" />}
      </Link>
    );
  }

  return (
    <Flex gap={2} alignItems="center">
      <PairItemRank no={rank} />
      <Flex gap={1}>
        <Image
          src={getBlockChainLogo(Number(chainId), baseToken, baseTokenLogo)}
          fallbackSrc={dexLogo ? constants.ASSETS_URL + dexLogo : StaticsImages.unknowLogo}
          alt={baseTokenSymbol}
          w="18px"
          h="18px"
          borderRadius={'100%'}
        />

        <Image
          src={getBlockChainLogo(Number(chainId), quoteToken, quoteTokenLogo)}
          fallbackSrc={dexLogo ? constants.ASSETS_URL + dexLogo : StaticsImages.unknowLogo}
          alt={quoteTokenSymbol}
          w="18px"
          h="18px"
          borderRadius={'100%'}
        />
        <Box>{baseTokenSymbol}</Box>
        <Box>/</Box>
        <Box color="gray.400">{quoteTokenSymbol}</Box>
        <Box noOfLines={1} display={['none', 'none', 'none', 'block']}>
          {tokenName}
        </Box>
      </Flex>
    </Flex>
  );
}

export default PairItemName;
