export const DiamondIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_25_5736)">
        <path
          d="M29.9 13.8L24.7 10H24.6L22.3 9.10001C22.3 9.10001 22.3 9.10001 22.2 9.10001L18 8.60001L13.8 9.10001C13.8 9.10001 13.8 9.10001 13.7 9.10001L11.4 10H11.3L6.1 13.8L6 13.9C6 14 6 14.2 6.1 14.3L17.8 27.4C17.9 27.5 17.9 27.5 18 27.5C18.1 27.5 18.2 27.5 18.2 27.4L29.9 14.3C30 14.2 30 14 29.9 13.8C29.9 13.9 29.9 13.9 29.9 13.8ZM18.6 11.7L24 10.6L22 15.5L18.6 11.7ZM21.5 15.7H14.5L18 12L21.5 15.7ZM13.9 15.5L12 10.6L17.5 11.7L13.9 15.5ZM13.4 15.6L6.9 13.9L11.4 10.6L13.4 15.6ZM21.7 16.3L18 26.3L14.2 16.3H21.7ZM24.6 10.7L29 14L22.6 15.6L24.6 10.7ZM13.9 9.60001L18 9.20001L22.1 9.60001L23.5 10.1L18 11.3L12.5 10.1L13.9 9.60001ZM13.6 16.2L17.2 25.8L7.1 14.6L13.6 16.2ZM18.8 25.8L22.4 16.2L28.9 14.6L18.8 25.8Z"
          fill={color ? color : !className ? '#8C8C8C' : 'current'}
        />
      </g>
      <defs>
        <clipPath id="clip0_25_5736">
          <rect width="24" height="18.8" fill="white" transform="translate(6 8.60001)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const LightningCircleIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.7 18C27.7 23.3572 23.3572 27.7 18 27.7C12.6429 27.7 8.30005 23.3572 8.30005 18C8.30005 12.6428 12.6429 8.3 18 8.3C23.3572 8.3 27.7 12.6428 27.7 18ZM13.3999 20.1H17.6999L17.0999 25.4C17.0999 25.8 17.6999 26 17.8999 25.6L22.9999 16.6C23.0999 16.3 22.8999 16 22.5999 16H18.2999L18.8999 10.7C18.8999 10.3 18.2999 10.1 18.0999 10.5L12.9999 19.5C12.8999 19.8 13.0999 20.1 13.3999 20.1Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const ChartPieIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7999 9.9C15.5999 9.6 16.3999 9.5 17.1999 9.5C19.2999 9.5 21.0999 10.2 22.6999 11.5L17.6999 17L17.2999 17.4H16.7999H8.8999C8.8999 16.9 8.9999 16.4 9.0999 16C9.1499 15.85 9.1749 15.725 9.1999 15.6C9.2249 15.475 9.2499 15.35 9.2999 15.2L9.5999 14.6C9.7999 14.1 9.9999 13.8 9.9999 13.8C10.8999 12.2 12.2999 10.9 13.9999 10.2C14.2999 10 14.5999 9.9 14.7999 9.9ZM17.1 19L19.8 26C19 26.4 18 26.5 17 26.5C15.1 26.5 13.4 25.9 12 24.9C11.7 24.7 11.5 24.5 11.3 24.3C9.79995 22.8 8.79995 20.9 8.69995 18.7H16.3H17L17.1 19ZM18.3 18.6L21.1 25.7C23.8 24.4 25.6 21.8 25.8 18.6V18.2H18.2L18.3 18.6ZM20.3 16.3L25.2 11.1C26.4 12.5 27.2 14.3 27.3 16.2L19.7 16.8L20.3 16.3Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const StarIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M27.5501 16.3057L23.2501 20.8057L23.8501 24.8057L24.1501 27.0057C24.2501 27.5057 23.7501 27.9057 23.2501 27.7057L21.0501 26.5057L17.8501 24.8057L12.8501 27.5057L12.4501 27.7057C12.0501 27.9057 11.6501 27.6057 11.5501 27.2057C11.5501 27.1057 11.5501 27.1057 11.5501 27.0057L12.5501 20.8057L12.3501 20.5057L8.45009 16.3057C8.05009 16.0057 8.25009 15.3057 8.75009 15.2057L14.7501 14.2057L17.4501 8.60567C17.6501 8.10567 18.3501 8.10567 18.5501 8.60567L21.2501 14.3057L23.7501 14.7057L27.2501 15.2057C27.7501 15.3057 27.9501 16.0057 27.5501 16.3057Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const ChartColumnIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.8501 8.5H16.8501V27.4H13.8501V8.5ZM8.55029 21.8H11.5503V27.4H8.55029V21.8ZM22.1504 14.7H19.1504V27.5H22.1504V14.7ZM24.4502 12.2H27.4502V27.4H24.4502V12.2Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const ChartColumnSquareIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 17, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <g id="Frame" clipPath="url(#clip0_77_8107)">
        <g id="Group">
          <path
            id="Vector"
            d="M13.4 16.65H4.00002C2.00002 16.65 0.400024 15.05 0.400024 13.05V3.65002C0.500024 1.75002 2.10002 0.150024 4.10002 0.150024H13.5C15.5 0.150024 17.1 1.75002 17.1 3.75002V13.15C17 15.05 15.4 16.65 13.4 16.65Z"
            fill={color ? color : !className ? '#8C8C8C' : 'current'}
          />
          <g id="Group_2">
            <g id="Group_3">
              <g id="Group_4">
                <path id="Vector_2" d="M5.00001 10.65H3.20001V13.95H5.00001V10.65Z" fill="black" />
              </g>
            </g>
            <g id="Group_5">
              <g id="Group_6">
                <path id="Vector_3" d="M8.09999 2.85002H6.29999V14.05H8.09999V2.85002Z" fill="black" />
              </g>
            </g>
            <g id="Group_7">
              <g id="Group_8">
                <path id="Vector_4" d="M11.2 6.45003H9.40002V13.95H11.2V6.45003Z" fill="black" />
              </g>
            </g>
            <g id="Group_9">
              <g id="Group_10">
                <path id="Vector_5" d="M14.3 4.95003H12.5V13.95H14.3V4.95003Z" fill="black" />
              </g>
            </g>
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_77_8107">
          <rect width="16.5" height="16.5" fill="white" transform="translate(0.5 0.150024)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ChartMultiIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <g clipPath="url(#clip0_25_5741)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2 17.35H16.3C17 17.35 17.5 16.85 17.5 16.15V10.25C17.5 9.55 17 9.05 16.3 9.05H10.2C9.5 9.05 9 9.55 9 10.25V16.15C9 16.85 9.5 17.35 10.2 17.35ZM25.8 17.35H19.7C19 17.35 18.5 16.85 18.5 16.15V10.25C18.5 9.55 19 9.05 19.7 9.05H25.8C26.5 9.05 27 9.55 27 10.25V16.15C27 16.85 26.5 17.35 25.8 17.35ZM25.8 26.95H19.7C19 26.95 18.5 26.45 18.5 25.75V19.85C18.5 19.15 19 18.65 19.7 18.65H25.8C26.5 18.65 27 19.15 27 19.85V25.75C27 26.45 26.5 26.95 25.8 26.95ZM16.3 26.95H10.2C9.5 26.95 9 26.45 9 25.75V19.85C9 19.25 9.5 18.65 10.2 18.65H16.3C17 18.65 17.5 19.15 17.5 19.85V25.75C17.5 26.45 17 26.95 16.3 26.95Z"
          fill={color ? color : !className ? '#8C8C8C' : 'current'}
        />
      </g>
      <defs>
        <clipPath id="clip0_25_5741">
          <rect width="18" height="17.9" fill="white" transform="translate(9 9.05)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const AdvertiseIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.5998 11.437H13.0998L8.2998 24.437H10.7998L11.474 22.437H16.1256L16.7998 24.437H19.2998L14.5998 11.437ZM15.3167 20.037L13.7998 15.537L12.283 20.037H15.3167ZM20.7999 22.737C20.9999 23.337 21.3999 23.837 21.7999 24.137C22.1999 24.437 22.7999 24.637 23.3999 24.537C23.8999 24.537 24.2999 24.437 24.6999 24.137C24.9533 23.8836 25.2068 23.5588 25.3999 23.1626V24.437H27.6999V11.437H25.3999V16.1616C25.2168 15.8127 24.9835 15.5497 24.6999 15.337C24.2999 15.037 23.8999 14.937 23.3999 14.937C22.7999 14.937 22.1999 15.137 21.7999 15.437C21.3999 15.737 20.9999 16.237 20.7999 16.837C20.4999 17.437 20.3999 18.137 20.3999 19.037V20.537C20.3999 21.437 20.5999 22.137 20.7999 22.737ZM25.3999 18.737C25.3999 18.5718 25.3696 18.4067 25.3424 18.2582C25.3201 18.137 25.2999 18.0269 25.2999 17.937C25.1999 17.737 24.9999 17.537 24.8999 17.437C24.6999 17.337 24.4999 17.237 24.1999 17.237C23.7999 17.237 23.3999 17.437 23.1999 17.737C22.9999 18.037 22.7999 18.537 22.7999 19.037V20.537C22.7999 21.137 22.8999 21.537 23.1999 21.837C23.3999 22.137 23.7999 22.337 24.1999 22.337C24.4999 22.337 24.6999 22.237 24.8999 22.137C25.0999 22.037 25.1999 21.837 25.2999 21.637C25.3999 21.437 25.3999 21.137 25.3999 20.837V18.737Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const ToolsIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.3188 16.251C21.3188 19.851 18.0188 24.051 18.0188 24.051C18.0188 24.051 14.7188 19.751 14.7188 16.251C14.7188 12.651 16.2188 11.151 18.0188 11.151C19.8188 11.151 21.3188 12.651 21.3188 16.251ZM25.4188 24.451C22.8188 25.451 18.8188 24.251 18.8188 24.251C18.8188 24.251 21.0188 20.651 23.6188 19.751C26.2188 18.751 27.7188 19.451 28.2188 20.751C28.6188 21.951 28.0188 23.451 25.4188 24.451ZM17.2189 24.251C17.2189 24.251 13.2189 25.451 10.6189 24.451C8.01889 23.451 7.31889 21.951 7.81889 20.651C8.31889 19.351 9.81889 18.651 12.4189 19.651C15.0189 20.651 17.2189 24.251 17.2189 24.251Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const GuideIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.0002 12.1258C25.1002 12.5258 26.8002 13.9258 27.9002 15.7258C27.1002 15.9258 25.1002 16.4258 23.9002 17.4258C23.8338 17.4806 23.7667 17.5361 23.699 17.5921C21.4214 19.4776 18.4165 21.9651 15.6002 19.9258C15.4935 19.8472 15.3928 19.7648 15.2981 19.679L15.2003 19.8258L14.9003 20.1258C14.8003 20.3258 14.6753 20.5008 14.5503 20.6758C14.4253 20.8508 14.3003 21.0258 14.2003 21.2258C14.0125 21.4136 13.903 21.6406 13.7984 21.8578C13.7359 21.9875 13.6751 22.1136 13.6003 22.2258C13.5598 22.3473 13.5029 22.4688 13.4428 22.597C13.3547 22.7852 13.2598 22.9878 13.2003 23.2258C13.1003 23.5258 13.0003 23.8258 12.7003 23.9258C12.6003 24.0258 12.4003 24.0258 12.3003 23.9258C12.2161 23.7574 12.3445 23.5182 12.4469 23.3275C12.4661 23.2916 12.4845 23.2574 12.5003 23.2258C12.5253 23.1758 12.5628 23.1195 12.6034 23.0586C12.7253 22.8758 12.8753 22.6508 12.8003 22.4258C12.7503 22.2758 12.6753 22.1258 12.6003 21.9758L12.6003 21.9757C12.5253 21.8258 12.4503 21.6758 12.4003 21.5258C12.3453 21.4158 12.287 21.3059 12.2259 21.1959C11.6049 21.4287 10.5117 21.1226 10.0001 20.5258C9.1001 19.6258 8.7001 18.8258 8.3001 17.6258C8.1001 17.0258 8.1001 15.8258 8.1001 15.3258C8.31307 15.417 8.56307 15.4713 8.8501 15.5336C9.50647 15.676 10.3565 15.8604 11.4001 16.6258C12.1001 17.2258 12.9001 18.3258 13.0001 19.3258C13.0001 19.8258 13.0001 20.5258 12.5001 21.0258C12.4631 21.0628 12.4211 21.0954 12.3749 21.1238C12.5302 21.3443 12.675 21.5752 12.8003 21.8258C12.9003 21.9258 13.0003 22.0258 13.0003 22.1258C13.0003 22.0758 13.0253 22.0508 13.0503 22.0258C13.0753 22.0008 13.1003 21.9758 13.1003 21.9258C13.1553 21.8433 13.2103 21.7532 13.2673 21.6598C13.4178 21.4134 13.5828 21.1433 13.8003 20.9258C13.9503 20.7258 14.1003 20.5508 14.2503 20.3758C14.4003 20.2008 14.5503 20.0258 14.7003 19.8258C14.7621 19.8258 14.7857 19.7876 14.8183 19.7348C14.8385 19.7021 14.8621 19.664 14.9003 19.6258L15.071 19.455C13.7173 17.9984 13.8657 15.7602 15.3002 14.3258C17.1002 12.2258 20.4002 11.7258 23.0002 12.1258Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};

export const Guide2Icon: React.FC<{
  width?: number;
  height?: number;
  color?: string;
  className?: string;
}> = ({ width = 16, height = 12, color, className }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 12" fill="none" className={className} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5574 5.75409C7.47544 5.83605 7.3115 5.91802 7.0656 6.08196C6.90167 6.32786 6.73773 6.49179 6.49183 6.73769L6.16396 7.14753L6.00003 7.39343C5.91806 7.39343 5.91806 7.4754 5.83609 7.55737C5.59019 7.88523 5.34429 8.13114 5.18035 8.459C5.01642 8.78687 4.85248 9.03277 4.68855 9.36064C4.68855 9.44261 4.60658 9.52458 4.60658 9.52458C4.60658 9.44261 4.52462 9.36064 4.52462 9.27868C4.11478 8.62294 3.70494 8.04917 3.37708 7.4754C3.62298 7.9672 3.95085 8.459 4.11478 8.95081C4.19675 9.19671 4.36068 9.44261 4.36068 9.68851C4.44265 9.93441 4.19675 10.1803 4.11478 10.4262C4.11478 10.5902 3.95085 10.8361 4.03281 11C4.11478 11.082 4.27871 11.082 4.36068 11C4.52462 10.918 4.68855 10.6721 4.77052 10.4262C4.85248 10.0983 5.01642 9.85245 5.09839 9.60655C5.18035 9.36064 5.34429 9.03277 5.50822 8.70491C5.67216 8.37704 5.83609 8.04917 6.00003 7.80327C6.08199 7.7213 6.16396 7.63933 6.16396 7.55737L6.32789 7.31146L6.65576 6.90163C6.8197 6.65573 6.98363 6.40982 7.22953 6.16392C7.3115 5.99999 7.47544 5.83605 7.5574 5.75409C7.72134 5.67212 7.72134 5.67212 7.72134 5.67212C7.8033 5.67212 7.72134 5.67212 7.5574 5.75409Z"
        fill={color ? color : !className ? '#17C467' : 'current'}
      />
      <path
        d="M1 3.70491C1 4.19671 1 5.18032 1.16393 5.59016C1.40984 6.57376 1.7377 7.2295 2.39344 8.04917C2.88525 8.62294 3.86885 8.86884 4.19672 8.45901C4.52459 8.04917 4.52459 7.39344 4.52459 7.06557C4.44262 6.24589 3.86885 5.34425 3.37705 4.85245C2.31148 3.95081 1.4918 4.03278 1 3.70491Z"
        fill={color ? color : !className ? '#17C467' : 'current'}
      />
      <path
        d="M15.5082 4.11475C14.6885 2.63934 13.459 1.4918 11.9016 1.08196C10.0164 0.754094 7.63933 1.16393 6.24589 2.88524C5.18031 4.27868 5.09834 6.4918 6.49179 7.63934C8.62293 9.44262 10.918 7.14754 12.5574 5.50819C13.459 4.68852 14.9344 4.27868 15.5082 4.11475Z"
        fill={color ? color : !className ? '#17C467' : 'current'}
      />
    </svg>
  );
};

export const DocsIcon: React.FC<{
  size?: number;
  color?: string;
  className?: string;
}> = ({ size = 36, color, className }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.81 8H22.191C25.28 8 27 9.78 27 12.83V23.16C27 26.26 25.28 28 22.191 28H13.81C10.77 28 9 26.26 9 23.16V12.83C9 9.78 10.77 8 13.81 8ZM14.08 12.66V12.65H17.069C17.5 12.65 17.85 13 17.85 13.429C17.85 13.87 17.5 14.22 17.069 14.22H14.08C13.649 14.22 13.3 13.87 13.3 13.44C13.3 13.01 13.649 12.66 14.08 12.66ZM14.08 18.74H21.92C22.35 18.74 22.7 18.39 22.7 17.96C22.7 17.53 22.35 17.179 21.92 17.179H14.08C13.649 17.179 13.3 17.53 13.3 17.96C13.3 18.39 13.649 18.74 14.08 18.74ZM14.08 23.31H21.92C22.319 23.27 22.62 22.929 22.62 22.53C22.62 22.12 22.319 21.78 21.92 21.74H14.08C13.78 21.71 13.49 21.85 13.33 22.11C13.17 22.36 13.17 22.69 13.33 22.95C13.49 23.2 13.78 23.35 14.08 23.31Z"
        fill={color ? color : !className ? '#8C8C8C' : 'current'}
      />
    </svg>
  );
};



export const LayoutIcon = ({ className }: { className?: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <path d="M17.52 20.59H6.90003C5.07003 20.59 3.59003 19.11 3.59003 17.28V6.9C3.59003 5.07 5.07003 3.59 6.90003 3.59H17.53C19.36 3.59 20.84 5.07 20.84 6.9V17.28C20.84 19.11 19.35 20.59 17.52 20.59Z" fill="#131722" stroke="#595959" stroke-width="1.1689" stroke-miterlimit="10" />
      <path d="M3.58002 9.48H20.84" stroke="#595959" stroke-width="1.1689" stroke-miterlimit="10" />
      <path d="M12.21 9.93V20.59" stroke="#595959" stroke-width="1.1689" stroke-miterlimit="10" />
    </svg>

  )

}


export const LayoutRightIcon = ({ className }: { className?: string }) => {
  return (<svg width="83" height="54" viewBox="0 0 83 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect width="80.6769" height="52" rx="4" transform="matrix(-1 0 0 1 81.677 1)" stroke="#323346" />
    <path d="M64.4154 23.1846H79.5539C79.677 23.1846 79.677 23.0615 79.677 23.0615C79.677 23.0615 79.677 23.0615 79.677 23.0615V10.2615C79.677 10.1384 79.5539 10.1384 79.5539 10.1384C79.5539 10.1384 79.5539 10.1384 79.5539 10.1384H64.4154C64.2924 10.1384 64.2923 10.2614 64.2923 10.2615C64.2923 10.2615 64.2923 10.2615 64.2923 10.2615V22.7728C64.2923 22.9577 64.2306 23.1846 64.4154 23.1846Z" fill="#323346" />
    <path d="M10.7537 3.98463C10.7537 4.46044 11.1394 4.84617 11.6152 4.84617H78.8155C79.2913 4.84617 79.677 4.46044 79.677 3.98463C79.677 3.50881 79.2913 3.12309 78.8155 3.12309H11.6152C11.1394 3.12309 10.7537 3.50881 10.7537 3.98463Z" fill="#131722" />
    <path d="M10.7537 7.18462C10.7537 7.66044 11.1394 8.04616 11.6152 8.04616H78.8155C79.2913 8.04616 79.677 7.66044 79.677 7.18462C79.677 6.70881 79.2913 6.32309 78.8155 6.32309H11.6152C11.1394 6.32309 10.7537 6.70881 10.7537 7.18462Z" fill="#131722" />
    <path d="M20.7232 27H61.9541C62.2003 27 62.3234 26.8769 62.3234 26.6308V10.3846C62.3234 10.1385 62.2003 10.0154 61.9541 10.0154H20.7232C20.4771 10.0154 20.354 10.1385 20.354 10.3846V26.6308C20.354 26.8769 20.4771 27 20.7232 27Z" fill="#131722" />
    <path d="M3.61539 8.04616H9.27694C9.40002 8.04616 9.40002 8.04616 9.40002 7.92309V3.12309C9.40002 3.00001 9.40002 3.00001 9.27694 3.00001H3.61539C3.49231 3.00001 3.49232 3.00001 3.49232 3.12309V7.92309C3.49232 8.04616 3.49231 8.04616 3.61539 8.04616Z" fill="#131722" />
    <path d="M66.1819 19.2986C66.2266 20.3699 67.108 21.2154 68.1802 21.2154H77.708C77.708 21.2154 77.708 21.2154 77.708 21.2154C77.7081 21.2154 77.8311 21.2153 77.8311 21.0923V18.2615C77.8311 18.2615 77.8311 18.2615 77.8311 18.2615C77.8311 18.2615 77.831 18.1385 77.708 18.1385H66.2618C66.2618 18.1385 66.2618 18.1385 66.2618 18.1385C66.2617 18.1385 66.1387 18.1385 66.1387 18.2615L66.1819 19.2986Z" fill="#131722" />
    <path d="M20.7232 50.7538H61.9541C62.2003 50.7538 62.3234 50.6308 62.3234 50.3846V29.7077C62.3234 29.4615 62.2003 29.3385 61.9541 29.3385H20.7232C20.4771 29.3385 20.354 29.4615 20.354 29.7077V49.9125C20.354 50.223 20.3619 50.6268 20.6511 50.7398C20.6752 50.7492 20.6992 50.7538 20.7232 50.7538Z" fill="#131722" />
    <path d="M64.2308 50.9385C64.2702 50.9779 64.3237 51 64.3794 51H79.4309C79.5539 51 79.554 50.877 79.554 50.877C79.554 50.877 79.554 50.877 79.554 50.877V25.4C79.554 25.277 79.431 25.277 79.4309 25.277C79.4309 25.277 79.4309 25.277 79.4309 25.277H64.2924C64.1693 25.277 64.1693 25.4 64.1693 25.4C64.1693 25.4 64.1693 25.4 64.1693 25.4V50.7899C64.1693 50.8456 64.1914 50.8991 64.2308 50.9385Z" fill="#323346" />
    <path d="M3.49248 27.3692H18.2618C18.5079 27.3692 18.631 27.2462 18.631 27V10.3846C18.631 10.1385 18.5079 10.0154 18.2618 10.0154H3.49248C3.24632 10.0154 3.12324 10.1385 3.12324 10.3846V27C3.12324 27.2462 3.24632 27.3692 3.49248 27.3692Z" fill="#131722" />
    <path d="M3.36943 42.3846H18.1387C18.3849 42.3846 18.5079 42.2615 18.5079 42.0154V29.2154C18.5079 28.9692 18.3849 28.8462 18.1387 28.8462H3.36943C3.12328 28.8462 3.0002 28.9692 3.0002 29.2154V42.0154C3.0002 42.2615 3.24636 42.3846 3.36943 42.3846Z" fill="#131722" />
    <path d="M3.24635 50.6308H18.2618C18.3849 50.6308 18.5079 50.5077 18.5079 50.3846V44.2308C18.5079 44.1077 18.3849 43.9846 18.2618 43.9846H3.24635C3.12327 43.9846 3.0002 44.1077 3.0002 44.2308V50.3846C3.0002 50.6308 3.12327 50.6308 3.24635 50.6308Z" fill="#131722" />
  </svg>
  )
}

export const LayoutLeftIcon = ({ className }: { className?: string }) => {
  return (<svg width="83" height="54" viewBox="0 0 83 54" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <rect x="1" y="1" width="80.6769" height="52" rx="4" stroke="#323346" />
    <path d="M18.2616 23.1846H3.12308C3.00001 23.1846 3 23.0615 3 23.0615C3 23.0615 3 23.0615 3 23.0615V10.2615C3 10.1384 3.12306 10.1384 3.12308 10.1384C3.12308 10.1384 3.12308 10.1384 3.12308 10.1384H18.2616C18.3846 10.1384 18.3847 10.2614 18.3847 10.2615C18.3847 10.2615 18.3847 10.2615 18.3847 10.2615V22.7728C18.3847 22.9577 18.4464 23.1846 18.2616 23.1846Z" fill="#323346" />
    <path d="M71.9233 3.98463C71.9233 4.46044 71.5376 4.84617 71.0618 4.84617H3.86154C3.38573 4.84617 3 4.46044 3 3.98463C3 3.50881 3.38572 3.12309 3.86154 3.12309H71.0618C71.5376 3.12309 71.9233 3.50881 71.9233 3.98463Z" fill="#131722" />
    <path d="M71.9233 7.18462C71.9233 7.66044 71.5376 8.04616 71.0618 8.04616H3.86154C3.38573 8.04616 3 7.66044 3 7.18462C3 6.70881 3.38572 6.32308 3.86154 6.32308H71.0618C71.5376 6.32308 71.9233 6.70881 71.9233 7.18462Z" fill="#131722" />
    <path d="M61.9538 27H20.7229C20.4767 27 20.3536 26.8769 20.3536 26.6308V10.3846C20.3536 10.1385 20.4767 10.0154 20.7229 10.0154H61.9538C62.1999 10.0154 62.323 10.1385 62.323 10.3846V26.6308C62.323 26.8769 62.1999 27 61.9538 27Z" fill="#131722" />
    <path d="M79.0616 8.04616H73.4001C73.277 8.04616 73.277 8.04616 73.277 7.92309V3.12309C73.277 3.00001 73.277 3.00001 73.4001 3.00001H79.0616C79.1847 3.00001 79.1847 3.00001 79.1847 3.12309V7.92309C79.1847 8.04616 79.1847 8.04616 79.0616 8.04616Z" fill="#131722" />
    <path d="M16.4951 19.2986C16.4504 20.3699 15.569 21.2154 14.4968 21.2154H4.96903C4.96903 21.2154 4.96903 21.2154 4.96902 21.2154C4.96894 21.2154 4.84595 21.2153 4.84595 21.0923V18.2615C4.84595 18.2615 4.84595 18.2615 4.84595 18.2615C4.84595 18.2615 4.84596 18.1385 4.96902 18.1385H16.4152C16.4152 18.1385 16.4152 18.1385 16.4152 18.1385C16.4153 18.1385 16.5383 18.1385 16.5383 18.2615L16.4951 19.2986Z" fill="#131722" />
    <path d="M61.9538 50.7538H20.7229C20.4767 50.7538 20.3536 50.6308 20.3536 50.3846V29.7077C20.3536 29.4615 20.4767 29.3385 20.7229 29.3385H61.9538C62.1999 29.3385 62.323 29.4615 62.323 29.7077V49.9125C62.323 50.223 62.3151 50.6268 62.0259 50.7398C62.0018 50.7492 61.9778 50.7538 61.9538 50.7538Z" fill="#131722" />
    <path d="M18.4462 50.9385C18.4068 50.9779 18.3533 51 18.2976 51H3.24612C3.12306 51 3.12305 50.877 3.12305 50.877C3.12305 50.877 3.12305 50.877 3.12305 50.877V25.4C3.12305 25.277 3.24604 25.277 3.24612 25.277C3.24613 25.277 3.24613 25.277 3.24613 25.277H18.3846C18.5077 25.277 18.5077 25.4 18.5077 25.4C18.5077 25.4 18.5077 25.4 18.5077 25.4V50.7899C18.5077 50.8457 18.4856 50.8991 18.4462 50.9385Z" fill="#323346" />
    <path d="M79.1845 27.3692H64.4153C64.1691 27.3692 64.046 27.2462 64.046 27V10.3846C64.046 10.1385 64.1691 10.0154 64.4153 10.0154H79.1845C79.4307 10.0154 79.5538 10.1385 79.5538 10.3846V27C79.5538 27.2462 79.4307 27.3692 79.1845 27.3692Z" fill="#131722" />
    <path d="M79.3076 42.3846H64.5383C64.2921 42.3846 64.1691 42.2615 64.1691 42.0154V29.2154C64.1691 28.9692 64.2921 28.8462 64.5383 28.8462H79.3076C79.5537 28.8462 79.6768 28.9692 79.6768 29.2154V42.0154C79.6768 42.2615 79.4306 42.3846 79.3076 42.3846Z" fill="#131722" />
    <path d="M79.4307 50.6308H64.4152C64.2921 50.6308 64.1691 50.5077 64.1691 50.3846V44.2308C64.1691 44.1077 64.2921 43.9846 64.4152 43.9846H79.4307C79.5537 43.9846 79.6768 44.1077 79.6768 44.2308V50.3846C79.6768 50.6308 79.5537 50.6308 79.4307 50.6308Z" fill="#131722" />
  </svg>
  )
}