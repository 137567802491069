import { useQuery } from '@tanstack/react-query';
import { getTopResearchs } from 'apis/research';
import constants from 'configs/constants';
import queryKey from 'configs/queryKey';
import { IQueryParamsTrending } from 'types/Pair';

interface Params {
  chainId?: string;
  factory?: string;
  pageIndex?: number;
  pageSize?: number;
}

function useTopResearchs(params: IQueryParamsTrending, autoFetch = false) {
  return useQuery(
    [queryKey.TOP_PRESALES, params],
    () =>
      getTopResearchs(params),
    {
      cacheTime: constants.CACHE_TIME[queryKey.PAIR_ACTIVITY],
      enabled: autoFetch,
    },
  );
}

export default useTopResearchs;
