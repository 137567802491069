import { baseStyle, ColorMode, extendTheme, FormLabel } from '@chakra-ui/react';
import { MultiSelectTheme } from 'chakra-multiselect';

export const themeConstants = {
  cardBgLight: 'white',
  cardBgDark: '#161A1E',
  headerHeight: 56,
  trendingHeight: 48,
  sidebarMaxWidth: 200,
  sidebarCollapsedWidth: 48,
  headerZIndex: 999,
  sidebarZIndex: 998,
  green: '#17c467',
  red: '#E44B27',
  hideScrollBar: {
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    'scrollbar-width': 'none',
  },
  // rowHoverLight: '#f5f5f4',
  rowHoverLight: '#131722',
  rowHoverDark: '#131722',
  inputBgLight: 'transparent',
  inputBgDark: 'transparent',
  dexPrimaryColor: '#f95192',
};

const config = {
  initialColorMode: 'dark',
  useSystemColorMode: true,
};

const theme = extendTheme({
  config,
  fonts: {
    body: 'Poppins, sans-serif',
    heading: 'Poppins, sans-serif',
  },
  components: {
    MultiSelect: MultiSelectTheme,
    Input: {
      baseStyle: {
        field: {
          borderColor: 'gray.300',
          _hover: {
            borderColor: '#F7931E',
          },
          _focus: {
            borderColor: '#F7931E',
            boxShadow: '0 0 5px #F7931E',
          },
          _placeholder: {
            color: '#454545',
          },
        },
      },
    },
    FormLabel: {
      baseStyle: {
        color: '#F7931E',
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
    },
  },
  styles: {
    global: (props: { colorMode: ColorMode }) => ({
      'html, body': {
        color: props.colorMode === 'dark' ? 'whiteAlpha.800' : 'black',
        background: props.colorMode === 'dark' ? '#1E2025' : '#f4f4f4',
        /* Fix bug scroll trading view on mobile */
        touchAction: 'auto',
      },
      '*::placeholder': {
        color: props.colorMode === 'dark' ? 'whiteAlpha.400' : 'gray.400',
      },
      '*, *::before, &::after': {
        borderColor: props.colorMode === 'dark' ? 'whiteAlpha.100' : 'gray.200',
        wordWrap: 'break-word',
      },

      '::-webkit-scrollbar': {
        width: '8px',
        height: '8px',
      },

      /* Track */
      '::-webkit-scrollbar-track': {
        background: 'transparent',
      },

      /* Handle */
      '::-webkit-scrollbar-thumb': {
        background: props.colorMode === 'dark' ? 'whiteAlpha.200' : 'gray.200',
        borderRadius: '3px',
      },

      /* Handle on hover */
      '::-webkit-scrollbar-thumb:hover': {
        background: props.colorMode === 'dark' ? 'whiteAlpha.300' : 'gray.300',
      },
    }),
  },
});

export default theme;
