import { createAction, createSlice, current, PayloadAction } from '@reduxjs/toolkit';
import { generatePersistConfig } from 'utils/misc';
import { persistReducer, REHYDRATE } from 'redux-persist';
import { TSearchItem } from 'apis/search';

interface State {
    previousSearch: TSearchItem[];
}

const rehydrateAction = createAction<State | undefined>(REHYDRATE);

const initialState: State = {
    previousSearch: [],
};

const search = createSlice({
    name: 'search',
    initialState,
    reducers: {
        addSearchItem: (state: State, action: PayloadAction<TSearchItem>) => {
            if (state.previousSearch.every(s => s.address !== action.payload.address)) {
                state.previousSearch = [
                    action.payload,
                    ...current(state?.previousSearch)
                ].slice(0, 5);
            }

        },
    },
    extraReducers: (builder) => {
        builder.addCase(rehydrateAction, (state, { payload }) => {
            if (payload?.previousSearch) state.previousSearch = payload.previousSearch;
        });
    },
});

export const { addSearchItem } =
    search.actions;
const persistConfig = generatePersistConfig('previousSearch', ['previousSearch']);
export default persistReducer<State>(persistConfig, search.reducer);
export const selectPreviousSearch: TSearchItem[] = (state: any) => state.search.previousSearch 