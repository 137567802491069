import { useQuery } from '@tanstack/react-query';
import { getPairNewSocials } from 'apis/pair';
import queryKey from 'configs/queryKey';
import { IQueryParamsTrending } from 'types/Pair';

function usePairNewSocials(params: IQueryParamsTrending, autoFetch = true) {
  return useQuery([queryKey.PAIR_SOCIALS, params.chainId], () => getPairNewSocials(params), {
    enabled: autoFetch,
  });
}

export default usePairNewSocials;
