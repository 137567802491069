import { Address } from '@web3modal/scaffold-utils/ethers';

export enum ChainIds {
  Ethereum = 1,
  Bsc = 56,
  BscTest = 97,
  Avalanche = 43114,
  FantomOpera = 250,
  Arbitrum = 42161,
  Polygon = 137,
  Pulse = 369,
  Bitrock = 7171,
  Shibarium = 109,
  Cybria = 6661,
  Base = 8453,

  // Solana = 501424,
}
export const ChainEVMIds = [
  ChainIds.Ethereum,
  ChainIds.Bsc,
  ChainIds.BscTest,
  ChainIds.Avalanche,
  ChainIds.FantomOpera,
  ChainIds.Arbitrum,
  ChainIds.Polygon,
  ChainIds.Pulse,
  ChainIds.Bitrock,
  ChainIds.Shibarium,
  ChainIds.Cybria,
  ChainIds.Base,
];
export const ChainSolanaIds = [501424];

// Also update in folder ./functions.
export enum ChainNames {
  Bsc = 'bsc',
  BscTest = 'bsc-testnet',
  Avalanche = 'avalanche',
  FantomOpera = 'fantom',
  Ethereum = 'eth',
  Arbitrum = 'arbitrum',
  Polygon = 'polygon',
  Pulse = 'pulse',
  Bitrock = 'bitrock',
  Shibarium = 'shibarium',
  Cybria = 'cybria',
  Base = 'base',

  // Solana = 'solana',
}

export const GO_PLUS_SUPPORTED_CHAIN = [ChainIds.Ethereum, ChainIds.Bsc, ChainIds.Arbitrum, ChainIds.Polygon];
export const HONEYPOT_SUPPORTED_CHAIN = [ChainIds.Ethereum, ChainIds.Bsc];
export const VIEW_PENDING_SUPPORTED_CHAIN = [ChainIds.Bsc, ChainIds.Ethereum];
export const INTEL_SUPPORTED_CHAIN: Record<string | number, string> = {
  [ChainIds.Bsc]: 'BSC',
  [ChainIds.Ethereum]: 'ETHEREUM',
  [ChainIds.Arbitrum]: 'ARBITRUM',
  [ChainIds.Avalanche]: 'AVALANCHE',
  [ChainIds.Base]: 'BASE',
  [ChainIds.Bitrock]: 'BITROCK',
  [ChainIds.FantomOpera]: 'FANTOM',
  [ChainIds.Polygon]: 'POLYGON',
  [ChainIds.Pulse]: 'PULSE',
  [ChainIds.Shibarium]: 'SHIBARIUM',
};

export enum ChainFullNames {
  Ethereum = 'Ethereum',
  Bsc = 'Binance Smart Chain',
  BscTest = 'Binance Smart Chain Test',
  Avalanche = 'Avalanche',
  FantomOpera = 'Fantom Opera',
  Arbitrum = 'Arbitrum One',
  Polygon = 'Polygon',
  Pulse = 'Pulse',
  Bitrock = 'Bit Rock',
  Shibarium = 'Shibarium',
  Cybria = 'Cybria',
  Solana = 'Solana',
  Base = 'Base',
}

type Contract = {
  address: Address;
  blockCreated?: number;
};
type NativeCurrency = {
  name: string;
  /** 2-6 characters long */
  symbol: string;
  decimals: number;
};
type RpcUrls = {
  http: readonly string[];
  webSocket?: readonly string[];
};
type BlockExplorer = {
  name: string;
  url: string;
};

export type Chain = {
  /** ID in number form */
  id: number;
  /** Human-readable name */
  name: string;
  /** Internal network name */
  network: string;
  /** Currency used by chain */
  nativeCurrency: NativeCurrency;
  /** Collection of RPC endpoints */
  rpcUrls: {
    [key: string]: RpcUrls;
    default: RpcUrls;
    public: RpcUrls;
  };
  /** Collection of block explorers */
  blockExplorers?: {
    [key: string]: BlockExplorer;
    default: BlockExplorer;
  };
  /** Collection of contracts */
  contracts?: {
    multicall3?: Contract;
  };
  /** Flag for test networks */
  testnet?: boolean;
};
