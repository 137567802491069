const queryKey = {
  RESOURCE: 'RESOURCE',
  PAIR_ACTIVITY: 'PAIR_ACTIVITY',
  NEW_PAIRS: 'NEW_PAIRS',
  PAIR_SOCIALS: 'PAIR_SOCIALS',
  PAIR_DETAIL: 'PAIR_DETAIL',
  STATISTICS: 'STATISTICS',
  PAIR_ACTIVITY_24H: 'PAIR_ACTIVITY_24H',
  USER_PROFIT: 'USER_PROFIT',
  SWAP_PAIR_DETAIL: 'SWAP_PAIR_DETAIL',
  WALLET_BALANCE: 'WALLET_BALANCE',
  WEB3_WALLET_BALANCE: 'WEB3_WALLET_BALANCE',
  MULTICALL: 'MULTICALL',
  SEARCH_PAIRS: 'SEARCH_PAIRS',
  PAIR_HISTORY_TRX: 'PAIR_HISTORY_TRX',
  NATIVE_TOKEN_PRICE: 'NATIVE_TOKEN_PRICE',
  ACCOUNT_BALANCE: 'ACCOUNT_BALANCE',
  TOKEN_INFO: 'TOKEN_INFO',
  TOKEN_TAXES: 'TOKEN_TAXES',
  WALLET_TRX: 'WALLET_TRX',
  TOP_BUY_SELL: 'TOP_BUY_SELL',
  AUTO_SLIPPAGE: 'AUTO_SLIPPAGE',
  DEX_INFO: 'DEX_INFO',
  WRAPPED_WALLET: 'WRAPPED_WALLET',
  TOKEN_OWNER_INFO: 'TOKEN_OWNER_INFO',
  TOKEN_TOP_HOLDER_IS_CONTRACT: 'TOKEN_TOP_HOLDER_IS_CONTRACT',
  MULTI_CHART: 'MULTI_CHART',
  COINGECKO_TOKEN_INFO: 'COINGECKO_TOKEN_INFO',
  USER_INFO: 'USER_INFO',
  GO_PLUS_DATA_RUG_CHECK: 'GO_PLUS_DATA_RUG_CHECK',
  TEAM_TOKEN_SOLD_AMOUNT: 'TEAM_TOKEN_SOLD_AMOUNT',
  HONEYPOT_DATA_RUG_CHECK: 'HONEYPOT_DATA_RUG_CHECK',
  SCAN_AND_HIDE_HONEYPOT: 'SCAN_AND_HIDE_HONEYPOT',
  SMALL_LIQUIDITY_CHECK: 'SMALL_LIQUIDITY_CHECK',
  SOLANA_ACCOUNT_BALANCE: 'SOLANA_ACCOUNT_BALANCE',
  SOLANA_ACCOUNT_ADDRESSES: 'SOLANA_ACCOUNT_ADDRESSES',
  TOKEN_APPROVER: 'TOKEN_APPROVER',
  SOLANA_BALANCE: 'SOLANA_BALANCE',
  SOLANA_PINKLOCK_RECORD: 'SOLANA_PINKLOCK_RECORD',
  PAIR: 'PAIR',
  PACKAGES: 'PACKAGES',
  TOP_TRENDINGS: 'TOP_TRENDINGS',
  TOP_PRESALES: 'TOP_PRESALES',
  BANNERS: 'BANNERS',
  SOCIAL_LINKS: 'SOCIAL_LINKS',
  PRESALE_REQUEST: 'PRESALE_REQUEST',
  TRENDING_REQUEST: 'TRENDING_REQUEST',
  PRESALE_PACKAGES: 'PRESALE_PACKAGES',
  RESEARCH_REQUEST: 'RESEARCH_REQUEST',
  CURRENCIES: 'CURRENCIES',
  BOOST_REQUEST: 'BOOST_REQUEST',
  SYSTEM_CONFIG: 'SYSTEM_CONFIG',
  TOP_GAINERS: 'TOP_GAINERS',
  TOP_LOSERS: 'TOP_LOSERS',
  BOOSTS: 'BOOSTS',
  SEARCH: 'SEARCH',
  INFOZONE: 'INFOZONE',
  GO_PLUS: 'GO_PLUS',
  SCAN: 'SCAN',
  RECENTLY_UPDATED_INFO: 'RECENTLY_UPDATED_INFO',
  VIEWS: 'VIEWS'
};

export default queryKey;
