
export const arrNumberSlider = [
  'liquidity',
  'totalTransaction24h',
  'volume',
  'presaleInfo_softCap',
  'presaleInfo_hardCap',
  'presaleInfo_maxBuy',
  'presaleInfo_contributors',
  'holders',
  'mcap',
  'makers',
  'txns',
  'pairCreatedTime',
]

export const arrMultiTag = [
  'badges'
]

export const arrMultiSelect = [
  'factory',
  'activity_type',
  'activity_description',
  'presaleInfo_type'
]

export const arrSwitchYesNo = [
  'socialLinks',
  'devOf',
  'scan'
]

export const arrMultiCheck = [
  'status',
  'activity_activityStatus'
]

export const keysPairFilter: any = {
  liquidity: ['minLq', 'maxLq'],
  totalTransaction24h: ['minTxs', 'maxTxs'],
  volume: ['minVol', 'maxVol'],
  badges: 'badge',
  socialLinks: '',
  devOf: 'haveDevInfo',
  scan: 'scanSecurity',
  factory: 'factory',
  presaleInfo_softCap: ['minSoftcap', 'maxSoftcap'],
  presaleInfo_hardCap: ['minHardcap', 'maxHardcap'],
  presaleInfo_maxBuy: ['minBuy', 'maxBuy'],
  presaleInfo_contributors: ['minContributor', 'maxContributor'],
  presaleInfo_type: 'saleType',
  holders: ['minHolder', 'maxHolder'],
  mcap: ['minMCap', 'maxMCap'],
  makers: ['minMaker', 'maxMaker'],
  txns: ['minTxs', 'maxTxs'],
  pairCreatedTime: ['minAge', 'maxAge'],
  activity_type: 'activityType',
  activity_description: 'detail',
  activity_activityStatus: 'activityStatus',
}